import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'

type PaginationProps = {
    resultFragmentPerPage: number
    totalWriting: number
    tableIndex: number
    setTableIndex: (idx: number) => void
}

const Pagination = ({
    resultFragmentPerPage,
    totalWriting,
    tableIndex,
    setTableIndex,
}: PaginationProps) => {
    const dispatch = useDispatch()
    const pages = []
    for (let i = 1; i <= Math.ceil(totalWriting / resultFragmentPerPage); i++) {
        pages.push(i)
    }
    const [userPageNumber, setUserPageNumber] = useState(tableIndex)

    const handleUserInputPageNumber = (e: KeyboardEvent) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            if (userPageNumber > 0 && userPageNumber < pages.length) {
                dispatch(setTableIndex(userPageNumber))
            }
        }
    }

    useEffect(() => {
        setUserPageNumber(tableIndex)
    }, [tableIndex])

    // console.log(resultFragmentPerPage, totalWriting, tableIndex, pages)
    if (totalWriting === 0) {
        return null
    }

    return (
        <ul className="pagination">
            <li
                className={'item' + (tableIndex === 1 ? ' disabled' : '')}
                onClick={() => dispatch(setTableIndex(tableIndex - 1))}
            >
                <div className="link" aria-label="Previous">
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                    <span className="sr-only">Previous</span>
                </div>
            </li>
            {pages.map((num, idx) => {
                if (tableIndex <= 4) {
                    if (num <= 8) {
                        if (num === 5) {
                            return (
                                <React.Fragment key={'pagination-input' + idx}>
                                    <li className="input-item">
                                        <input
                                            className="input"
                                            type="number"
                                            value={userPageNumber}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                setUserPageNumber(+e.target.value)
                                            }
                                            onKeyDown={(e) => handleUserInputPageNumber(e)}
                                            min={1}
                                            max={pages.length - 1}
                                        />
                                    </li>
                                    <li
                                        className={
                                            'item' + (tableIndex === num ? ' active disabled' : '')
                                        }
                                        key={'pagination' + idx}
                                        onClick={() => dispatch(setTableIndex(num))}
                                    >
                                        <div className="link">{num}</div>
                                    </li>
                                </React.Fragment>
                            )
                        }

                        return (
                            <li
                                className={'item' + (tableIndex === num ? ' active disabled' : '')}
                                key={'pagination' + idx}
                                onClick={() => dispatch(setTableIndex(num))}
                            >
                                <div className="link">{num}</div>
                            </li>
                        )
                    }
                } else if (tableIndex > 4 && tableIndex < pages.length - 4) {
                    if (num >= tableIndex - 4 && num <= tableIndex + 4) {
                        if (tableIndex >= 4 && num === tableIndex) {
                            return (
                                <li className="input-item" key={'pagination' + idx}>
                                    <input
                                        className="input"
                                        type="number"
                                        value={userPageNumber}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            setUserPageNumber(+e.target.value)
                                        }
                                        onKeyDown={(e) => handleUserInputPageNumber(e)}
                                        min={1}
                                        max={pages.length - 1}
                                    />
                                </li>
                            )
                        } else {
                            return (
                                <li
                                    className={
                                        'item' + (tableIndex === num ? ' active disabled' : '')
                                    }
                                    key={'pagination' + idx}
                                    onClick={() => dispatch(setTableIndex(num))}
                                >
                                    <div className="link">{num}</div>
                                </li>
                            )
                        }
                    }
                } else if (tableIndex >= pages.length - 4) {
                    if (num >= pages.length - 8) {
                        if (num === pages.length - 4) {
                            return (
                                <React.Fragment key={'pagination-input' + idx}>
                                    <li className="input-item">
                                        <input
                                            className="input"
                                            type="number"
                                            value={userPageNumber}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                setUserPageNumber(+e.target.value)
                                            }
                                            onKeyDown={(e) => handleUserInputPageNumber(e)}
                                            min={1}
                                            max={pages.length - 1}
                                        />
                                    </li>
                                    <li
                                        className={
                                            'item' + (tableIndex === num ? ' active disabled' : '')
                                        }
                                        key={'pagination' + idx}
                                        onClick={() => dispatch(setTableIndex(num))}
                                    >
                                        <div className="link">{num}</div>
                                    </li>
                                </React.Fragment>
                            )
                        }

                        return (
                            <li
                                className={'item' + (tableIndex === num ? ' active disabled' : '')}
                                key={'pagination' + idx}
                                onClick={() => dispatch(setTableIndex(num))}
                            >
                                <div className="link">{num}</div>
                            </li>
                        )
                    }
                }

                return true
            })}
            <li
                className={'item' + (tableIndex === pages.length ? ' disabled' : '')}
                onClick={() => dispatch(setTableIndex(tableIndex + 1))}
            >
                <div className="link" aria-label="Next">
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    <span className="sr-only">Next</span>
                </div>
            </li>
        </ul>
    )
}

export default Pagination
