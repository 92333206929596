export const options = {
    category: {
        en: [
            { value: '', label: 'All Categories' },
            { value: 'ELE_ENGLISH', label: 'English Language Education' },
            { value: 'ELS_ENGLISH', label: 'English Language Studies' },
            { value: 'ET_ENGLISH', label: 'Education Theories' },
            { value: 'GEC_ENGLISH', label: 'General Education' },
            { value: 'ELPS_ENGLISH', label: 'English Language Proficiency' },
        ],
        cn: [
            { value: '', label: 'All Categories' },
            { value: 'CLE_CHINESE', label: 'Chinese Language Education' },
            { value: 'CLS_CHINESE', label: 'Chinese Language Studies' },
            { value: 'ET_CHINESE', label: 'Education Theories' },
            { value: 'GEC_CHINESE', label: 'General Education' },
            { value: 'ECE_CHINESE', label: 'Early Childhood Education' },
        ],
    },
    // Subsections of Essay
    section: [
        { value: 'all', label: 'Search All' },
        { value: 'title', label: 'Title' },
        { value: 'intro', label: 'Introduction' },
        { value: 'body', label: 'Body' },
        { value: 'conclusion', label: 'Conclusion' },
        // { value: 'ref', label: 'References' },
        // { value: 'app', label: 'Appendix' },
    ],
    // MetaData
    age: [
        { value: '0', label: '0' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '31', label: '31' },
        { value: '32', label: '32' },
        { value: '33', label: '33' },
        { value: '36', label: '36' },
        { value: '39', label: '39' },
        { value: '41', label: '41' },
        { value: '43', label: '43' },
        { value: '44', label: '44' },
        { value: '52', label: '52' },
    ],
    gender: [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ],
    placeofbirth: [
        { value: 'Hong Kong', label: 'Hong Kong' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Macau', label: 'Macau' },
        { value: 'Mainland China', label: 'China' },
        { value: 'Malaysia', label: 'Malaysia' },
        { value: 'Nepal', label: 'Nepal' },
        { value: 'Nigeria', label: 'Nigeria' },
        { value: 'Pakistan', label: 'Pakistan' },
        { value: 'Taiwan', label: 'Taiwan' },
        { value: 'The Philippines', label: 'Philippines' },
        { value: 'USA', label: 'USA' },

        // { value: 'Hong Kong', label: 'Hong Kong' },
        // { value: 'Canada', label: 'Canada' },
        // { value: 'Macau', label: 'Macau' },
        // { value: 'Mainland China', label: 'China' },
        // { value: 'Malaysia', label: 'Malaysia' },
        // { value: 'Nepal', label: 'Nepal' },
        // { value: 'Nigeria', label: 'Nigeria' },
        // { value: 'Pakistan', label: 'Pakistan' },
        // { value: 'Philippines', label: 'Philippines' },
        // { value: 'Taiwan', label: 'Taiwan' },
        // { value: 'USA', label: 'USA' },
    ],
    firstlanguage: {
        en: [
            { value: 'Cantonese', label: 'Cantonese' },
            { value: 'Filipino', label: 'Filipino' },
            { value: 'Hindko', label: 'Hindko' },
            { value: 'Hoi Ping Cantonese', label: 'HoiPingCantonese' },
            { value: 'Minan Dialect', label: 'Minan Dialect' },
            { value: 'Nepali', label: 'Nepali' },
            { value: 'Pashto', label: 'Pashto' },
            { value: 'Punjabi', label: 'Punjabi' },
            { value: 'Putonghua', label: 'Putonghua' },
            { value: 'Shanghai Dialect', label: 'Shanghai Dialect' },
            { value: 'Sichuan Dialect', label: 'Sichuan Dialect' },
            { value: 'Urdu', label: 'Urdu' },
        ],
        cn: [
            { value: 'Cantonese', label: 'Cantonese' },
            { value: 'English', label: 'English' },
            { value: 'Filipino', label: 'Filipino' },
            { value: 'Hindko', label: 'Hindko' },
            { value: 'Hoi Ping Cantonese', label: 'HoiPingCantonese' },
            { value: 'Minan Dialect', label: 'Minan Dialect' },
            { value: 'Nepali', label: 'Nepali' },
            { value: 'Pashto', label: 'Pashto' },
            { value: 'Punjabi', label: 'Punjabi' },
            { value: 'Putonghua', label: 'Putonghua' },
            { value: 'Shanghai Dialect', label: 'Shanghai Dialect' },
            { value: 'Sichuan Dialect', label: 'Sichuan Dialect' },
            { value: 'Urdu', label: 'Urdu' },
        ],
    },
    exam: [
        {
            name: 'HKDSE English (overall level)',
            formName: 'hkdseenglish',
            option: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '5*' },
                { value: '7', label: '5**' },
            ],
        },
        {
            name: 'HKDSE Chinese (overall level)',
            formName: 'hkdsechinese',
            option: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '5*' },
                { value: '7', label: '5**' },
            ],
        },
        {
            name: 'HKALE English (overall grade)',
            formName: 'hkaleenglish',
            option: [
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' },
                { value: 'F', label: 'F' },
            ],
        },
        {
            name: 'HKALE Chinese (overall grade)',
            formName: 'hkalechinese',
            option: [
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' },
                { value: 'F', label: 'F' },
            ],
        },
        {
            name: 'IELTS (overall band score)',
            formName: 'ielts',
            option: [
                { value: '4.5', label: '4.5' },
                { value: '5', label: '5' },
                { value: '5.5', label: '5.5' },
                { value: '6', label: '6' },
                { value: '6.5', label: '6.5' },
                { value: '7', label: '7' },
                { value: '7.5', label: '7.5' },
                { value: '8', label: '8' },
                { value: '8.5', label: '8.5' },
                { value: '9', label: '9' },
            ],
        },
    ],

    sortBy: [
        { value: '5L', label: '5L' },
        { value: '4L', label: '4L' },
        { value: '3L', label: '3L' },
        { value: '2L', label: '2L' },
        { value: '1L', label: '1L' },
        { value: '0', label: '0' },
        { value: '1R', label: '1R' },
        { value: '2R', label: '2R' },
        { value: '3R', label: '3R' },
        { value: '4R', label: '4R' },
        { value: '5R', label: '5R' },
    ],
}

export const getCourseEn = () => options.category.en
export const getCourseCh = () => options.category.cn
export const getSections = () => options.section

// metadata
export const getGenderOptions = () => options.gender
export const getPlaceOfBirthOptions = () => options.placeofbirth
export const getLanguageOptions = (lang: string = 'english') =>
    lang === 'english' ? options.firstlanguage.en : options.firstlanguage.cn
export const getExamOptions = () => options.exam
export const getSortByOptions = () => options.sortBy

export const getAllOptionsLength = (lang: string = 'english') => ({
    age: options.age.length,
    gender: options.gender.length,
    placeofbirth: options.placeofbirth.length,
    firstlanguage:
        lang === 'english' ? options.firstlanguage.en.length : options.firstlanguage.cn.length,
    hkdseenglish: options.exam[0].option.length,
    hkdsechinese: options.exam[1].option.length,
    hkaleenglish: options.exam[2].option.length,
    hkalechinese: options.exam[3].option.length,
    ielts: options.exam[4].option.length,
})

export const wordListOptions = {
    category: {
        en: [
            { value: '', label: 'All Categories' },
            { value: 'ELE_ENGLISH', label: 'English Language Education' },
            { value: 'ELS_ENGLISH', label: 'English Language Studies' },
            { value: 'ET_ENGLISH', label: 'Education Theories' },
            { value: 'GEC_ENGLISH', label: 'General Education' },
            { value: 'ELPS_ENGLISH', label: 'English Language Proficiency' },
        ],
        cn: [
            { value: '', label: 'All Categories' },
            { value: 'CLE_CHINESE', label: 'Chinese Language Education' },
            { value: 'CLS_CHINESE', label: 'Chinese Language Studies' },
            { value: 'ET_CHINESE', label: 'Education Theories' },
            { value: 'GEC_CHINESE', label: 'General Education' },
            { value: 'ECE_CHINESE', label: 'Early Childhood Education' },
        ],
    },
    // Subsections of Essay
    section: [
        { value: 'all', label: 'Search All' },
        { value: 'title', label: 'Title' },
        { value: 'intro', label: 'Introduction' },
        { value: 'body', label: 'Body' },
        { value: 'conclusion', label: 'Conclusion' },
        // { value: 'ref', label: 'References' },
        // { value: 'app', label: 'Appendix' },
    ],
    // MetaData
    age: [
        { value: '0', label: '0' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '31', label: '31' },
        { value: '32', label: '32' },
        { value: '33', label: '33' },
        { value: '36', label: '36' },
        { value: '39', label: '39' },
        { value: '41', label: '41' },
        { value: '43', label: '43' },
        { value: '44', label: '44' },
        { value: '52', label: '52' },
    ],
    gender: [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ],
    placeofbirth: [
        { value: 'Hong Kong', label: 'Hong Kong' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Macau', label: 'Macau' },
        { value: 'Mainland China', label: 'China' },
        { value: 'Malaysia', label: 'Malaysia' },
        { value: 'Nepal', label: 'Nepal' },
        { value: 'Nigeria', label: 'Nigeria' },
        { value: 'Pakistan', label: 'Pakistan' },
        { value: 'Taiwan', label: 'Taiwan' },
        { value: 'The Philippines', label: 'Philippines' },
        { value: 'USA', label: 'USA' },
    ],
    firstlanguage: [
        { value: 'Cantonese', label: 'Cantonese' },
        { value: 'English', label: 'English' },
        { value: 'Filipino', label: 'Filipino' },
        { value: 'Hindko', label: 'Hindko' },
        { value: 'Hoi Ping Cantonese', label: 'HoiPingCantonese' },
        { value: 'Minan Dialect', label: 'Minan Dialect' },
        { value: 'Nepali', label: 'Nepali' },
        { value: 'Pashto', label: 'Pashto' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Putonghua', label: 'Putonghua' },
        { value: 'Shanghai Dialect', label: 'Shanghai Dialect' },
        { value: 'Sichuan Dialect', label: 'Sichuan Dialect' },
        { value: 'Urdu', label: 'Urdu' },
    ],
    exam: [
        {
            name: 'HKDSE English (overall level)',
            formName: 'hkdseenglish',
            option: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '5*' },
                { value: '7', label: '5**' },
            ],
        },
        {
            name: 'HKDSE Chinese (overall level)',
            formName: 'hkdsechinese',
            option: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '5*' },
                { value: '7', label: '5**' },
            ],
        },
        {
            name: 'HKALE English (overall grade)',
            formName: 'hkaleenglish',
            option: [
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' },
                { value: 'F', label: 'F' },
            ],
        },
        {
            name: 'HKALE Chinese (overall grade)',
            formName: 'hkalechinese',
            option: [
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' },
                { value: 'F', label: 'F' },
            ],
        },
        {
            name: 'IELTS (overall band score)',
            formName: 'ielts',
            option: [
                { value: '4.5', label: '4.5' },
                { value: '5', label: '5' },
                { value: '5.5', label: '5.5' },
                { value: '6', label: '6' },
                { value: '6.5', label: '6.5' },
                { value: '7', label: '7' },
                { value: '7.5', label: '7.5' },
                { value: '8', label: '8' },
                { value: '8.5', label: '8.5' },
                { value: '9', label: '9' },
            ],
        },
    ],

    sortBy: [
        { value: '5L', label: '5L' },
        { value: '4L', label: '4L' },
        { value: '3L', label: '3L' },
        { value: '2L', label: '2L' },
        { value: '1L', label: '1L' },
        { value: '0', label: '0' },
        { value: '1R', label: '1R' },
        { value: '2R', label: '2R' },
        { value: '3R', label: '3R' },
        { value: '4R', label: '4R' },
        { value: '5R', label: '5R' },
    ],

    // wordlist
    sortByWordlist: {
        en: [
            { value: 'descFrequency', label: 'Frequency Descending' },
            { value: 'ascFrequency', label: 'Frequency Ascending' },
        ],
        cn: [
            { value: 'descFrequency', label: 'Frequency Descending' },
            { value: 'ascFrequency', label: 'Frequency Ascending' },
        ],
    },
}

export const getAllWordListOptionsLength = () => ({
    age: wordListOptions.age.length,
    gender: wordListOptions.gender.length,
    placeofbirth: wordListOptions.placeofbirth.length,
    firstlanguage: wordListOptions.firstlanguage.length,
    hkdseenglish: wordListOptions.exam[0].option.length,
    hkdsechinese: wordListOptions.exam[1].option.length,
    hkaleenglish: wordListOptions.exam[2].option.length,
    hkalechinese: wordListOptions.exam[3].option.length,
    ielts: wordListOptions.exam[4].option.length,
})
