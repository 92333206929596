import React, { FunctionComponent, useMemo } from 'react'
import HitCount from './HitCount'
// import bg from '../../../images/front_page/bg-svg/wave-footer.svg'

interface FooterProps {
    isAbsolute?: boolean
}

const Footer: FunctionComponent<FooterProps> = ({ isAbsolute }) => {
    const currentYear = useMemo(() => new Date().getFullYear(), [])

    return (
        <footer
            className={`${
                isAbsolute ? 'absolute' : ''
            }  flex items-center justify-between bottom-5px w-full text-xs flex-col sm:flex-row sm:bottom-5
            px-10px sm:px-5 md:px-10 lg:px-20 xl:px-30px
            bg-no-repeat bg-cover
            `}
            // style={{
            //     backgroundImage: `url(${bg})`,
            // }}
        >
            <div className="w-full sm:w-1/2">
                <div className="flex justify-start">
                    <HitCount></HitCount>
                </div>

                <p>
                    Supported by Research Grants Council, Hong Kong SAR (Project Code: UGC/IDS16/17)
                </p>
                <p>© 2018 - {currentYear} RIBiLT HKMU. All Rights Reserved</p>
            </div>

            <div className="flex items-center justify-between w-full sm:w-auto">
                <div>
                    <img
                        className="w-20 sm:w-40"
                        src={require('../../../images/RIBiLT Logo (blue without stroke)_transprant.png')}
                        alt=""
                    />
                </div>
                <div className="ml-1">
                    <img
                        className="w-20 sm:w-40"
                        src={require('../../../images/HKMU-logo-mb.png')}
                        alt=""
                    />
                </div>
            </div>
        </footer>
    )
}

export default Footer
