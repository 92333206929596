import { User } from '../../data-models'

export interface AuthState {
    user: User
    token: string
    isRegistering: boolean
}

export const SET_USER = 'auth/SET_USER'
export const CLEAR_USER = 'auth/CLEAR_USER'
export const SET_TOKEN = 'auth/SET_TOKEN'
export const SET_IS_REGISTERING = 'auth/SET_IS_REGISTERING'
