import React, { FunctionComponent } from "react"
import cubeSvg from '../../images/cell_corpus_cube2.svg'

const Loading: FunctionComponent = () => (
    <div className="loader">
        {/* <span/> */}
        <div className="loader_img">
            <img src={cubeSvg} alt=""/>
        </div>
    </div>
)

export default Loading
