import { cloneDeep } from 'lodash'
import { defaultConcordanceRequestEn } from '../../../data/DefaultRequest'
import { ConcordanceActions } from './actions'
import * as types from './types'

const initialState: types.ConcordanceState = {
    isKwicSort: true,

    results: {
        data: [],
        resultlength: 0,
        totalessay: 0,
        totalhits: 0,
    },

    searchParams: cloneDeep(defaultConcordanceRequestEn),
}

const concordanceReducer = (
    state: types.ConcordanceState = initialState,
    action: ConcordanceActions
): types.ConcordanceState => {
    switch (action.type) {
        case types.SET_RESULTS:
            return { ...state, results: action.payload }

        case types.SET_IS_KWIC_SORT:
            return { ...state, isKwicSort: action.payload }

        case types.SET_GLOBAL_SEARCH_PARAMS:
            return { ...state, searchParams: action.payload }

        default:
            return state
    }
}

export default concordanceReducer
