import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
type KwicControlProps = {
    options: { label: string; value: string }[]
    curSortBy: string[]
    onClick: (sortByIdx: number, sortByValue: string) => void
    isSorting?: boolean
    isSortingOn?: boolean
}

const KwicControl = ({ options, curSortBy, onClick, isSorting, isSortingOn }: KwicControlProps) => {
    // console.log('Rendering...KwicControl')
    // const levelColor = ['red', 'green', 'purple']
    const curSortIdx = Object.values(curSortBy).map((v) => {
        let idx = 0
        Object.values(options).map((opt, optIdx) => {
            if (opt.value === v) {
                // console.log(optIdx)
                idx = optIdx
            }
            return true
        })
        return idx
    })
    const isKwicOn = !isSorting && isSortingOn
    // console.log(curSortIdx)

    return (
        <React.Fragment>
            <div className="kwicControlWrapper">
                {/* <label>KWIC Sort</label> */}

                {[0, 1, 2].map((idx) => (
                    <div className="kwicControl" key={idx}>
                        <label className="kwicControl__label">Level {idx + 1}</label>
                        <div className={`kwicControl__input`}>
                            <div
                                className={`kwicControl__input__btn left lv${idx + 1} ${
                                    isKwicOn ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    if (curSortIdx[idx] - 1 >= 0) {
                                        onClick(idx, options[curSortIdx[idx] - 1].value)
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                            </div>

                            <div className={`kwicControl__input__num lv${idx + 1} ${
                                    isKwicOn ? '' : 'disabled'
                                }`}>
                                {curSortBy[idx]}
                            </div>

                            <div
                                className={`kwicControl__input__btn right lv${idx + 1} ${
                                    isKwicOn ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    if (curSortIdx[idx] + 1 < options.length) {
                                        onClick(idx, options[curSortIdx[idx] + 1].value)
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

export default KwicControl
