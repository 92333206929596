import React from 'react'
import { NavLink } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import useLanguage from '../../hooks/useLanguage'

type BoardTabProps = {
    curTab: string
}

const BoardTab = ({ curTab }: BoardTabProps) => {
    // // console.log(`Rendering...${BoardTab.name}`)

    const language = useLanguage()
    const lang = language === 'english' ? 'en' : 'cn'

    return (
        <ul className="boardTab">
            <NavLink
                className={`boardTab__item${curTab === 'concordance' ? ' --active' : ''}`}
                to={'/concordance/' + lang}
            >
                Concordance
            </NavLink>
            <NavLink
                className={`boardTab__item${curTab === 'wordlist' ? ' --active' : ''}`}
                to={'/wordlist/' + lang}
            >
                Word List
            </NavLink>
        </ul>
    )
}

export default BoardTab
