import React, { HTMLAttributes } from 'react'

type CheckboxInputProps = {
    className?: HTMLAttributes<HTMLElement>['className']
    checked: boolean
    name: string
    value: string
    label: string
    onChange: (name: string, value: string, isChecked: boolean) => void
}

const MultiCheckboxInput = ({
    checked,
    className,
    name,
    label,
    value,
    onChange,
}: CheckboxInputProps) => {
    return (
        <React.Fragment>
            <input
                className="cell_checkbox"
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                onChange={() => onChange(name, value, !checked)}
            />
            <label>{label}</label>
        </React.Fragment>
    )
}

export default MultiCheckboxInput
