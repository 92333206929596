import React, { useState } from 'react'
import chevronLeftSvg from '../../../images/chevron-left.svg'

type DropdownProps = {
    width?: string

    name: string
    label: string
    theOptions: string
    options: { label: string; value: string }[]
    onChange: (name: string, value: string) => void
}

const Dropdown = ({ width, name, label, theOptions, options, onChange }: DropdownProps) => {
    const [active, setActive] = useState(false)
    const [displayOption] = options.filter((o) => o.value === theOptions)
    // console.log(theOptions, displayOption)

    return (
        <div className="level_dropdown">
            <label>{label}</label>

            <div
                className={'custom_dropdown' + (active ? ' active' : '')}
                onClick={() => setActive(!active)}
            >
                <div className="the_option" style={{ width: width }}>
                    <div className="text-sm sm:text-lg">{displayOption.label}</div>
                </div>
                <div className="options" style={{ width: width }}>
                    {options.map((opt) => (
                        <div className="option" key={name + opt.value}>
                            <input
                                id={name + opt.value}
                                type="radio"
                                name={name}
                                value={opt.value}
                                onChange={() => {
                                    setActive(false)
                                    onChange(name, opt.value)
                                }}
                            />
                            <label className="text-sm sm:text-lg" htmlFor={name + opt.value}>
                                {opt.label}
                            </label>
                        </div>
                    ))}
                </div>
                <img className="svg" src={chevronLeftSvg} alt="" />
            </div>
        </div>
    )
}

export default Dropdown
