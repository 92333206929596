import React, { Fragment, FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { UserLoginRequest } from '../../../stores/data-models'
import { login } from '../../../stores/epics/authEpics'
import { adminRoute } from '../../../utils/helpers'
import IntroDropdown from './IntroDropdown'

interface INav {
    className?: string
    setIsHeaderSidebarOpen: (flag: boolean) => void
}

const Nav: FunctionComponent<INav> = ({ className, setIsHeaderSidebarOpen }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { handleSubmit } = useForm<UserLoginRequest>()

    const autoLogin = () => {
        setIsSubmitting(true)

        dispatch(
            login(
                {
                    username: 'admin',
                    password: 'password',
                },
                () => {
                    history.push(adminRoute())
                    setIsSubmitting(false)
                },
                ({ validations, error }) => {
                    setIsSubmitting(false)
                }
            )
        )
    }

    return (
        <Fragment>
            {/* <Link
            to="/register"
            className="px-6 py-3 text-2xl hover:text-primary-300 duration-300"
        >
            Sign Up
        </Link> */}

            {/* <Link to="/introduction" className={`${className}`}>
            Introduction
        </Link> */}

            <IntroDropdown
                setIsHeaderSidebarOpen={setIsHeaderSidebarOpen}
                className={className}
            ></IntroDropdown>

            <Link to="/guide" className={`${className}`}>
                User Guide
            </Link>

            <Link to="/terms" className={`${className}`}>
                Terms of Use
            </Link>

            <Link to="/contact" className={`${className}`}>
                Contact Us
            </Link>

            {/* <Link to="/registration" className={`${className}`}>
            Registration
        </Link> */}

            {/* <Link to="/login" className={`${className}`}>
            Login
        </Link> */}

            <button
                type="submit"
                className={`${className} text-left`}
                disabled={isSubmitting}
                onClick={handleSubmit(autoLogin)}
            >
                Concordance
            </button>
        </Fragment>
    )
}

export default Nav
