import React, { KeyboardEvent, ChangeEvent, useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { ConcordanceSearchQueryNew } from '../../../stores/data-models/Assignment'
import { getResults } from '../../../stores/reducers/concordance'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type PaginationProps = {
    parentSearchParams: ConcordanceSearchQueryNew
    parentSetSearchParams: (newParams: ConcordanceSearchQueryNew) => void
    handlePageNumberSearch: (pageNumber: number) => void
}

const PaginationWithInput = ({
    parentSearchParams,
    parentSetSearchParams,
    handlePageNumberSearch,
}: PaginationProps) => {
    // console.log(`Rendering...${PaginationWithInput.name}`)

    const pages = []
    const pagesMapData: number[] = []
    const searchParams = parentSearchParams
    const results = useSelector(getResults)
    const [userPageNumber, setUserPageNumber] = useState<number>(0)

    const setPageNumber = (newPageNubmer: number) => {
        // parentSetSearchParams({ ...searchParams, pagenumber: newPageNubmer })
        if (newPageNubmer < pages.length) {
            handlePageNumberSearch(newPageNubmer)
        }
    }

    const handleUserInputPageNumber = (e: KeyboardEvent) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            if (userPageNumber > 0 && userPageNumber < pages.length) {
                parentSetSearchParams({ ...searchParams, pagenumber: userPageNumber - 1 })
            }
        }
    }

    useEffect(() => {
        setUserPageNumber(searchParams.pagenumber + 1)
    }, [])

    if (results.totalhits === 0 || searchParams.pagesize === 0) {
        return null
    }

    for (let i = 0; i < Math.ceil(results.totalhits / searchParams.pagesize); i++) {
        pages.push(i)
    }

    if (pages.length > 8) {
        for (let index = 0; index < 8; index++) {
            if (searchParams.pagenumber < 4) {
                pagesMapData.push(index)
            } else if (searchParams.pagenumber > pages.length - 5) {
                pagesMapData.push(pages.length - 8 + index)
            } else {
                if (index < 4) {
                    pagesMapData.push(searchParams.pagenumber + index - 4)
                } else {
                    pagesMapData.push(searchParams.pagenumber + index - 3)
                }
            }
        }
    } else {
        pages.map((page) => pagesMapData.push(page))
    }

    return (
        <ul className="pagination">
            <li
                className={'item' + (searchParams.pagenumber === 0 ? ' disabled' : '')}
                onClick={() => setPageNumber(searchParams.pagenumber - 1)}
            >
                <div className="link" aria-label="Previous">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        style={{ color: searchParams.pagenumber === 0 ? '#eee' : '#000' }}
                    ></FontAwesomeIcon>
                    <span className="sr-only">Previous</span>
                </div>
            </li>
            {pagesMapData.map((num, idx) =>
                pages.length > 8 ? (
                    idx == 3 ? (
                        <React.Fragment key={'pagination-input' + idx}>
                            <li
                                className={
                                    'item' +
                                    (searchParams.pagenumber === num ? ' active disabled' : '')
                                }
                                key={'pagination' + idx}
                                onClick={() => setPageNumber(num)}
                            >
                                <div className="link">{num + 1}</div>
                            </li>
                            <li className="input-item">
                                <input
                                    className="input"
                                    type="number"
                                    value={userPageNumber}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setUserPageNumber(+e.target.value)
                                    }
                                    onKeyDown={(e) => handleUserInputPageNumber(e)}
                                    min={1}
                                    max={pages.length - 1}
                                />
                            </li>
                        </React.Fragment>
                    ) : (
                        <li
                            className={
                                'item' + (searchParams.pagenumber === num ? ' active disabled' : '')
                            }
                            key={'pagination' + idx}
                            onClick={() => setPageNumber(num)}
                            page-num={num}
                        >
                            <div className="link">{num + 1}</div>
                        </li>
                    )
                ) : (
                    <li
                        className={
                            'item' + (searchParams.pagenumber === num ? ' active disabled' : '')
                        }
                        key={'pagination' + idx}
                        onClick={() => setPageNumber(num)}
                        page-num={num}
                    >
                        <div className="link">{num + 1}</div>
                    </li>
                )
            )}
            <li
                className={
                    'item' + (searchParams.pagenumber === pages.length - 1 ? ' disabled' : '')
                }
                onClick={() => setPageNumber(searchParams.pagenumber + 1)}
            >
                <div className="link" aria-label="Next">
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{
                            color: searchParams.pagenumber === pages.length - 1 ? '#eee' : '#000',
                        }}
                    ></FontAwesomeIcon>
                    <span className="sr-only">Next</span>
                </div>
            </li>
        </ul>
    )
}

export default PaginationWithInput
