import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppLayout from '../../components/layouts/AppLayout'
import BoardTab from '../../components/utility/BoardTab'
import SearchLoader2 from '../../components/utility/SearchLoader'
import useLanguage from '../../hooks/useLanguage'
import {
    ConcordanceData,
    ConcordanceSearchQueryNew,
    ConcordanceSearchResponseNew
} from '../../stores/data-models/Assignment'
import { searchConcordanceCn, searchConcordanceEn } from '../../stores/epics/concordanceEpics'
import {
    getGlobalSearchParams,
    getIsKwicSort,
    getResults,
    setGlobalSearchParams,
    setResults
} from '../../stores/reducers/concordance'
import { getIsSidebarActive, setIsSidebarActive } from '../../stores/reducers/system'
import {
    getWordlistSearchText,
    setSearchText as setWordlistSearchText
} from '../../stores/reducers/wordlist'
import {
    checkConcordanceRequest,
    getMinifiedConcordanceSearchParams
} from '../../utils/helpers/request.helpers'
import { handleKWICSorting } from '../../utils/helpers/sort.helpers'
import AppHeaderConcordance from './partials/AppHeaderConcordance'
import BoardFooter from './partials/BoardFooter'
import ResultTable from './partials/ResultTable'
import SearchBar from './partials/SearchBar'
import SelectChipSet from './partials/SelectChipSet'
import Sidebar from './partials/Sidebar'

const Concordance = () => {
    // console.log(`Rendering...${Concordance.name}`)

    const dispatch = useDispatch()
    const language = useLanguage()
    const results = useSelector(getResults)
    const wordlistSearchText = useSelector(getWordlistSearchText)
    const isSidebarActive = useSelector(getIsSidebarActive)
    const isKwicSort = useSelector(getIsKwicSort)
    const searchParams = useSelector(getGlobalSearchParams)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isRegNewUserOpen, setIsRegNewUserOpen] = useState(false)
    const [validateStatus, setValidateStatus] = useState({
        isVaild: true,
        msg: 'Ready for searching',
    })
    const [isSearching, setIsSearching] = useState(false)
    const [isSorting, setIsSorting] = useState(false)
    const [kwicResults, setKwicResults] = useState<ConcordanceData[]>([])
    const isSortingOn = isKwicSort && results.data.length > 0 && !isSearching && !isSorting

    const searchingSuccessAction = useCallback(
        (response: ConcordanceSearchResponseNew) => {
            // console.log('response = ', response)
            dispatch(setResults(response))

            if (response.data.length === 0) {
                setValidateStatus({
                    isVaild: false,
                    msg: 'Empty Search Result',
                })
            } else {
                // KWIC Sorting
                const colorTarget = isKwicSort ? searchParams.sortBy.map((sb) => sb.split('')) : []
                const newResponseData = handleKWICSorting(
                    response.data,
                    isKwicSort,
                    colorTarget,
                    language
                )
                setKwicResults(newResponseData)
            }

            setIsSearching(false)
        },
        [dispatch, isKwicSort, language, searchParams.sortBy]
    )

    const searchingFailsAction = () => {
        setResults({
            data: [],
            resultlength: 0,
            totalessay: 0,
            totalhits: 0,
        })
        setIsSearching(false)
    }

    const searching = useCallback(
        (pageNumber: number | undefined = undefined) => {
            let { isVaild, msg } = checkConcordanceRequest(
                searchParams,
                wordlistSearchText,
                language
            )
            setValidateStatus({ isVaild, msg })
            // console.log(`{ isVaild ${isVaild}, msg ${msg}}`)
            if (!isVaild) {
                return
            }

            let minifiedSearchParams = getMinifiedConcordanceSearchParams(
                searchParams,
                wordlistSearchText,
                language
            )
            // console.log(`minifiedSearchParams`, minifiedSearchParams)

            if (pageNumber !== undefined && pageNumber >= 0) {
                setGlobalSearchParams({ ...searchParams, pagenumber: pageNumber })
                minifiedSearchParams.pagenumber = pageNumber
            }

            setIsSearching(true)
            if (language === 'english') {
                dispatch(
                    searchConcordanceEn(
                        minifiedSearchParams,
                        (response: ConcordanceSearchResponseNew) =>
                            searchingSuccessAction(response),
                        () => searchingFailsAction()
                    )
                )
            }
            if (language === 'chinese') {
                dispatch(
                    searchConcordanceCn(
                        minifiedSearchParams,
                        (response: ConcordanceSearchResponseNew) =>
                            searchingSuccessAction(response),
                        () => searchingFailsAction()
                    )
                )
            }
        },
        [dispatch, language, searchParams, wordlistSearchText, searchingSuccessAction]
    )

    const handleSearch = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            // console.log('searching triggered by searchbar form submit')
            searching()
        },
        [searching]
        // [searchParams, searching]
    )

    // page number searching
    const handlePageNumberSearch = (pageNumber: number) => {
        // console.log('searching triggered by pagenumber on click', searchParams.pagenumber)
        searching(pageNumber)
    }

    const handleChangeFromSidebar = useCallback((newParams: ConcordanceSearchQueryNew) => {
        dispatch(
            setGlobalSearchParams({
                ...searchParams,
                category: newParams.category,
                section: newParams.section,
                metadata: newParams.metadata,
            })
        )
    }, [])

    const parentSetSearchParams = (params: ConcordanceSearchQueryNew) =>
        dispatch(setGlobalSearchParams(params))

    // search from wordlist to concordance
    useEffect(() => {
        if (wordlistSearchText) {
            // console.log('searching triggered by wordlist to concordance')

            setGlobalSearchParams({ ...searchParams, searchText: wordlistSearchText })
            dispatch(setWordlistSearchText(''))
            searching()
        }
    }, [wordlistSearchText])

    // KWIC Sorting
    useEffect(() => {
        if (isKwicSort && results.data.length) {
            setIsSorting(true)

            const colorTarget = isKwicSort ? searchParams.sortBy.map((sb) => sb.split('')) : []
            const newResponseData = handleKWICSorting(
                results.data,
                isKwicSort,
                colorTarget,
                language
            )
            setKwicResults(newResponseData)
        }
    }, [searchParams.sortBy])

    useEffect(() => {
        if (isSorting) {
            setIsSorting(false)
        }
    }, [kwicResults])

    return (
        <AppLayout
            AppHeader={() => (
                <AppHeaderConcordance
                    parentSearchParams={searchParams}
                    parentSetSearchParams={parentSetSearchParams}
                    setIsModalOpen={setIsModalOpen}
                    setIsRegNewUserOpen={setIsRegNewUserOpen}
                ></AppHeaderConcordance>
            )}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isRegNewUserOpen={isRegNewUserOpen}
            setIsRegNewUserOpen={setIsRegNewUserOpen}
        >
            <div className="app__sidebar">
                <div className={`sidebar_wrapper ${isSidebarActive ? 'active' : ''}`}>
                    <Sidebar parentSetSearchParams={handleChangeFromSidebar}></Sidebar>
                </div>
                <div
                    className={`sidebar-wrapper-overlay ${isSidebarActive ? 'active' : ''}`}
                    onClick={() => dispatch(setIsSidebarActive(false))}
                ></div>
            </div>

            <div className="app__divider"></div>

            <div className="app__board">
                <div className="board-wrapper">
                    <BoardTab curTab="concordance"></BoardTab>

                    <div className="board">
                        <SearchBar
                            parentSearchParams={searchParams}
                            parentSetSearchParams={parentSetSearchParams}
                            onSearch={handleSearch}
                            isSorting={isSorting}
                            isSortingOn={isSortingOn}
                            isSearching={isSearching}
                        ></SearchBar>

                        <div className="selectChipSetWrapper">
                            <SelectChipSet
                                parentSearchParams={searchParams}
                                parentSetSearchParams={parentSetSearchParams}
                            ></SelectChipSet>
                        </div>

                        {validateStatus.isVaild && results.data.length ? (
                            <div className="flex items-center justify-between my-10px">
                                <p className="text-sm sm:text-lg font-bold">Results</p>
                                <p className="text-sm sm:text-lg font-bold">
                                    Hits: {results?.totalhits}
                                </p>
                            </div>
                        ) : null}

                        <div className="resultBoard">
                            <div
                                className={`resultBoard__tableWrapper ${
                                    isSearching ? ' mt-10px' : ''
                                }`}
                            >
                                {validateStatus.isVaild ? (
                                    isSearching ? (
                                        <SearchLoader2 />
                                    ) : results.data.length ? (
                                        <ResultTable
                                            pageNumber={searchParams.pagenumber}
                                            pageSize={searchParams.pagesize}
                                            sortBy={searchParams.sortBy}
                                            kwicResults={kwicResults}
                                            isKwicSort={isKwicSort}
                                            lang={language}
                                        ></ResultTable>
                                    ) : (
                                        <div className="h-full flex justify-center items-center">
                                            <p className="text-sm sm:text-lg font-bold">
                                                {validateStatus.msg}
                                            </p>
                                        </div>
                                    )
                                ) : (
                                    <div className="h-full flex justify-center items-center">
                                        <p className="text-sm sm:text-lg font-bold">
                                            {validateStatus.msg}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {validateStatus.isVaild && kwicResults.length > 0 && !isSearching ? (
                            <BoardFooter
                                parentSearchParams={searchParams}
                                parentSetSearchParams={parentSetSearchParams}
                                kwicResults={kwicResults}
                                handlePageNumberSearch={handlePageNumberSearch}
                            ></BoardFooter>
                        ) : null}
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default Concordance
