import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AssignmentService } from '../../ApiServices'
import { ConcordanceSearchQueryNew, ConcordanceSearchResponseNew } from '../data-models'
import { apiCall } from '../reducers/system'
import { OnSuccessCallBack, RootState, OnErrorCallBack } from '../types'

export const searchConcordanceEn = (
    searchParams: Partial<ConcordanceSearchQueryNew>,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => AssignmentService.searchConcordanceEn(searchParams),
            onSuccess: async (response: ConcordanceSearchResponseNew[]) => {
                onSuccess(response)
            },
            onError: (error: any) => {
                console.error(error)
                onError()
            },
        })
    )
}

export const searchConcordanceCn = (
    searchParams: Partial<ConcordanceSearchQueryNew>,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => AssignmentService.searchConcordanceCn(searchParams),
            onSuccess: async (response: ConcordanceSearchResponseNew[]) => {
                onSuccess(response)
            },
            onError: (error: any) => {
                console.error(error)
                onError()
            },
        })
    )
}
