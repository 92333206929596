import { config }   from "../../config"
import LocalStorage from "../LocalStorage"

export const getAuthTokenFromBrowserStorage = async (): Promise<string | null> => await LocalStorage.get(config.app.authTokenKeyName)
export const setAuthTokenToBrowserStorage = async (token: string): Promise<void> => {
    await LocalStorage.set(config.app.authTokenKeyName, token)
}
export const clearAuthTokenFromBrowserStorage = async (): Promise<void> => {
    await LocalStorage.remove(config.app.authTokenKeyName)
}
