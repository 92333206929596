import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppLayout from '../../components/layouts/AppLayout'
import Banner from '../../components/utility/Banner'
import BoardTab from '../../components/utility/BoardTab'
import WordlistLoading from '../../components/utility/WordlistLoading'
import { defaultWordListRequest } from '../../data/DefaultRequest'
import useLanguage from '../../hooks/useLanguage'
import {
    WordListData,
    WordListResponse,
    WordListSearchQueryNew
} from '../../stores/data-models/WordList'
import { searchWordListCn, searchWordListEn } from '../../stores/epics/wordListEpics'
import { getIsSidebarActive, setIsSidebarActive } from '../../stores/reducers/system'
import {
    getIsInitSearchCompleted,
    getResults,
    getWordlistPageNumber,
    setIsInitSearchCompleted,
    setResults,
    setWordlistPageNumberAction
} from '../../stores/reducers/wordlist'
import {
    checkWordListRequest,
    getMinifiedWordListSearchParams
} from '../../utils/helpers/request.helpers'
import Sidebar from '../word-list/partials/Sidebar'
import AppHeaderWordList from './partials/AppHeaderWordList'
import BoardFooter from './partials/BoardFooter'
import ResultTable from './partials/ResultTable'
import SearchBar from './partials/SearchBar'

const WordList = () => {
    const dispatch = useDispatch()
    const language = useLanguage()
    const isSidebarActive = useSelector(getIsSidebarActive)
    const results = useSelector(getResults)
    const isInitSearchCompleted = useSelector(getIsInitSearchCompleted)
    const wordlistPageNumber = useSelector(getWordlistPageNumber)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isRegNewUserOpen, setIsRegNewUserOpen] = useState(false)
    const [validateStatus, setValidateStatus] = useState({
        isVaild: true,
        msg: 'Ready for searching',
    })
    const [resultsFragments, setResultsFragments] = useState<WordListResponse[]>([])
    // state
    const [isSearching, setIsSearching] = useState(false)
    const [searchParams, setSearchParams] = useState<WordListSearchQueryNew>({
        ...defaultWordListRequest,
    })
    const [csvName, setCsvName] = useState('')
    const [isBannerOpen, setisBannerOpen] = useState(true)
    // pagination
    const resultFragmentPerPage = 10

    const handleResultFragment = useCallback(
        (res) => {
            if (res.wordlist.length > 0 && res.wordlist[0].Frequency === null) {
                setResultsFragments([])
            } else {
                const writingStart = (wordlistPageNumber - 1) * resultFragmentPerPage
                const writingEnd = wordlistPageNumber * resultFragmentPerPage
                setResultsFragments(res.wordlist.slice(writingStart, writingEnd))
            }
        },
        [setResultsFragments, wordlistPageNumber]
    )

    // search all action
    const getWordlistSuccess = (response: WordListData) => {
        dispatch(setResults(response))
        if (response.wordlist.length > 0 && response.wordlist[0].Frequency === null) {
            setValidateStatus({
                isVaild: false,
                msg: 'Empty Search Result',
            })
        }

        if (!isInitSearchCompleted || searchParams.showentirewordlist) {
            setCsvName('wordlist-(all word)-result.xlsx')
            setSearchParams((prevState) => ({
                ...prevState,
                showentirewordlist: false,
            }))
        } else {
            setCsvName(`wordlist-(${searchParams.searchText})-result.xlsx`)
        }

        if (isInitSearchCompleted === false) {
            dispatch(setIsInitSearchCompleted(true))
        }

        setIsSearching(false)
        dispatch(setWordlistPageNumberAction(1))
    }
    const getWordlist = useCallback(() => {
        let { isVaild, msg } = checkWordListRequest(searchParams, isInitSearchCompleted)
        setValidateStatus({ isVaild, msg })
        if (!isVaild) {
            return
        }

        let minifiedSearchParams = getMinifiedWordListSearchParams(
            searchParams,
            language,
            isInitSearchCompleted
        )

        setIsSearching(true)
        if (language === 'english') {
            dispatch(
                searchWordListEn(
                    minifiedSearchParams,
                    (response: WordListData) => getWordlistSuccess(response),
                    () => setIsSearching(false)
                )
            )
        }

        if (language === 'chinese') {
            dispatch(
                searchWordListCn(
                    minifiedSearchParams,
                    (response: WordListData) => getWordlistSuccess(response),
                    () => setIsSearching(false)
                )
            )
        }
    }, [dispatch, language, searchParams, isInitSearchCompleted])

    // search text click
    const handleSearchParamsChange = useCallback(
        (params: Partial<WordListSearchQueryNew>) => {
            setSearchParams((prevState) => ({ ...prevState, ...params }))
        },
        [setSearchParams]
    )

    // search all click
    const handleSearchAllOnChange = useCallback(() => {
        setSearchParams((prevState) => ({
            ...prevState,
            showentirewordlist: true,
        }))
    }, [])

    useEffect(() => {
        if (searchParams.showentirewordlist || !isInitSearchCompleted) {
            getWordlist()
        }
    }, [searchParams.showentirewordlist])

    // handle paination
    useEffect(() => {
        if (!isSearching) {
            handleResultFragment(results)
        }
    }, [isSearching, wordlistPageNumber])

    return (
        <AppLayout
            AppHeader={() => (
                <AppHeaderWordList
                    banner={<Banner isShow={isBannerOpen} setIsShow={setisBannerOpen}></Banner>}
                    parentSearchParams={searchParams}
                    parentSetSearchParams={setSearchParams}
                    setIsModalOpen={setIsModalOpen}
                    setIsRegNewUserOpen={setIsRegNewUserOpen}
                ></AppHeaderWordList>
            )}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isRegNewUserOpen={isRegNewUserOpen}
            setIsRegNewUserOpen={setIsRegNewUserOpen}
        >
            <div className="app__sidebar">
                <div className={`sidebar_wrapper ${isSidebarActive ? 'active' : ''}`}>
                    <Sidebar handleSearchParamsChange={handleSearchParamsChange}></Sidebar>
                </div>
                <div
                    className={`sidebar-wrapper-overlay ${isSidebarActive ? 'active' : ''}`}
                    onClick={() => setIsSidebarActive(false)}
                ></div>
            </div>

            <div className="app__divider"></div>

            <div className="app__board">
                <div className="board-wrapper">
                    <BoardTab curTab="wordlist"></BoardTab>

                    <div className="board">
                        <SearchBar
                            parentSearchParams={searchParams}
                            handleSearchParamsChange={handleSearchParamsChange}
                            handleSearchOnSubmit={getWordlist}
                            handleSearchAllOnChange={handleSearchAllOnChange}
                            isSearching={isSearching}
                        ></SearchBar>

                        {validateStatus.isVaild && results.wordlist.length ? (
                            <div className="flex items-center justify-between my-10px">
                                <p className="text-sm sm:text-lg font-bold">Results</p>
                                <p className="text-sm sm:text-lg font-bold">
                                    {results?.wordlist.length > 1
                                        ? 'Type Count: ' + results?.wordlist.length
                                        : null}
                                </p>
                            </div>
                        ) : null}

                        <div className="resultBoard">
                            <div className="resultBoard__tableWrapper">
                                {validateStatus.isVaild ? (
                                    isSearching ? (
                                        <WordlistLoading />
                                    ) : results.wordlist.length ? (
                                        <ResultTable datas={resultsFragments}></ResultTable>
                                    ) : (
                                        <div className="h-full flex justify-center items-center">
                                            <p className="text-sm sm:text-lg font-bold">
                                                {validateStatus.msg}
                                            </p>
                                        </div>
                                    )
                                ) : (
                                    <div className="h-full flex justify-center items-center">
                                        <p className="text-sm sm:text-lg font-bold">
                                            {validateStatus.msg}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {validateStatus.isVaild && results.wordlist.length > 0 && !isSearching ? (
                            <BoardFooter
                                csvName={csvName}
                                resultFragmentPerPage={resultFragmentPerPage}
                                tableIndex={wordlistPageNumber}
                                setTableIndex={setWordlistPageNumberAction}
                            ></BoardFooter>
                        ) : null}
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default WordList
