import React from 'react'

type CheckboxInputProps = {
    label: string
    name: string
    isChecked: boolean
    // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onChange: (name: string, isChecked: boolean) => void
}

const CheckboxInputWithDefVal = ({ label, isChecked, name, onChange }: CheckboxInputProps) => {
    return (
        <div className="flex items-center">
            <input
                className="cell_checkbox"
                type="checkbox"
                name={name}
                checked={isChecked ? true : false}
                onChange={() => onChange(name, !isChecked)}
            />
            <label className="text-sm sm:text-base">{label}</label>
        </div>
    )
}

export default CheckboxInputWithDefVal
