import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loginFromBrowserStorage, logout } from '../../stores/epics/authEpics'
import { isSystemLoading, setIsLoading } from '../../stores/reducers/system'
import { Loading } from '../loading'

const MainLayout: FunctionComponent = ({ children }) => {
    const isLoading = useSelector(isSystemLoading)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            loginFromBrowserStorage(
                () => {
                    dispatch(setIsLoading(false))
                },
                () => {
                    dispatch(setIsLoading(false))
                }
            )
        )

        // window.addEventListener('beforeunload', () => dispatch(logout()))

        return () => {
            // window.removeEventListener('beforeunload', () => {})
        }
    }, [dispatch])

    if (isLoading) {
        return <Loading />
    }

    return <Fragment>{children}</Fragment>
}

export default MainLayout
