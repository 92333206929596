import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'

import 'swiper/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'

SwiperCore.use([EffectFade])
SwiperCore.use([Autoplay])

const LandingSwiper = () => {
    // const points = [
    //     'A 4-million-word English Corpus.',
    //     'An 18-million-character Chinese Corpus.',
    //     'Web-based Concordance and Word List features.',
    //     'Undergraduate-level academic essays from 10 categories of courses.',
    //     'Filter functions by essay sections and writers’ meta-data.',
    // ]

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            effect="fade"
            autoplay={{ delay: 3000 }}
            fadeEffect={{ crossFade: true }}

            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
                <p className="font-bold text-lg sm:text2xl lg:text-4xl ">
                    <span className="text-red-800">A 4-million-word</span> English Corpus.
                </p>
            </SwiperSlide>
            <SwiperSlide>
                <p className="font-bold text-lg sm:text2xl lg:text-4xl ">
                    An <span className="text-red-800">18-million-character</span> Chinese Corpus.
                </p>
            </SwiperSlide>
            <SwiperSlide>
                <p className="font-bold text-lg sm:text2xl lg:text-4xl ">
                    Web-based <span className="text-red-800">Concordance</span> and{' '}
                    <span className="text-red-800">Word List</span> features.
                </p>
            </SwiperSlide>
            <SwiperSlide>
                <p className="font-bold text-lg sm:text2xl lg:text-4xl ">
                    Undergraduate-level academic essays from{' '}
                    <span className="text-red-800">10 categories of courses</span>.
                </p>
            </SwiperSlide>
            <SwiperSlide>
                <p className="font-bold text-lg sm:text2xl lg:text-4xl ">
                    <span className="text-red-800">Filter functions</span> by essay sections and
                    writers’ meta-data.
                </p>
            </SwiperSlide>
        </Swiper>
    )
}

export default LandingSwiper
