import {
    createActionWithPayload,
    IActionWithPayload,
    OnErrorCallBack,
    OnRequestCallBack,
    OnSuccessCallBack,
} from '../../types'
import * as types from './types'

export interface ApiCallPayload {
    onRequest: OnRequestCallBack
    onSuccess?: OnSuccessCallBack
    onError?: OnErrorCallBack
}

export type SetIsLoadingAction = IActionWithPayload<typeof types.SET_IS_LOADING, boolean>
export type ApiRequestAction = IActionWithPayload<typeof types.API_REQUEST, ApiCallPayload>
export type SetCorpusLangAction = IActionWithPayload<typeof types.SET_CORPUS_LANG, string>
export type SetFontSizegAction = IActionWithPayload<typeof types.SET_FONT_SIZE, string>
export type SetIsSidebarActiveAction = IActionWithPayload<
    typeof types.SET_IS_SIDEBAR_ACTIVE,
    boolean
>
export type SystemActions =
    | SetIsLoadingAction
    | SetCorpusLangAction
    | SetFontSizegAction
    | SetIsSidebarActiveAction

export const setIsLoading = createActionWithPayload<SetIsLoadingAction>(types.SET_IS_LOADING)
export const apiCall = createActionWithPayload<ApiRequestAction>(types.API_REQUEST)
export const setCorpusLang = createActionWithPayload<SetCorpusLangAction>(types.SET_CORPUS_LANG)
export const setFontSize = createActionWithPayload<SetFontSizegAction>(types.SET_FONT_SIZE)
export const setIsSidebarActive = createActionWithPayload<SetIsSidebarActiveAction>(
    types.SET_IS_SIDEBAR_ACTIVE
)
