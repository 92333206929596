import { faSignOutAlt, faUserCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Banner from '../../../components/utility/Banner'
import {
    defaultConcordanceRequestCn,
    defaultConcordanceRequestEn,
} from '../../../data/DefaultRequest'
import useLanguage from '../../../hooks/useLanguage'
import { WordListSearchQueryNew } from '../../../stores/data-models/WordList'
import { logout } from '../../../stores/epics/authEpics'
import { getUser } from '../../../stores/reducers/auth'
import {
    setGlobalSearchParams,
    setResults as setConcordanceResults,
} from '../../../stores/reducers/concordance'
import { setResults as setWordListResults } from '../../../stores/reducers/wordlist'
import AppHeaderMoblieWordList from './AppHeaderMoblieWordList'

interface AppHeaderWordListProps {
    banner: JSX.Element | null
    parentSearchParams: WordListSearchQueryNew
    parentSetSearchParams: (newParams: WordListSearchQueryNew) => void
    setIsModalOpen: (flag: boolean) => void
    setIsRegNewUserOpen: (flag: boolean) => void
}

const AppHeaderWordList: FunctionComponent<AppHeaderWordListProps> = ({
    banner,
    parentSearchParams,
    parentSetSearchParams,
    setIsModalOpen,
    setIsRegNewUserOpen,
}) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const language = useLanguage()
    const user = useSelector(getUser)

    const handleOnClick = (lang: string) => {
        dispatch(
            setConcordanceResults({
                data: [],
                resultlength: 0,
                totalessay: 0,
                totalhits: 0,
            })
        )

        dispatch(
            setWordListResults({
                wordtypes: 0,
                wordtokens: 0,
                hits: 0,
                wordlist: [],
            })
        )

        dispatch(
            setGlobalSearchParams(
                cloneDeep(
                    lang === 'english' ? defaultConcordanceRequestEn : defaultConcordanceRequestCn
                )
            )
        )
    }

    // const handleFontSizeChange = (size: string) => {
    //     dispatch(setFontSize(size))
    // }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <React.Fragment>
            <AppHeaderMoblieWordList
                parentSearchParams={parentSearchParams}
                parentSetSearchParams={parentSetSearchParams}
                setIsModalOpen={setIsModalOpen}
                setIsRegNewUserOpen={setIsRegNewUserOpen}
            ></AppHeaderMoblieWordList>
            {banner}

            <header className="header header-fixed">
                <div className="w-full flex justify-between items-center">
                    <div className="flex justify-center items-center">
                        <div className="inline-flex items-center">
                            <Link to="/" className={`cursor-pointer`}>
                                <img
                                    className={`w-10`}
                                    src={require('../../../images/cell_corpus_cube2.svg')}
                                    alt=""
                                />
                            </Link>
                            <p
                                className={`text-white font-bold ml-2 text-sm sm:text-base xl:text-xl`}
                            >
                                CELL Corpus
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <Link
                            to="/concordance/en"
                            className={
                                'text-xl font-bold hover:text-cell-green duration-300 ' +
                                (language === 'english' &&
                                !location.pathname.includes('spoken-data-archive')
                                    ? 'text-cell-green'
                                    : 'text-white')
                            }
                            onClick={() => handleOnClick('english')}
                        >
                            English Corpus
                        </Link>
                        <Link
                            to="/concordance/cn"
                            className={
                                'ml-5 text-xl font-bold hover:text-cell-green duration-300 ' +
                                (language === 'chinese' &&
                                !location.pathname.includes('spoken-data-archive')
                                    ? 'text-cell-green'
                                    : 'text-white')
                            }
                            onClick={() => handleOnClick('chinese')}
                        >
                            Chinese Corpus
                        </Link>
                        {/* DISBALED SPOKEN DATA ARCHIVE - TEMPORARILY   */}
                        <Link
                            to="/spoken-data-archive"
                            className={
                                'ml-5 text-xl font-bold hover:text-cell-green duration-300 ' +
                                (location.pathname.includes('spoken-data-archive')
                                    ? 'text-cell-green'
                                    : 'text-white')
                            }
                        >
                            Spoken Data Archive
                        </Link>

                        <FontAwesomeIcon
                            className="ml-5 text-white hover:text-cell-green duration-300 cursor-pointer"
                            icon={faUserCircle}
                            size="2x"
                            onClick={() => setIsModalOpen(true)}
                        />

                        {user.isAdmin ? (
                            <FontAwesomeIcon
                                className="ml-5 text-white hover:text-cell-green duration-300 cursor-pointer"
                                icon={faUserPlus}
                                size="2x"
                                onClick={() => setIsRegNewUserOpen(true)}
                            />
                        ) : null}

                        <FontAwesomeIcon
                            className="ml-5 text-white hover:text-cell-green duration-300 cursor-pointer"
                            icon={faSignOutAlt}
                            size="2x"
                            onClick={handleLogout}
                        />

                        {/* <div className="ml-5 dropdown">
                            <img
                                className="w-10 hover:text-cell-green duration-300"
                                src={userSvg}
                                alt=""
                            />

                            <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={() => setIsModalOpen(true)}>
                                    Profile
                                </div>
                                {user.isAdmin ? (
                                    <div
                                        className="dropdown-item whitespace-no-wrap"
                                        onClick={() => setIsRegNewUserOpen(true)}
                                    >
                                        Register New User
                                    </div>
                                ) : null}
                                <div className="dropdown-item" onClick={handleLogout}>
                                    Logout
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default AppHeaderWordList
