import { cloneDeep } from 'lodash'
import { ConcordanceData } from '../../stores/data-models/Assignment'
/**
 * @biref handle left text & right text
 */
export const getWords = (data: ConcordanceData, dir: string, lang: string) => {
    // let anyEngCharsRegex = /([^A-Za-z])/
    // let anyChiCharsRegex = /\s*(?:\uD805\uDC4D|\uD836\uDE87|[\u002C\u02BB\u060C\u2E32\u2E34\u2E41\u2E49\u3001\uFE10\uFE11\uFE50\uFE51\uFF0C\uFF64\u00B7\u055D\u07F8\u1363\u1802\u1808\uA4FE\uA60D\uA6F5\u02BD\u0312\u0313\u0314\u0315\u0326\u201A])\s*/
    // let anyChiCharsRegex = ' '
    // const spliter = lang === 'english' ? anyEngCharsRegex : anyChiCharsRegex
    const spliter = /(\s+)/

    let words =
        dir === 'L'
            ? data.leftText
                  .split(spliter)
                  .reverse()
                  .filter((text) => text)
            : data.rightText.split(spliter).filter((text) => text)
    // console.log(words);
    return words
    // return dir === 'L' ? data.leftText.split(spliter).reverse() : data.rightText.split(spliter)
}

/**
 * @brief 0 pure word | 1 number | 2 punctuation | 3 whitespace | 4 dirty word
 */
export const getWordType = (word: string) => {
    let isWord = /^[a-zA-Z]+$/g
    let isPunctuation = /^[!"#$%&'()*+,\-./:;<=>?@[\\\]^_‘{|}~]+$/g
    let isNum = /^\d+$/
    let isChineseChar = /\p{Script=Han}/u

    if (!isWord.test(word)) {
        // if only number skip
        if (isNum.test(word)) {
            // console.log(`${word}......pure number`)
            return 1
        }
        // if only punctuation skip
        else if (isPunctuation.test(word)) {
            // console.log(`${word}......pure punctuation`)
            return 2
        }
        // if only whitespace skip
        else if (word === ' ') {
            return 3
        }
        // if only chinese punctuation skip
        else if (!isChineseChar.test(word)) {
            return 4
        }
        // // if dirty word
        // else {
        //     // console.log(`${word}......dirty word`)
        //     return 4
        // }
    }

    return 0
}

/**
 * @brief get next sortable, word or charater
 */
export const getSortable = (text: string[], startIdx: number): number => {
    if (text.length > startIdx) {
        let type = getWordType(text[startIdx])

        if (type > 0) {
            return getSortable(text, startIdx + 1)
        }

        return startIdx
    }
    return -1
}

/**
 * @brief get nth sortable
 */
export const getNthSortableIndex = (words: string[], nth: number): number => {
    let nthWordIdx = 0

    for (let i = 0; i < nth + 1; i++) {
        if (i === 0) {
            nthWordIdx = getSortable(words, 0)
        } else {
            nthWordIdx = getSortable(words, nthWordIdx + 1)
        }
    }

    return nthWordIdx
}

/**
 * @biref get [duplicate word, start index, end index] of previous sorted Concordance data[]
 */
const handleAddToBeSort = (
    currWord: string,
    nextWord: string,
    toBeSorts: {
        word: string
        startIdx: number
        endIdx: number
    }[],
    dataIdx: number
) => {
    let lastIndex = toBeSorts.length - 1

    if (currWord === nextWord) {
        if (toBeSorts.length > 0) {
            // console.log(currWord, nextWord, dataIdx, toBeSorts[lastIndex].endIdx)

            if (toBeSorts[lastIndex].word === currWord && toBeSorts[lastIndex].endIdx === dataIdx) {
                toBeSorts[lastIndex].endIdx++
            } else {
                toBeSorts.push({
                    word: currWord,
                    startIdx: dataIdx,
                    endIdx: dataIdx + 1,
                })
            }
        } else {
            toBeSorts.push({
                word: currWord,
                startIdx: dataIdx,
                endIdx: dataIdx + 1,
            })
        }
    }
}
export const getToBeSort = (
    sortedData: ConcordanceData[],
    idx: number,
    lang: string,
    colorTarget: string[][],
    oldToBeSorts: {
        word: string
        startIdx: number
        endIdx: number
    }[] = []
) => {
    let levelTarget = idx - 1
    let sortedDataCopy = cloneDeep(sortedData)
    let toBeSorts: {
        word: string
        startIdx: number
        endIdx: number
    }[] = []

    if (colorTarget[levelTarget].length === 2) {
        let prev_col = +colorTarget[levelTarget][0] - 1
        let prev_dir = colorTarget[levelTarget][1]

        sortedDataCopy.forEach((currData, dataIdx) => {
            if (sortedDataCopy.length > dataIdx + 1) {
                let nextData = sortedDataCopy[dataIdx + 1]
                // console.log(dataIdx, dataIdx + 1)

                let currWords = getWords(currData, prev_dir, lang)
                let nextWords = getWords(nextData, prev_dir, lang)

                let currWordIdx = getNthSortableIndex(currWords, prev_col)
                let nextWordIdx = getNthSortableIndex(nextWords, prev_col)

                if (currWordIdx === -1 || nextWordIdx === -1) {
                    return
                }

                let currWord = currWords[currWordIdx]
                let nextWord = nextWords[nextWordIdx]

                handleAddToBeSort(currWord, nextWord, toBeSorts, dataIdx)
            }
        })
    } else {
        sortedDataCopy.forEach((currData, dataIdx) => {
            if (sortedDataCopy.length > dataIdx + 1) {
                let nextData = sortedDataCopy[dataIdx + 1]

                let currWord = currData.searchText
                let nextWord = nextData.searchText

                handleAddToBeSort(currWord, nextWord, toBeSorts, dataIdx)
            }
        })
    }

    // check each sort range are inside each old sort
    if (oldToBeSorts.length > 0) {
        let vaildToBeSort: {
            word: string
            startIdx: number
            endIdx: number
        }[] = []

        toBeSorts.forEach((toBeSort) => {
            oldToBeSorts.forEach((oldToBeSort) => {
                if (
                    toBeSort.startIdx >= oldToBeSort.startIdx &&
                    toBeSort.endIdx <= oldToBeSort.endIdx
                ) {
                    vaildToBeSort.push(toBeSort)
                }
            })
        })

        // console.log(
        //     `idx`,
        //     idx,
        //     `oldToBeSorts`,
        //     oldToBeSorts,
        //     `toBeSorts`,
        //     toBeSorts,
        //     `vaildToBeSort`,
        //     vaildToBeSort
        // )
        return vaildToBeSort
    }

    return toBeSorts
}

/**
 * rearrange result array
 */
const rearrangeResults = (
    toBeSorts: {
        word: string
        startIdx: number
        endIdx: number
    }[],
    resultsDataCopy: ConcordanceData[],
    idx: number,
    lang: string,
    colorTarget: string[][]
): ConcordanceData[] => {
    let copy = cloneDeep(resultsDataCopy)

    toBeSorts.forEach((toBeSort) => {
        let body = copy.splice(toBeSort.startIdx, toBeSort.endIdx - toBeSort.startIdx + 1)
        let head = copy.splice(0, toBeSort.startIdx)

        body.sort((a, b) => {
            if (+colorTarget[idx].length === 1) {
                return a.searchText.localeCompare(b.searchText)
            } else {
                let nthCol = +colorTarget[idx][0] - 1
                let dir = colorTarget[idx][1]

                let aWords = getWords(a, dir, lang)
                let bWords = getWords(b, dir, lang)

                let aWordsIdx = getNthSortableIndex(aWords, nthCol)
                let bWordsIdx = getNthSortableIndex(bWords, nthCol)

                // if end of sentense
                if (aWordsIdx === -1 || bWordsIdx === -1) {
                    return 0
                }

                let aWord = aWords[aWordsIdx]
                let bWord = bWords[bWordsIdx]

                // alphabetical order
                return aWord.localeCompare(bWord)
            }
        })

        copy = head.concat(body).concat(copy)
    })

    return copy
}

/**
 * handle KWIC Sorting
 */
export const handleKWICSorting = (
    resultsData: ConcordanceData[],
    isKwicSort: boolean,
    colorTarget: string[][],
    lang: string
) => {
    // console.log('Result table run handleResults()...')
    let resultsDataCopy: ConcordanceData[] = [...resultsData]

    // clear result data
    // console.log('before clear resultsDataCopy ', resultsDataCopy)
    for (const resultsDataCopyIdx in resultsDataCopy) {
        let data = resultsDataCopy[resultsDataCopyIdx]

        // remove leading & tailing space
        data.leftText = data.leftText.trim()
        data.rightText = data.rightText.trim()

        // multi whitespace 2 one whitespace OR remove all whitespace
        data.leftText = data.leftText.replace(/\s\s+/g, ' ')
        data.rightText = data.rightText.replace(/\s\s+/g, ' ')

        // remove all line break
        data.leftText = data.leftText.replace(/\r?\n|\r/, '')
        data.rightText = data.rightText.replace(/\r?\n|\r/, '')
    }
    // console.log('After clean result data: ', resultsDataCopy)

    if (isKwicSort) {
        let toBeSorts: {
            word: string
            startIdx: number
            endIdx: number
        }[] = []

        for (let idx = 0; idx < 3; idx++) {
            if (idx === 0) {
                resultsDataCopy.sort((a, b) => {
                    if (+colorTarget[idx].length === 1) {
                        return a.searchText.localeCompare(b.searchText)
                    } else {
                        let nthCol = +colorTarget[idx][0] - 1
                        let dir = colorTarget[idx][1]

                        let aWords = getWords(a, dir, lang)
                        let bWords = getWords(b, dir, lang)

                        let aWordsIdx = getNthSortableIndex(aWords, nthCol)
                        let bWordsIdx = getNthSortableIndex(bWords, nthCol)

                        if (aWordsIdx === -1 || bWordsIdx === -1) {
                            return 0
                        }

                        let aWord = aWords[aWordsIdx]
                        let bWord = bWords[bWordsIdx]

                        return aWord.localeCompare(bWord)
                    }
                })
            }

            if (idx === 1) {
                toBeSorts = getToBeSort(resultsDataCopy, idx, lang, colorTarget)
                // console.log('toBeSorts', toBeSorts)

                resultsDataCopy = rearrangeResults(
                    toBeSorts,
                    resultsDataCopy,
                    idx,
                    lang,
                    colorTarget
                )
            }

            if (idx === 2) {
                let toBeSorts2 = getToBeSort(resultsDataCopy, idx, lang, colorTarget, toBeSorts)
                // console.log(`toBeSorts2`, toBeSorts2)

                resultsDataCopy = rearrangeResults(
                    toBeSorts2,
                    resultsDataCopy,
                    idx,
                    lang,
                    colorTarget
                )
            }
        }
    }

    return resultsDataCopy
}
