import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent, useState } from 'react'

import FrontPageLayout from '../../components/layouts/FrontPageLayout'
import bg from '../../images/front_page/bg-svg/wave-header-green.svg'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import GuideTable from './GuideTable'
import icon from '../../images/RIBiLT Logo (blue without stroke)_transprant.png'
import pic4 from '../../images/user_guide/4.png'
import pic6 from '../../images/user_guide/6.png'
import pic9 from '../../images/user_guide/9.png'
import pic12 from '../../images/user_guide/12.png'
import pic16 from '../../images/user_guide/16.png'
import pic18 from '../../images/user_guide/18.png'
import pic26 from '../../images/user_guide/26.png'
import pic27 from '../../images/user_guide/27.png'

const Guide: FunctionComponent = () => {
    const [currentTable, setCurrentTable] = useState('english')
    const [currentTaggerFile, setCurrentTaggerFile] = useState(
        '/Table 1 List of English taggers.pdf'
    )

    const setTaggerFile = (type: string) => {
        if (type === 'english') {
            setCurrentTable('english')
            setCurrentTaggerFile('/Table 1 List of English taggers.pdf')
        } else {
            setCurrentTable('chinese')
            setCurrentTaggerFile('/Table 2 List of Chinese taggers.pdf')
        }
    }

    return (
        <FrontPageLayout>
            <div
                className="flex flex-col justify-between w-full h-screen px-10px sm:px-140px bg-no-repeat bg-cover sm:bg-contain"
                style={{
                    backgroundImage: `url(${bg})`,
                }}
            >
                <div></div>
                <div className="mx-auto sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-center text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline  text-white sm:text-black">
                            User Guide
                        </span>
                    </h1>
                </div>

                <div className="flex justify-center w-full">
                    <img
                        alt={icon}
                        className="w-12"
                        src={require('../../images/front_page/icon/down-chevron.svg')}
                    />
                </div>
            </div>

            <div className="flex justify-center items-start w-full px-10px sm:px-140px mt-20px sm:mt-48px">
                {/*  */}
                <div className="w-full md:4/5 xl:w-2/3">
                    <div className="container py-10">
                        <div className="flex flex-col justify-center">
                            <h2 className="heading text-page-heading font-bold text-3xl">
                                Concordance
                            </h2>

                            <div className="bg-white w-full mt-5">
                                <ul className="list-decimal list-inside">
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Type a word or phrase you want to search in the Corpus, for
                                        example “education”.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                For the Chinese Corpus, please type in Simplified
                                                Chinese.
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you want to specify your search, for example, the
                                        concordance lines of “education” in a particular course,
                                        please click “Categories of Courses” and check the relevant
                                        box to specify the course.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can also click “Sections”, and then check the relevant
                                        box to specify the part of the essay you want to search.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you want to limit the search result based on “Gender”,
                                        “Place of Birth”, “First Language” or/and “Public
                                        Examination Results”, cancel “Select All” and click the
                                        box(es) needed, as shown below.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img alt={icon} className="w-full" src={pic4}></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Press “Search” and you will be directed to the “Results”
                                        page.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can find the search filters you have applied below.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img alt={icon} className="w-full" src={pic6}></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can unclick the cross (
                                        <FontAwesomeIcon
                                            className="mx-5px"
                                            style={{ color: '#707070' }}
                                            icon={faTimes}
                                        />
                                        ) button to cancel the filters.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Click “Search” again to get the new results.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can find the number of hits of your search results right
                                        here
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img alt={icon} className="w-full" src={pic9}></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you find it necessary, you can adjust the “Page Size”
                                        here. You can either click the ‘up’ and ‘down’ arrows or
                                        type the number of lines to be shown on one page (maximum
                                        1000 lines).
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        In order to show the changes, you need to click “Search”
                                        again.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can go to different pages by selecting the numbers or typing a number here.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img
                                                    alt={icon}
                                                    className="w-full"
                                                    src={pic12}
                                                ></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        The concordance lines are sorted based on the default
                                        settings. There are three levels of sorting and each level
                                        is presented in different colours as shown below (Level 1 is
                                        red; Level 2 is green; Level 3 is purple).)
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can change the sorting parameters by clicking the two
                                        arrows for each Level. For example, if you set Level 1 as 1R
                                        and Level 2 as 1L, the concordance lines will be sorted
                                        first based on the first word to the right of the search
                                        word (in red) and then based on the first word to the left
                                        of the search word (in green).
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Please see below, by default, the words / characters to be
                                        shown on the left and right sides of the search itemin the
                                        English and Chinese corpora respectively.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                Window size = 80 characters;
                                            </li>
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                Enlarged text size = 200 characters;
                                            </li>
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                Default page size = 100 lines
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can get a random result by clicking “Random” as shown
                                        below.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img
                                                    alt={icon}
                                                    className="w-full"
                                                    src={pic16}
                                                ></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Click “Search” again to get the adjusted results.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        By clicking “Case” as shown below, you can distinguish the
                                        search results between uppercase and lowercase letters
                                        according to the search word or phrases you type. For
                                        example, if you type in lowercase, it only shows the
                                        lowercase search words.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img
                                                    alt={icon}
                                                    className="w-full"
                                                    src={pic18}
                                                ></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Click “Search” again to get the adjusted results.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you want to type a "grammatical pattern", please note that there can only be one POS tag (i.e. *_NN) in the pattern. Only one wildcard item (i.e. *) is allowed in the pattern you type.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <div className="bg-white">
                                                    <nav className="flex flex-col sm:flex-row">
                                                        <button
                                                            className={`text-gray-900 py-4 px-6 block hover:text-gray-500 focus:outline-none ${
                                                                currentTable === 'english'
                                                                    ? 'border-b-2 font-medium'
                                                                    : ''
                                                            } `}
                                                            onClick={() => setTaggerFile('english')}
                                                        >
                                                            English Taggers List
                                                        </button>
                                                        <button
                                                            className={`text-gray-900 py-4 px-6 block hover:text-gray-500 focus:outline-none ${
                                                                currentTable === 'chinese'
                                                                    ? 'border-b-2 font-medium'
                                                                    : ''
                                                            }`}
                                                            onClick={() => setTaggerFile('chinese')}
                                                        >
                                                            Chinese Taggers List
                                                        </button>
                                                    </nav>
                                                </div>
                                            </li>
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <GuideTable
                                                    taggerPdfFile={currentTaggerFile}
                                                ></GuideTable>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can click “Download Results on This Page” to export your
                                        data toan Excel document. The number of corcordance lines to
                                        be downloaded at a time corresponds to the “Page Size”
                                        number. For example, if the “Page Size” is set as 100, it
                                        means 100 concordance lines will appear as results on a page
                                        and these 100 lines are to be downloaded if “Download
                                        Results on This Page” appearing on this page of results is
                                        clicked. The maximum “Page Size” is 1000.. You are suggested
                                        to set the “Page Size” as 1000 if the total number of
                                        concordance lines to be downloaded is large.
                                    </li>
                                </ul>
                            </div>

                            <h2 className="heading text-page-heading font-bold text-3xl mt-10">
                                Word List
                            </h2>

                            <div className="bg-white w-full mt-5">
                                <ul className="list-decimal list-inside">
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you are searching for the frequency of a particular word,
                                        you can get the result through “Word List”.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Type a word or phrase you want to search, for example
                                        “education”.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        Press “Search” to get the results.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you want to get the complete list of all word types and
                                        their corresponding frequencies, click “Search ALL”.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can sort their frequencies in either the ascending or
                                        descending order here.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img
                                                    alt={icon}
                                                    className="w-full"
                                                    src={pic26}
                                                ></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can see the number of “Type Count” here.
                                        <ul className="">
                                            <li className="p-2 text-gray-700 hover:bg-gray-100 cursor-pointer italic">
                                                <img
                                                    alt={icon}
                                                    className="w-full"
                                                    src={pic27}
                                                ></img>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can click “Download Results on This Page” to export your
                                        data toan Excel document.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        You can click any words on the result list to get the
                                        concordance lines of the words. If you have selected any
                                        filters, please select them again when switching from Word
                                        List page to the Concordance page.
                                    </li>
                                    <li className="p-4 hover:bg-gray-100 cursor-pointer">
                                        If you have applied filters, e.g. only shows the word list
                                        in ‘Education Theories’ courses, then click “Search All” to
                                        get a word list for this category of courses.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
            </div>
        </FrontPageLayout>
    )
}

export default Guide
