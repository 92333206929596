import { ConcordanceSearchQueryNew } from '../../stores/data-models/Assignment'
import { WordListSearchQueryNew } from '../../stores/data-models/WordList'
import { requestFieldName, checkBoxFieldName, requestFieldNameWordList } from '../../data/checkbox'
import { getAllOptionsLength, getAllWordListOptionsLength } from '../../data/ApiOption'

// const optionsLength = getAllOptionsLength()

export const getMinifiedConcordanceSearchParams = (
    searchParams: ConcordanceSearchQueryNew,
    wordlistSearchText: string,
    lang: string
) => {
    const optionsLength = getAllOptionsLength(lang)
    let minifiedSearchParams: Partial<ConcordanceSearchQueryNew> = {}

    minifiedSearchParams.pagesize = searchParams.pagesize
    minifiedSearchParams.searchText = wordlistSearchText
        ? wordlistSearchText
        : searchParams.searchText
    minifiedSearchParams.randomSample = searchParams.randomSample
    minifiedSearchParams.case = searchParams.case

    if (searchParams.randomSample) {
        minifiedSearchParams.sortBy = searchParams.sortBy
    } else {
        minifiedSearchParams.pagenumber = searchParams.pagenumber
    }

    for (const name in searchParams) {
        if (Object.prototype.hasOwnProperty.call(searchParams, name)) {
            const val = searchParams[name as requestFieldName]

            if (name === 'tagsearch' && val === true) {
                minifiedSearchParams.tagsearch = val
            }

            if (name === 'tagsearch' && val === false) {
                minifiedSearchParams.segmentsearch = !val
            }

            if (name === 'randomSample' && val === true) {
                minifiedSearchParams.randomSample = val
                delete minifiedSearchParams.pagenumber
            }

            if (name === 'category' && typeof val === 'string' && val !== '') {
                minifiedSearchParams.category = val
            }

            if (name === 'section' && typeof val === 'string' && val !== 'all') {
                minifiedSearchParams.section = val
            }
        }
    }

    minifiedSearchParams.metadata = { ...searchParams.metadata }
    for (const name in searchParams.metadata) {
        if (Object.prototype.hasOwnProperty.call(searchParams.metadata, name)) {
            const metadataArr = searchParams.metadata[name as checkBoxFieldName]

            if (metadataArr.length === optionsLength[name as checkBoxFieldName]) {
                delete minifiedSearchParams.metadata[name as checkBoxFieldName]
            }
        }
    }
    delete minifiedSearchParams.metadata.age

    if (
        Object.keys(minifiedSearchParams.metadata).length === 0 &&
        minifiedSearchParams.metadata.constructor === Object
    ) {
        delete minifiedSearchParams.metadata
    }

    return minifiedSearchParams
}

export const getMinifiedWordListSearchParams = (
    searchParams: WordListSearchQueryNew,
    lang: string,
    isInitSearchCompleted: boolean
) => {
    const optionsLength = getAllWordListOptionsLength()
    let minifiedSearchParams: Partial<WordListSearchQueryNew> = {}

    // search all or init search
    if (searchParams.showentirewordlist) {
        minifiedSearchParams.showentirewordlist = searchParams.showentirewordlist
        minifiedSearchParams.sortby = searchParams.sortby
    } else {
        minifiedSearchParams.searchText = searchParams.searchText
    }

    // init search
    if (!isInitSearchCompleted) {
        minifiedSearchParams.showentirewordlist = true
        minifiedSearchParams.sortby = searchParams.sortby
        delete minifiedSearchParams.searchText
    }

    for (const name in searchParams) {
        if (Object.prototype.hasOwnProperty.call(searchParams, name)) {
            const val = searchParams[name as requestFieldNameWordList]

            if (name === 'sortby' && val === 'ascFrequency') {
                minifiedSearchParams.sortby = val
            }

            if (name === 'metadatasearch' && val === true) {
                minifiedSearchParams.metadatasearch = val
            }

            if (name === 'category' && typeof val === 'string' && val !== '') {
                minifiedSearchParams.category = val
            }

            if (name === 'section' && typeof val === 'string' && val !== 'all') {
                minifiedSearchParams.section = val
            }
        }
    }

    minifiedSearchParams.metadata = { ...searchParams.metadata }
    for (const name in searchParams.metadata) {
        if (Object.prototype.hasOwnProperty.call(searchParams.metadata, name)) {
            const metadataArr = searchParams.metadata[name as checkBoxFieldName]

            if (metadataArr.length === optionsLength[name as checkBoxFieldName]) {
                delete minifiedSearchParams.metadata[name as checkBoxFieldName]
            }
        }
    }
    delete minifiedSearchParams.metadata.age

    if (
        Object.keys(minifiedSearchParams.metadata).length === 0 &&
        minifiedSearchParams.metadata.constructor === Object
    ) {
        delete minifiedSearchParams.metadata
    }

    return minifiedSearchParams
}

export const checkConcordanceRequest = (
    searchParams: ConcordanceSearchQueryNew,
    wordlistSearchText: string,
    language: string
) => {
    // check search text exists
    if (!searchParams.searchText && !wordlistSearchText) {
        return {
            isVaild: false,
            msg: 'Invalid search: search text cannot empty',
        }
    }

    // check tag search vaild
    if (searchParams.searchText.includes('*_') && !searchParams.tagsearch) {
        return {
            isVaild: false,
            msg: 'Invalid search: please click tag checkbox when tag searching',
        }
    }
    if (searchParams.tagsearch) {
        const englishTagVaildRegex = /^\w+_[a-zA-Z]+$|^\*_[a-zA-Z]+$/g

        const chineseTagVaildRegex = /^([\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d])+_[a-zA-Z]+$|^\*_[a-zA-Z]+$/g

        const regex = language === 'english' ? englishTagVaildRegex : chineseTagVaildRegex
        const isTagTextVails = regex.test(searchParams.searchText)

        // console.log(`req helper: language ${language} test ${regex.test(searchParams.searchText)}`)
        // console.log(`isTagTextVails ${isTagTextVails}`)

        if (!isTagTextVails) {
            return {
                isVaild: false,
                msg: `Invalid search: Search text format is not correct.`,
            }
        }
    }

    // check metadata option
    for (const name in searchParams.metadata) {
        if (name === 'age') {
            continue
        }

        if (Object.prototype.hasOwnProperty.call(searchParams.metadata, name)) {
            const options = searchParams.metadata[name as checkBoxFieldName]

            if (options.length === 0) {
                return {
                    isVaild: false,
                    msg: `Invalid search: metadata option ${name} cannot empty`,
                }
            }
        }
    }

    return {
        isVaild: true,
        msg: '',
    }
}

export const checkWordListRequest = (
    searchParams: WordListSearchQueryNew,
    isInitSearchCompleted: boolean
) => {
    // check search text exists
    if (!searchParams.showentirewordlist && !searchParams.searchText && isInitSearchCompleted) {
        return {
            isVaild: false,
            msg: 'Invalid search: search text cannot empty',
        }
    }

    // check metadata option
    for (const name in searchParams.metadata) {
        if (name === 'age') {
            continue
        }

        if (Object.prototype.hasOwnProperty.call(searchParams.metadata, name)) {
            const options = searchParams.metadata[name as checkBoxFieldName]
            // console.log(name, options.length)

            if (options.length === 0) {
                return {
                    isVaild: false,
                    msg: `Invalid search: metadata option ${name} cannot empty`,
                }
            }
        }
    }

    return {
        isVaild: true,
        msg: '',
    }
}
