import React from 'react'
import useScript from '../../../hooks/useScript'

const HitCount = () => {
    useScript(`https://visitorshitcounter.com/js/hitCounter.js?v=${Date.now()}`)

    return (
        <>
            {/* Badge Code - Do Not Change The Code */}
            <a
                className="hitCounter"
                href="https://visitorshitcounter.com/"
                target="_blank"
                title="Hit counter"
                data-name="dd966b6bd07c748a1dd7b5d354fe6d0a|6|page|1|rgb(80, 86, 234);|#ffffff|small|s-hit"
            >
                Loading
            </a>
            {/* Badge Code End Here */}
        </>
    )
}

export default HitCount
