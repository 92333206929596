import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'

import React from 'react'

import { ConcordanceSearchQueryNew, ConcordanceData } from '../../../stores/data-models/Assignment'
// import Pagination from './Pagination'
import PaginationWithInput from './PaginationWithInput'

type csvTitleType = 'searchText' | 'enlargedLine' | 'leftText' | 'line' | 'rightText'

type BoardFooterProps = {
    parentSearchParams: ConcordanceSearchQueryNew
    parentSetSearchParams: (newParams: ConcordanceSearchQueryNew) => void
    kwicResults: ConcordanceData[]
    handlePageNumberSearch: (pageNumber: number) => void
}

const BoardFooter = ({
    parentSearchParams,
    parentSetSearchParams,
    kwicResults,
    handlePageNumberSearch,
}: BoardFooterProps) => {
    const genXlxs = (data: ConcordanceData[]) => {
        let copy = []
        // left text, kwic, right text, uid;  no enlarged line, no line, add
        let col_title = ['lineNumber', 'leftText', 'searchText', 'rightText', 'uid']
        copy.push(col_title)

        let csvArr = [...data]
        csvArr.map((csv_data_row, csv_data_row_idx) => {
            let tempArr: string[] = []
            tempArr[0] = (csv_data_row_idx + 1).toString()

            for (const key in csv_data_row) {
                if (Object.prototype.hasOwnProperty.call(csv_data_row, key)) {
                    const element = csv_data_row[key as csvTitleType]

                    col_title.map((title, title_idx) => {
                        if (key === title) {
                            tempArr[title_idx] = element
                        }
                        return true
                    })
                }
            }
            copy.push(tempArr)
            return true
        })

        var worksheet = XLSX.utils.aoa_to_sheet(copy)
        var new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
        var wbout = XLSX.write(new_workbook, { bookType: 'xlsx', type: 'binary' })

        let buf = new ArrayBuffer(wbout.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i < buf.byteLength; i++) {
            view[i] = wbout.charCodeAt(i) & 0xff
        }
        FileSaver.saveAs(
            new Blob([buf]),
            'concordance-(' + parentSearchParams.searchText + ')-result.xlsx'
        )
    }

    return (
        <div className="board__footer">
            <div className="board__footer__downloadArea">
                <button
                    className={`ml-0 sm:ml-10px px-10px py-5px h-full rounded duration-300 bg-primary-300 hover:bg-primary-600 text-center text-white text-sm sm:text-lg`}
                    onClick={() => genXlxs(kwicResults)}
                >
                    Download Results on This Page
                </button>
            </div>

            <div className="board__footer__paginationArea">
                {parentSearchParams.randomSample ? null : (
                    <PaginationWithInput
                        parentSearchParams={parentSearchParams}
                        parentSetSearchParams={parentSetSearchParams}
                        handlePageNumberSearch={handlePageNumberSearch}
                    ></PaginationWithInput>
                )}
            </div>
        </div>
    )
}

export default BoardFooter
