import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AuthService, ProfileService } from '../../ApiServices'
import {
    clearAuthTokenFromBrowserStorage,
    getAuthTokenFromBrowserStorage,
    setAuthTokenToBrowserStorage,
} from '../../utils/helpers'
import {
    User,
    UserLoginRequest,
    UserRegistrationRequest,
    UserWithToken,
    RegisterNewUserRequest,
    UserWithStatus,
} from '../data-models'
import { clearUser, setToken, setUser, setIsRegistering } from '../reducers/auth'
import { apiCall } from '../reducers/system'
import { OnErrorCallBack, OnSuccessCallBack, RootState } from '../types'

export const register = (
    userData: UserRegistrationRequest,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => AuthService.register(userData),
            onSuccess: async ({ token, ...user }: UserWithToken) => {
                dispatch(setIsRegistering(true))
                // dispatch(setUser(user))
                // dispatch(setToken(token))
                // await setAuthTokenToBrowserStorage(token)

                onSuccess()
            },
            onError: (error: any) => {
                if (error.response && error.response.status === 422) {
                    onError(error.response.data.data)
                }
            },
        })
    )
}

export const registernewuser = (
    userData: RegisterNewUserRequest,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => AuthService.registernewuser(userData),
            onSuccess: async (data: UserWithStatus) => {
                onSuccess(data)
            },
            onError: (error: any) => {
                if (error.response && error.response.status === 422) {
                    onError(error.response.data.data)
                }
            },
        })
    )
}

export const login = (
    credential: UserLoginRequest,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => AuthService.login(credential),
            onSuccess: async ({ token, ...user }: UserWithToken) => {
                dispatch(setUser(user))
                dispatch(setToken(token))

                await setAuthTokenToBrowserStorage(token)

                onSuccess()
            },
            onError: (error: any) => {
                if (!error.response) {
                    throw error
                }

                if (error.response.status === 401) {
                    onError({
                        error: error.response.data.message,
                    })
                }

                if (error.response.status === 422) {
                    onError({
                        validations: error.response.data.data,
                    })
                }
            },
        })
    )
}

export const loginFromBrowserStorage = (
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    const token = await getAuthTokenFromBrowserStorage()

    if (!token) {
        onError()
        return
    }

    dispatch(
        apiCall({
            onRequest: async () => ProfileService.getProfile(),
            onSuccess: async (user: User) => {
                dispatch(setUser(user))
                dispatch(setToken(token))

                onSuccess()
            },
            onError: (error: any) => {
              // console.log('Not logged in!')
                onError()
            },
        })
    )
}

export const logout = (): ThunkAction<void, RootState, null, Action<string>> => async (
    dispatch
) => {
    await clearAuthTokenFromBrowserStorage()

    dispatch(clearUser())
    dispatch(setToken(''))
}
