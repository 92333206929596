import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { setResults } from '../../../stores/reducers/concordance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faFileWord } from '@fortawesome/free-solid-svg-icons'
import HeaderMobileMeun from '../../../components/utility/HeaderMobileMeun/HeaderMobileMeun'
import cubeSvg from '../../../images/cell_corpus_cube2.svg'

interface AppHeaderMoblieTranscribedFileProps {
    setIsModalOpen: (flag: boolean) => void
    setIsRegNewUserOpen: (flag: boolean) => void
    isFileListOpen: boolean
    setIsFileListOpen: (flag: boolean) => void
}

const AppHeaderMoblieTranscribedFile: FunctionComponent<AppHeaderMoblieTranscribedFileProps> = ({
    setIsModalOpen,
    setIsRegNewUserOpen,
    isFileListOpen,
    setIsFileListOpen,
}) => {
    const dispatch = useDispatch()
    const [isMeunActive, setIsMeunActive] = useState(false)

    const handleOnClick = (lang: string) => {
        dispatch(
            setResults({
                data: [],
                resultlength: 0,
                totalessay: 0,
                totalhits: 0,
            })
        )
    }

    // const handleFontSizeChange = (size: string) => {
    //     dispatch(setFontSize(size))
    // }

    return (
        <React.Fragment>
            <div className="header-mobile items-center header-mobile-fixed">
                <div className="flex items-center">
                    <button
                        className="text-white text-center px-10px py-5px h-full rounded duration-300 hover:text-cell-green"
                        onClick={(e) => setIsFileListOpen(!isFileListOpen)}
                    >
                        <FontAwesomeIcon size="1x" icon={faFileWord} />
                    </button>
                </div>

                <div className="flex justify-center items-center">
                    <Link to="/" className={`cursor-pointer`}>
                        <img className={`w-8`} src={cubeSvg} alt="" />
                    </Link>
                </div>

                <div className="flex items-center">
                    <button
                        className="text-white text-center px-10px py-5px h-full rounded duration-300 hover:text-cell-green"
                        onClick={(e) => {
                            setIsMeunActive(!isMeunActive)
                            setIsModalOpen(false)
                            setIsRegNewUserOpen(false)
                        }}
                    >
                        <FontAwesomeIcon size="1x" icon={faBars} />
                    </button>
                </div>
            </div>

            <div className={`header-mobile-meun-wrapper ${isMeunActive ? 'active' : ''}`}>
                <HeaderMobileMeun
                    handleOnClick={handleOnClick}
                    setIsModalOpen={setIsModalOpen}
                    setIsMeunActive={setIsMeunActive}
                    setIsRegNewUserOpen={setIsRegNewUserOpen}
                ></HeaderMobileMeun>
            </div>

            {isMeunActive ? (
                <div
                    className="header-menu-wrapper-overlay"
                    onClick={(e) => setIsMeunActive(false)}
                ></div>
            ) : null}
        </React.Fragment>
    )
}

export default AppHeaderMoblieTranscribedFile
