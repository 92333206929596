import React, { FormEvent, ChangeEvent, useState, useCallback, useEffect } from 'react'
import { WordListSearchQueryNew } from '../../../stores/data-models/WordList'
import { defaultWordListRequest } from '../../../data/DefaultRequest'
import { wordListOptions } from '../../../data/ApiOption'
import useLanguage from '../../../hooks/useLanguage'

import Dropdown from './Dropdown'
// import NumberInputHorizontal from '../../../components/utility/NumberInputHorizontal'
// import CheckboxInput from '../../../components/utility/CheckboxInput'

type SearchBarProps = {
    parentSearchParams: Partial<WordListSearchQueryNew>
    handleSearchParamsChange: (params: Partial<WordListSearchQueryNew>) => void
    handleSearchOnSubmit: () => void
    handleSearchAllOnChange: () => void
    isSearching: boolean
}

const SearchBar = ({
    parentSearchParams,
    handleSearchParamsChange,
    handleSearchOnSubmit,
    handleSearchAllOnChange,
    isSearching,
}: SearchBarProps) => {
    // console.log(`Rendering...${SearchBar.name}`)

    const [searchParamsWordlist, setSearchParamsWordlist] = useState<WordListSearchQueryNew>({
        ...defaultWordListRequest,
    })
    const language = useLanguage()
    const options =
        language === 'english'
            ? wordListOptions.sortByWordlist.en
            : wordListOptions.sortByWordlist.cn

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            let { name, value } = event.target
            // console.log(name, value, event.target)
            handleSearchParamsChange({ ...searchParamsWordlist, [name]: value })
        },
        [searchParamsWordlist]
    )

    const handleDropdownOnChange = useCallback(
        (name, value) => {
            // console.log(name, value)
            // setSearchParamsWordlist((params) => ({ ...params, [name]: value }))
            handleSearchParamsChange({ ...searchParamsWordlist, [name]: value })
        },
        // [searchParamsWordlist.sortby]
        [handleSearchParamsChange, searchParamsWordlist]
    )

    // const handleCheckboxOnChange = useCallback(
    //     (name: string, isChecked: boolean) => {
    //         // console.log(name, isChecked)
    //         // setSearchParamsWordlist((params) => ({ ...params, [name]: isChecked }))
    //         handleSearchParamsChange({ ...searchParamsWordlist, [name]: isChecked })
    //     },
    //     [handleSearchParamsChange, searchParamsWordlist]
    // )

    const handleSearchBtnOnClick = () => {
        handleSearchParamsChange({
            ...searchParamsWordlist,
            searchText: searchParamsWordlist.searchText.trim(),
        })
    }

    useEffect(() => {
        setSearchParamsWordlist((prevState) => ({ ...prevState, ...parentSearchParams }))
    }, [parentSearchParams])

    return (
        <React.Fragment>
            <div className="searchBarMobile">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        return handleSearchOnSubmit()
                    }}
                >
                    <div className="searchBarMobile__searchGroup__wordlist">
                        <input
                            className="searchBarMobile__searchGroup__wordlist__input"
                            value={searchParamsWordlist.searchText}
                            type="text"
                            name="searchText"
                            onChange={handleOnChange}
                            required
                        />
                        <div className="mt-10px">
                            <button
                                type="submit"
                                className={`searchBarMobile__searchGroup__wordlist__btn${
                                    isSearching ? '--disable' : ''
                                }`}
                                disabled={isSearching}
                            >
                                Search
                            </button>
                        </div>
                        <div className="mt-10px">
                            <button
                                type="button"
                                className={`searchBarMobile__searchGroup__wordlist__btn${
                                    isSearching ? '--disable' : ''
                                }`}
                                disabled={isSearching}
                                onClick={() => handleSearchAllOnChange()}
                            >
                                Search All
                            </button>
                        </div>

                        <div className="mt-10px">
                            <Dropdown
                                // width="240px"
                                name="sortby"
                                label=""
                                // theOptions={searchParamsRoot.sortby!}
                                theOptions={searchParamsWordlist.sortby || ''}
                                options={options}
                                onChange={handleDropdownOnChange}
                            ></Dropdown>
                        </div>
                    </div>
                </form>
            </div>

            <div className="searchBar">
                <form
                    className="h-full"
                    onSubmit={(e) => {
                        e.preventDefault()
                        return handleSearchOnSubmit()
                    }}
                >
                    <div className="row1">
                        {/* <CheckboxInput
                            label="Enable Metadata Search"
                            name="metadatasearch"
                            onChange={handleCheckboxOnChange}
                        ></CheckboxInput> */}
                        <div></div>

                        <div className="flex justify-center items-center">
                            <input
                                className="serach_input"
                                value={searchParamsWordlist.searchText}
                                type="text"
                                name="searchText"
                                onChange={handleOnChange}
                                required={true}
                            />
                            <button
                                type="submit"
                                className={`search_bar_btn${isSearching ? '--disable' : ''}`}
                                disabled={isSearching}
                                onClick={handleSearchBtnOnClick}
                            >
                                Search
                            </button>
                            <button
                                type="button"
                                className={`search_bar_btn${isSearching ? '--disable' : ''}`}
                                disabled={isSearching}
                                onClick={() => handleSearchAllOnChange()}
                            >
                                Search All
                            </button>
                        </div>
                    </div>

                    <div className="row2">
                        <div></div>
                        <Dropdown
                            // width="240px"
                            name="sortby"
                            label="Sort By"
                            // theOptions={searchParamsRoot.sortby!}
                            theOptions={searchParamsWordlist.sortby || ''}
                            options={options}
                            onChange={handleDropdownOnChange}
                        ></Dropdown>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default SearchBar
