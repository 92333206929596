import { User } from "../stores/data-models"
import Api      from "../utils/Api"

class ProfileService {

    static async getProfile() {
        const {body} = await Api.get<User>("/profile/me")

        return body.data
    }

}

export default ProfileService
