import { User } from '../../data-models'
import {
    createActionWithoutPayload,
    createActionWithPayload,
    IActionWithoutPayload,
    IActionWithPayload,
} from '../../types'
import * as types from './types'

type SetUserAction = IActionWithPayload<typeof types.SET_USER, User>
type ClearUserAction = IActionWithoutPayload<typeof types.CLEAR_USER>
type SetTokenAction = IActionWithPayload<typeof types.SET_TOKEN, string>
type SetIsRegisteringAction = IActionWithPayload<typeof types.SET_IS_REGISTERING, boolean>

export type AuthActions = SetUserAction | ClearUserAction | SetTokenAction | SetIsRegisteringAction

export const setUser = createActionWithPayload<SetUserAction>(types.SET_USER)
export const clearUser = createActionWithoutPayload<ClearUserAction>(types.CLEAR_USER)
export const setToken = createActionWithPayload<SetTokenAction>(types.SET_TOKEN)
export const setIsRegistering = createActionWithPayload<SetIsRegisteringAction>(
    types.SET_IS_REGISTERING
)
