import {
    ConcordanceSearchQueryNew,
    ConcordanceSearchResponseNew
} from '../../data-models/Assignment'
export type Option = {
    value: string
    label: string
}

export type OptionObj = {
    name: string
    formName: string
    option: Option[]
}

export interface ConcordanceState {
    isKwicSort: boolean
    results: ConcordanceSearchResponseNew
    searchParams: ConcordanceSearchQueryNew
}

export const SET_GLOBAL_SEARCH_PARAMS = 'concordance/SET_SEARCH_PARAMS'
export const SET_RESULTS = 'concordance/SET_RESULTS'
export const SET_IS_KWIC_SORT = 'concordance/SET_IS_KWIC_SORT'
