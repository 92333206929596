import {
    UserLoginRequest,
    UserRegistrationRequest,
    UserWithToken,
    RegisterNewUserRequest,
    UserWithStatus,
} from '../stores/data-models'
import Api from '../utils/Api'

class AuthService {
    static async register(userData: UserRegistrationRequest): Promise<UserWithToken> {
        const { body } = await Api.post<UserWithToken>('/auth/register', userData)

        return body.data
    }

    static async registernewuser(userData: RegisterNewUserRequest): Promise<any> {
        const { body } = await Api.post<UserWithStatus>('/auth/registernewuser', userData)
        return body
    }

    static async login(credential: UserLoginRequest): Promise<UserWithToken> {
        const { body } = await Api.post<UserWithToken>('/auth/login', credential)

        return body.data
    }
}

export default AuthService
