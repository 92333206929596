import { ConcordanceSearchQueryNew, ConcordanceSearchResponseNew } from '../stores/data-models'
import Api from '../utils/Api'

class AssignmentService {
    public static async searchConcordanceEn(searchParams: Partial<ConcordanceSearchQueryNew>) {
        const { body } = await Api.post<ConcordanceSearchResponseNew[]>(
            '/concordance/ennew',
            searchParams
        )

        return body.data
    }

    public static async searchConcordanceCn(searchParams: Partial<ConcordanceSearchQueryNew>) {
        const { body } = await Api.post<ConcordanceSearchResponseNew[]>(
            '/concordance/cnnew',
            searchParams
        )

        return body.data
    }
}

export default AssignmentService
