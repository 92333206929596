import React, { FunctionComponent, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { HeaderLogo } from '../../../components/layouts'
import { HamburgerIcon } from '../../../images'
import Nav from './Nav'

interface IHeader {
    textClass?: string
}

const Header: FunctionComponent<IHeader> = ({ textClass }) => {
    const [isHeaderSidebarOpen, setIsHeaderSidebarOpen] = useState(false)

    return (
        <header className="absolute top-0 left-0 right-0 z-20 py-5 sm:py-10">
            <div className="px-10px sm:px-5 md:px-10 lg:px-20 xl:px-30">
                <div className="flex justify-between items-center">
                    <HeaderLogo textClass={textClass} />

                    <button
                        className="text-secondary lg:hidden h-6 w-6"
                        onClick={() => setIsHeaderSidebarOpen(true)}
                    >
                        <HamburgerIcon />
                    </button>

                    <nav className="hidden lg:flex items-center">
                        <div className="container">
                            <Nav
                                setIsHeaderSidebarOpen={setIsHeaderSidebarOpen}
                                className={`px-3 xl:px-6 py-3 text-base sm:text-base md:text-lg lg:text-xl xl:text-2xl hover:text-primary-300 duration-300 hover:cursor-pointer ${
                                    textClass ? textClass : ''
                                }`}
                            />
                        </div>
                    </nav>

                    <nav
                        className={`flex lg:hidden absolute top-0 w-1/2 h-screen bg-gray-100 ${
                            isHeaderSidebarOpen ? 'right-0 visible' : 'right-full invisible'
                        }`}
                    >
                        <div className="flex flex-col w-full h-full p-20px">
                            <div className="flex justify-end">
                                <FontAwesomeIcon
                                    className="cursor-pointer hover:text-cell-green"
                                    size="2x"
                                    icon={faTimes}
                                    onClick={() => setIsHeaderSidebarOpen(false)}
                                />
                            </div>
                            <Nav
                                setIsHeaderSidebarOpen={setIsHeaderSidebarOpen}
                                className="text-black font-bold text-lg px-20px py-20px hover:text-cell-green"
                            ></Nav>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header
