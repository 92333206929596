import { get } from 'lodash'
import React, { useState, useCallback, ChangeEvent, FormEvent } from 'react'
import { useDispatch } from 'react-redux'

import { UserRegistrationRequest } from '../../stores/data-models'
import { register } from '../../stores/epics/authEpics'
type ValidationErrors = Partial<UserRegistrationRequest>

const RegisterNewUser = () => {
    const dispatch = useDispatch()
    const [form, setForm] = useState({
        full_name: '',
        email: '',
        username: '',
        password: '',
        'confirm-password': '',
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSuccesful, setIsSuccesful] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget
        // console.log(name, value)

        setValidationErrors((errors) => ({ ...errors, [name]: null }))

        setForm((prevState) => ({ ...prevState, [name]: value }))
    }, [])

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            // console.log('handleSubmit')

            setIsSubmitting(true)
            setIsSuccesful(false)
            setValidationErrors({})

            dispatch(
                register(
                    form,
                    () => {
                        setIsSubmitting(false)
                        setIsSuccesful(true)
                    },
                    (errors) => {
                      // console.log(`errors ${errors}`)
                        setIsSubmitting(false)
                        setValidationErrors(errors as ValidationErrors)
                    }
                )
            )
        },
        [dispatch, form]
    )

    return (
        <div>
            {isSuccesful ? (
                <div
                    className="bg-green-200 border border-teal-400 text-teal-700 p-2 rounded mb-4 flex justify-center items-center"
                    role="alert"
                >
                    <p className="font-bold text-base">Registration has been successful!</p>
                </div>
            ) : null}

            {isSuccesful ? null : (
                <form
                    className="w-full flex flex-col justify-around"
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <div className="mb-4">
                        <label htmlFor="full_name" className="block mb-2 text-sm">
                            Full Name
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                get(validationErrors, 'full_name') && 'is-invalid'
                            }`}
                            name="full_name"
                            id="full_name"
                            value={form.full_name}
                            onChange={handleOnChange}
                            autoComplete="full_name"
                            placeholder="Full Name"
                        />
                        <div>
                            <div className="text-sm text-red-600">
                                {get(validationErrors, 'full_name')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-2 text-sm required">
                            Email
                        </label>
                        <input
                            type="email"
                            className={`form-control ${
                                get(validationErrors, 'email') && 'is-invalid'
                            }`}
                            name="email"
                            id="email"
                            value={form.email}
                            onChange={handleOnChange}
                            autoComplete="email"
                            placeholder="Email"
                        />
                        <div>
                            <div className="text-sm text-red-600">
                                {get(validationErrors, 'email')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="username" className="block mb-2 text-sm required">
                            Username
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                get(validationErrors, 'username') && 'is-invalid'
                            }`}
                            name="username"
                            id="username"
                            value={form.username}
                            onChange={handleOnChange}
                            autoComplete="username"
                            placeholder="Username"
                        />
                        <div>
                            <div className="text-sm text-red-600">
                                {get(validationErrors, 'username')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block mb-2 text-sm required">
                            Password
                        </label>
                        <input
                            type="password"
                            className={`form-control ${
                                get(validationErrors, 'password') && 'is-invalid'
                            }`}
                            name="password"
                            id="password"
                            onChange={handleOnChange}
                            autoComplete="password"
                            placeholder="Password"
                        />
                        <div>
                            <div className="text-sm text-red-600">
                                {get(validationErrors, 'password')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="confirm-password" className="block mb-2 text-sm required">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            className={`form-control ${
                                get(validationErrors, 'confirm-password') && 'is-invalid'
                            }`}
                            name="confirm-password"
                            id="confirm-password"
                            onChange={handleOnChange}
                            autoComplete="confirm-password"
                            placeholder="Confirm Password"
                        />
                        <div>
                            <div className="text-sm text-red-600">
                                {get(validationErrors, 'confirm-password')}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mt-8">
                        <button
                            type="submit"
                            className={`modal3--submit-btn${isSubmitting ? '--disable' : ''}`}
                            disabled={isSubmitting ? true : false}
                        >
                            Register
                        </button>
                    </div>
                </form>
            )}
        </div>
    )
}

export default RegisterNewUser
