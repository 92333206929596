import React, { useCallback } from 'react'

import { ConcordanceSearchQueryNew } from '../../../stores/data-models/Assignment'
import { getAllOptionsLength } from '../../../data/ApiOption'
import { checkBoxFieldName } from '../../../data/checkbox'
import useLanguage from '../../../hooks/useLanguage'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type SelectChipSetProps = {
    parentSearchParams: ConcordanceSearchQueryNew
    parentSetSearchParams: (newParams: ConcordanceSearchQueryNew) => void
}

const SelectChipSet = ({ parentSearchParams, parentSetSearchParams }: SelectChipSetProps) => {
    const language = useLanguage()
    const searchParams = parentSearchParams
    const display = [
        'Age',
        'Gender',
        'Place of Birth',
        'Language',
        'HKDSE Eng',
        'HKDSE Chi',
        'HKALE Eng',
        'HKALE Chi',
        'IELTS',
    ]
    const optionsLength = getAllOptionsLength(language)

    const handleSelectChipOnClick = useCallback(
        (name: checkBoxFieldName, value: string) => {
            let values = searchParams.metadata

            if (value === 'all') {
                values[name] = []
            } else {
                values[name] = values[name].filter((v) => v !== value)
            }

            parentSetSearchParams({
                ...searchParams,
                metadata: values,
            })
        },
        [searchParams, parentSetSearchParams]
    )

    const processedMetadata = Object.keys(searchParams.metadata).map((name, idx) => {
        let metadataArr = searchParams.metadata[name as checkBoxFieldName]
        let tempArr: { display: string; name: string; value: string }[] = []
        // console.log(name, metadataArr, metadataArr.length, optionsLength[name as checkBoxFieldName])

        if (metadataArr.length === optionsLength[name as checkBoxFieldName]) {
            // select all hide
            // return [{ display: display[idx] + ': all', name: name, value: 'all' }]
            return []
        } else {
            if (metadataArr.length > 0) {
                metadataArr.map((value) => {
                    tempArr.push({
                        display: display[idx] + ': ' + value,
                        name: name,
                        value: value,
                    })
                    return true
                })
                return tempArr
            } else {
                return []
            }
        }
    })
    // console.log(processedMetadata)
    const isFilterAvailable = processedMetadata.reduce((acc, cur) => acc || cur.length !== 0, false)

    return (
        <React.Fragment>
            {isFilterAvailable ? (
                <div className="flex w-full mt-10px">
                    <p className="text-lg font-bold">Filters: </p>
                    <div className="flex flex-wrap">
                        {processedMetadata.map((metadata) =>
                            Object.values(metadata).map((data, idx) => (
                                <div
                                    className="flex items-center ml-5px pt-px px-15px mb-px bg-gray-300 text-black rounded-lg cursor-pointer"
                                    key={idx}
                                    onClick={() =>
                                        handleSelectChipOnClick(
                                            data.name as checkBoxFieldName,
                                            data.value
                                        )
                                    }
                                >
                                    <span className="whitespace-no-wrap">{data.display}</span>

                                    <FontAwesomeIcon
                                        className="ml-10px text-black text-sm"
                                        icon={faTimes}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    )
}

export default SelectChipSet
