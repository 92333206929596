import React, { FunctionComponent, useMemo } from 'react'
import { range } from '../../utils/helpers'

const SearchLoader: FunctionComponent = () => {
    const iter = useMemo(() => range(0, 10), [])

    return (
        <table className="bg-white rounded shadow w-full h-full font-light text-secondary text-sm overflow-hidden">
            <tbody>
                {iter.map((i) => (
                    <tr key={i}>
                        <td colSpan={4} className="p-3">
                            <span className="loader-placeholder group-hover:scale-110 transition-transform transform-center block w-full h-full rounded-lg" />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SearchLoader
