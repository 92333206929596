// import { cloneDeep } from 'lodash'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import React, { useState, useEffect, useRef } from 'react'

import { ConcordanceData } from '../../../stores/data-models/Assignment'

import ResultWritingText from './ResultWritingText'
import Modal2 from '../../../components/utility/Modal2'

type ResultTableProps = {
    pageNumber: number
    pageSize: number
    sortBy: string[]
    kwicResults: ConcordanceData[]
    isKwicSort: boolean
    lang: string
}

const ResultTable = ({
    pageNumber,
    pageSize,
    sortBy,
    kwicResults,
    isKwicSort,
    lang,
}: ResultTableProps) => {
    // console.log(`Rendering...ResultTable`)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [enlargedText, setEnlargedText] = useState('')
    const [uid, setUid] = useState('')

    const resultTableRef = useRef<HTMLDivElement>(null)
    const [tableHeight, setTableHeight] = useState(0)
    const colorTarget = isKwicSort ? sortBy.map((sb) => sb.split('')) : []

    useEffect(() => {
        // console.log(resultTableRef.current)
        if (resultTableRef.current) {
            setTableHeight(resultTableRef.current.clientHeight)
        }
    }, [resultTableRef, sortBy])

    return (
        <React.Fragment>
            {isModalOpen ? (
                <Modal2 title="Expanded Line" onClose={setIsModalOpen}>
                    <p>{uid}</p>

                    <hr />
                    <br />

                    <p>{enlargedText}</p>
                </Modal2>
            ) : null}

            <div className="flex w-full h-full" ref={resultTableRef}>
                {tableHeight ? (
                    <SimpleBar style={{ maxHeight: tableHeight, width: '100%' }}>
                        <div className="result_table_wrapper">
                            <table className="result_table">
                                <tbody>
                                    {kwicResults.map((data, idx) => (
                                        <tr key={data.uid + data.searchText + idx}>
                                            <td className="px-10px"></td>

                                            <td className="id">
                                                {pageNumber > 0
                                                    ? pageNumber * pageSize + idx + 1
                                                    : idx + 1}
                                            </td>
                                            <td className="px-5px"></td>

                                            <td className="sidetext">
                                                <ResultWritingText
                                                    isLeftSide={true}
                                                    writingStr={data.leftText}
                                                    colorTarget={colorTarget}
                                                    data={data}
                                                    lang={lang}
                                                ></ResultWritingText>
                                            </td>
                                            <td className="centertext">
                                                <span
                                                    className="lv0 cursor-pointer"
                                                    onClick={() => {
                                                        setUid(data.uid)
                                                        setEnlargedText(data.enlargedLine)
                                                        setIsModalOpen(true)
                                                    }}
                                                >
                                                    {data.searchText}&nbsp;
                                                </span>
                                            </td>
                                            <td className="sidetext">
                                                <ResultWritingText
                                                    isLeftSide={false}
                                                    writingStr={data.rightText}
                                                    colorTarget={colorTarget}
                                                    data={data}
                                                    lang={lang}
                                                ></ResultWritingText>
                                            </td>

                                            <td className="px-10px"></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </SimpleBar>
                ) : null}
            </div>
        </React.Fragment>
    )
}

export default ResultTable
