import React, { memo } from 'react'
import { ConcordanceData } from '../../../stores/data-models/Assignment'
import { getWords, getSortable } from '../../../utils/helpers/sort.helpers'

type ResultWritingTextProps = {
    isLeftSide: boolean
    writingStr: string
    colorTarget: string[][]
    data: ConcordanceData
    lang: string
}

const ResultWritingText = memo(
    ({ isLeftSide, writingStr, colorTarget, data, lang }: ResultWritingTextProps) => {
        // console.log(
        //     `Rendering...ResultWritingText isLeftSide: ${isLeftSide} writingStr: ${writingStr}, colorTarget: ${colorTarget}`
        // )

        let directionChar = isLeftSide ? 'L' : 'R'
        let words = getWords(data, directionChar, lang)
        let targetWordIndex: number[] = []
        let displayWords = []

        if (colorTarget.length) {
            for (let i = 0; i < 3; i++) {
                let idx = 0
                for (let j = 0; j < +colorTarget[i][0]; j++) {
                    idx = j === 0 ? getSortable(words, idx) : getSortable(words, idx + 1)
                }
                targetWordIndex.push(idx)
            }

            displayWords = words.map((word, wordIdx) => {
                let level = -1

                targetWordIndex.forEach((targetWordIdx, lv) => {
                    if (colorTarget[lv][1] === directionChar && wordIdx === targetWordIdx) {
                        level = lv
                    }
                })

                if (level !== -1) {
                    return {
                        word: word,
                        class: `lv${level + 1}`,
                    }
                }

                return {
                    word: word,
                    class: ``,
                }
            })
        } else {
            displayWords = words.map((word, wordIdx) => ({
                word: word,
                class: ``,
            }))
        }

        displayWords = isLeftSide ? displayWords.reverse() : displayWords

        // console.log(words, targetWordIndex, displayWords)

        return (
            <div className={'flex ' + (isLeftSide ? 'justify-end' : 'justify-start')}>
                {displayWords.map((word, wordIdx) =>
                    word.word === ' ' ? (
                        <div className={word.class} key={`${word.word}${wordIdx}`}>
                            &nbsp;
                        </div>
                    ) : (
                        <div className={word.class} key={`${word.word}${wordIdx}`}>
                            {word.word}
                        </div>
                    )
                )}
            </div>
        )
    }
)

export default ResultWritingText
