import React, { ChangeEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

type RadioAccordionProps = {
    title: string
    name: string
    options: { label: string; value: string }[]
    current_option: string

    handleRadioOnChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

const RadioAccordion = ({
    title,
    name,
    options,
    current_option,
    //
    handleRadioOnChange,
}: RadioAccordionProps) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <div className="accordion">
            <div
                className={'accordion-btn' + (isActive ? ' active' : '')}
                onClick={() => setIsActive(!isActive)}
            >
                <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <span className="ml-5 font-bold">{title}</span>
            </div>

            <div className={'accordion-meun' + (isActive ? ' active' : '')}>
                {options.map((opt, idx) => (
                    <div className="accordion-item" key={idx}>
                        <input
                            type="radio"
                            name={name}
                            value={opt.value}
                            defaultChecked={current_option === opt.value}
                            onChange={handleRadioOnChange}
                        />
                        <label>{opt.label}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RadioAccordion
