import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordanceSearchQueryNew } from '../../../stores/data-models/Assignment'
import { getSortByOptions } from '../../../data/ApiOption'
import useLanguage from '../../../hooks/useLanguage'

import NumberInputHorizontal from '../../../components/utility/NumberInputHorizontal'
import CheckboxInputWithDefVal from '../../../components/utility/CheckboxInputWithDefVal'
// import InputHorizontal from '../../../components/utility/InputHorizontal'
import KwicControl from './KwicControl'
// import CheckboxInput from '../../../components/utility/CheckboxInput'

type TabsProps = {
    parentSearchParams: ConcordanceSearchQueryNew
    parentSetSearchParams: (newParams: ConcordanceSearchQueryNew) => void
    handleKWICCheckboxOnChange: (name: string, isChecked: boolean) => void
    handleQuickKWIC: (quick: string) => void
    isKwicSort: boolean
    //
    isSorting: boolean
    isSortingOn: boolean
}

const Tabs = ({
    parentSearchParams,
    parentSetSearchParams,
    isKwicSort,
    handleKWICCheckboxOnChange,
    handleQuickKWIC,
    //
    isSorting,
    isSortingOn,
}: TabsProps) => {
    const language = useLanguage()
    const searchParams = parentSearchParams
    const options = useSelector(getSortByOptions)
    const [isOpen, setIsOpen] = useState({ size: false, kwic: false })

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            let { name, value } = event.target
            // console.log(name, value, event.target)
            const str2int = ['windowsize', 'pagesize']
            parentSetSearchParams({
                ...searchParams,
                [name]: str2int.includes(name) ? +value : value,
            })
        },
        [parentSetSearchParams, searchParams]
    )

    const handleSortByOnClick = useCallback(
        (sortByIdx: number, sortByValue: string) => {
            let newSortby = [...searchParams.sortBy]
            newSortby[sortByIdx] = sortByValue
            // console.log(sortByIdx, sortByValue)
            // console.log(newSortby)

            parentSetSearchParams({
                ...searchParams,
                sortBy: newSortby,
            })
        },
        [parentSetSearchParams, searchParams]
    )

    const handleCheckboxOnChange = useCallback(
        (name: string, isChecked: boolean) => {
            // console.log(name, isChecked)
            parentSetSearchParams({
                ...searchParams,
                [name]: isChecked,
            })
        },
        [parentSetSearchParams, searchParams]
    )

    return (
        <div className="w-full">
            <div className="w-full flex">
                <div className="w-1/2">
                    <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-1 sm:px-4 sm:py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-300"
                        onClick={() =>
                            setIsOpen((prevState) => ({
                                ...prevState,
                                size: !isOpen.size,
                                kwic: false,
                            }))
                        }
                    >
                        Search Options
                        <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                <div className="w-1/2">
                    <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-1 sm:px-4 sm:py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-300"
                        onClick={() =>
                            setIsOpen((prevState) => ({
                                ...prevState,
                                size: false,
                                kwic: !isOpen.kwic,
                            }))
                        }
                    >
                        Kwic Sort
                        <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <div
                className={`w-full rounded-10px border-cell-border shadow-cell-sm ${
                    isOpen.size ? '' : 'hidden'
                }`}
            >
                <div className="rounded-md bg-white shadow-xs">
                    <div className="p-10px">
                        <div className="mt-5px">
                            <NumberInputHorizontal
                                label="Page Size"
                                value={searchParams.pagesize}
                                name="pagesize"
                                placehoderStr=""
                                onChange={handleOnChange}
                                max={1000}
                                min={0}
                            ></NumberInputHorizontal>
                        </div>

                        <div className="flex mt-5px">
                            <div className="ml-5px">
                                <CheckboxInputWithDefVal
                                    label="Pattern"
                                    name="tagsearch"
                                    isChecked={searchParams.tagsearch}
                                    onChange={handleCheckboxOnChange}
                                ></CheckboxInputWithDefVal>
                            </div>

                            <div className="ml-5px">
                                <CheckboxInputWithDefVal
                                    label="KWIC Sort"
                                    name="kwicSort"
                                    isChecked={isKwicSort}
                                    onChange={handleKWICCheckboxOnChange}
                                ></CheckboxInputWithDefVal>
                            </div>

                            <div className="ml-5px">
                                <CheckboxInputWithDefVal
                                    label="Random"
                                    name="randomSample"
                                    isChecked={searchParams.randomSample}
                                    onChange={handleCheckboxOnChange}
                                ></CheckboxInputWithDefVal>
                            </div>

                            {language === 'english' ? (
                                <div className="ml-5px">
                                    <CheckboxInputWithDefVal
                                        label="Case"
                                        name="case"
                                        isChecked={searchParams.case}
                                        onChange={handleCheckboxOnChange}
                                    ></CheckboxInputWithDefVal>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            {language === 'english' ? (
                <div
                    className={`w-full rounded-10px border-cell-border shadow-cell-sm ${
                        isOpen.kwic ? '' : 'hidden'
                    }`}
                >
                    <div className="rounded-md bg-white shadow-xs">
                        <div className="p-10px">
                            <div>
                                <KwicControl
                                    options={options}
                                    curSortBy={searchParams.sortBy}
                                    onClick={handleSortByOnClick}
                                    isSorting={isSorting}
                                    isSortingOn={isSortingOn}
                                ></KwicControl>
                            </div>

                            <div className="flex justify-between mt-10px">
                                <button
                                    type="button"
                                    className={`sort_btn${isSortingOn ? '' : '--disable'}`}
                                    disabled={isSortingOn ? false : true}
                                    onClick={() => handleQuickKWIC('L')}
                                >
                                    Sort Left
                                </button>
                                <button
                                    type="button"
                                    className={`ml-5px sort_btn${isSortingOn ? '' : '--disable'}`}
                                    disabled={isSortingOn ? false : true}
                                    onClick={() => handleQuickKWIC('R')}
                                >
                                    Sort Right
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Tabs
