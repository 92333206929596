import { WordListData } from '../../data-models/WordList'

export interface WordListState {
    searchText: string
    results: WordListData
    isInitSearchCompleted: boolean
    wordlistPageNumber: number
}

export const SET_SEARCH_TEXT = 'wordlist/SET_SEARCH_TEXT'
export const SET_RESULTS = 'wordlist/SET_RESULTS'
export const SET_IS_INIT_SEARCH_COMPLETED = 'wordlist/SET_IS_INIT_SEARCH_COMPLETED'
export const SET_WORDLIST_PAGE_NUMBER = 'wordlist/SET_WORDLIST_PAGE_NUMBER'
