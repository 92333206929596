import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import iconUrl from '../../images/cell_corpus_cube2.svg'

interface IHeaderLogo {
    textClass?: string
}

const HeaderLogo: FunctionComponent<IHeaderLogo> = ({ textClass }) => (
    <div className="flex-1">
        <div className="inline-flex items-center text-3xl">
            <Link to="/">
                <img className="w-10 sm:w-12 md:w-14 lg:w-16" src={iconUrl} alt="" />
            </Link>

            <h1
                className={`${
                    textClass ? textClass : 'text-black'
                } font-bold ml-2 text-sm sm:text-base md:text-lg lg:text-2xl xl:text-3xl`}
            >
                CELL Corpus
            </h1>
        </div>
    </div>
)

export default HeaderLogo
