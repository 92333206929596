import React, { useState } from 'react'
// import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { getAllOptionsLength } from '../../data/ApiOption'
import { checkBoxFieldName } from '../../data/checkbox'
import useLanguage from '../../hooks/useLanguage'

import MultiCheckboxInput from './MultiCheckboxInput'

type MultiRowCheckboxProps = {
    title: string
    options: { name: string; formName: string; option: { label: string; value: string }[] }[]
    checkedList: {
        age: string[]
        gender: string[]
        placeofbirth: string[]
        firstlanguage: string[]
        hkdseenglish: string[]
        hkdsechinese: string[]
        hkaleenglish: string[]
        hkalechinese: string[]
        ielts: string[]
    }
    selectAllOnChange: (name: checkBoxFieldName, localSelectAllChecked: boolean) => void
    isChecked: (name: checkBoxFieldName, value: string) => boolean
    handleCheckboxOnChange: (name: checkBoxFieldName, value: string, isChecked: boolean) => void
}

const MultiRowCheckboxAccordion = ({
    title,
    options,
    checkedList,
    selectAllOnChange,
    isChecked,
    handleCheckboxOnChange,
}: MultiRowCheckboxProps) => {
    const language = useLanguage()
    const optionsLength = getAllOptionsLength(language)
    const [isActive, setIsActive] = useState(false)

    return (
        <div className="accordion">
            <div
                className={'accordion-btn' + (isActive ? ' active' : '')}
                onClick={() => setIsActive(!isActive)}
            >
                {/* <FontAwesomeIcon icon={faChevronRight} />
                    <span className="ml-5 font-bold">Public Examination Results</span> */}
                <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="ml-5 font-bold">{title}</div>
            </div>

            <div className={'accordion-meun' + (isActive ? ' active' : '')}>
                {options.map((exam) => (
                    <div key={exam.name}>
                        <p className="mt-2 font-bold">{exam.name}</p>
                        <div className="accordion-group mt-2">
                            <div className="accordion-item">
                                <input
                                    className="cell_checkbox"
                                    type="checkbox"
                                    name={exam.formName}
                                    checked={
                                        checkedList[exam.formName as checkBoxFieldName].length ===
                                        optionsLength[exam.formName as checkBoxFieldName]
                                    }
                                    onChange={() =>
                                        selectAllOnChange(
                                            exam.formName as checkBoxFieldName,
                                            checkedList[exam.formName as checkBoxFieldName]
                                                .length ===
                                                optionsLength[exam.formName as checkBoxFieldName]
                                        )
                                    }
                                />
                                <label>Select All</label>
                            </div>
                            {exam.option.map((opt) => (
                                <div className="accordion-item" key={exam.formName + opt.value}>
                                    <MultiCheckboxInput
                                        name={exam.formName}
                                        label={opt.label}
                                        value={opt.value}
                                        checked={isChecked(
                                            exam.formName as checkBoxFieldName,
                                            opt.value
                                        )}
                                        onChange={() =>
                                            handleCheckboxOnChange(
                                                exam.formName as checkBoxFieldName,
                                                opt.value,
                                                !isChecked(
                                                    exam.formName as checkBoxFieldName,
                                                    opt.value
                                                )
                                            )
                                        }
                                    ></MultiCheckboxInput>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MultiRowCheckboxAccordion
