export const defaultConcordanceRequestEn = {
    sortBy: ['1R', '2R', '3R'],
    // windowsize: 100,
    pagesize: 100,
    pagenumber: 0,
    searchText: '',
    randomSample: false,
    case: false,
    tagsearch: false,
    segmentsearch: false,
    category: '',
    section: 'all',
    metadata: {
        age: [
            // '0',
            // '16',
            // '17',
            // '18',
            // '19',
            // '20',
            // '21',
            // '22',
            // '23',
            // '24',
            // '25',
            // '26',
            // '27',
            // '28',
            // '31',
            // '32',
            // '33',
            // '36',
            // '39',
            // '41',
            // '43',
            // '44',
            // '52',
        ],
        gender: ['Male', 'Female'],
        placeofbirth: [
            'Hong Kong',
            'Canada',
            'Macau',
            'Mainland China',
            'Malaysia',
            'Nepal',
            'Nigeria',
            'Pakistan',
            'Taiwan',
            'The Philippines',
            'USA',
        ],
        firstlanguage: [
            'Cantonese',
            'Filipino',
            'Hindko',
            'Hoi Ping Cantonese',
            'Minan Dialect',
            'Nepali',
            'Pashto',
            'Punjabi',
            'Putonghua',
            'Shanghai Dialect',
            'Sichuan Dialect',
            'Urdu',
        ],
        hkdseenglish: ['1', '2', '3', '4', '5', '6', '7'],
        hkdsechinese: ['1', '2', '3', '4', '5', '6', '7'],
        hkaleenglish: ['A', 'B', 'C', 'D', 'E', 'F'],
        hkalechinese: ['A', 'B', 'C', 'D', 'E', 'F'],
        ielts: ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9'],
    },
}

export const defaultConcordanceRequestCn = {
    sortBy: ['1R', '2R', '3R'],
    // windowsize: 100,
    pagesize: 100,
    pagenumber: 0,
    searchText: '',
    randomSample: false,
    case: false,
    tagsearch: false,
    segmentsearch: false,
    category: '',
    section: 'all',
    metadata: {
        age: [
            // '0',
            // '16',
            // '17',
            // '18',
            // '19',
            // '20',
            // '21',
            // '22',
            // '23',
            // '24',
            // '25',
            // '26',
            // '27',
            // '28',
            // '31',
            // '32',
            // '33',
            // '36',
            // '39',
            // '41',
            // '43',
            // '44',
            // '52',
        ],
        gender: ['Male', 'Female'],
        placeofbirth: [
            'Hong Kong',
            'Canada',
            'Macau',
            'Mainland China',
            'Malaysia',
            'Nepal',
            'Nigeria',
            'Pakistan',
            'Taiwan',
            'The Philippines',
            'USA',
        ],
        firstlanguage: [
            'Cantonese',
            'English',
            'Filipino',
            'Hindko',
            'Hoi Ping Cantonese',
            'Minan Dialect',
            'Nepali',
            'Pashto',
            'Punjabi',
            'Putonghua',
            'Shanghai Dialect',
            'Sichuan Dialect',
            'Urdu',
        ],
        hkdseenglish: ['1', '2', '3', '4', '5', '6', '7'],
        hkdsechinese: ['1', '2', '3', '4', '5', '6', '7'],
        hkaleenglish: ['A', 'B', 'C', 'D', 'E', 'F'],
        hkalechinese: ['A', 'B', 'C', 'D', 'E', 'F'],
        ielts: ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9'],
    },
}

export const defaultWordListRequest = {
    sortby: 'descFrequency',
    searchText: '',
    showentirewordlist: false,
    metadatasearch: false,
    section: 'all',
    category: '',
    metadata: {
        age: [],
        firstlanguage: [
            'Cantonese',
            'English',
            'Filipino',
            'Hindko',
            'Hoi Ping Cantonese',
            'Minan Dialect',
            'Nepali',
            'Pashto',
            'Punjabi',
            'Putonghua',
            'Shanghai Dialect',
            'Sichuan Dialect',
            'Urdu',
        ],
        gender: ['Male', 'Female'],
        hkalechinese: ['A', 'B', 'C', 'D', 'E', 'F'],
        hkaleenglish: ['A', 'B', 'C', 'D', 'E', 'F'],
        hkdsechinese: ['1', '2', '3', '4', '5', '6', '7'],
        hkdseenglish: ['1', '2', '3', '4', '5', '6', '7'],
        ielts: ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9'],
        placeofbirth: [
            'Hong Kong',
            'Canada',
            'Macau',
            'Mainland China',
            'Malaysia',
            'Nepal',
            'Nigeria',
            'Pakistan',
            'Taiwan',
            'The Philippines',
            'USA',
        ],
    },
}
