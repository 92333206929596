import React, { useEffect, useState } from 'react'
// import { faArrowCircleUp, faArrowAltCircleUp, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BackToTop = () => {
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }

        setTimeout(() => {
            setShowScroll(false)
        }, 2500)
    }

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop)

        return () => window.removeEventListener('scroll', checkScrollTop)
    })

    return (
        <a
            href="#root"
            className={`fixed ${
                showScroll ? 'opacity-100' : 'opacity-0 pointer-events-none'
            } duration-300 bottom-20px right-20px`}
        >
            <img
                className="w-12 cursor-pointer"
                src={require('../../../images/front_page/icon/top.svg')}
                alt=""
            />
        </a>
    )
}

export default BackToTop
