import React, { FunctionComponent, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { checkIfAuthenticated, getUser } from '../../stores/reducers/auth'
import { getFontSize } from '../../stores/reducers/system'

import Modal2 from '../../components/utility/Modal2'
import Modals from '../../components/utility/Modals'
import RegisterNewUser from '../../components/form/RegisterNewUser'
import AppFooter from './AppFooter'

interface IAppLayoutProps {
    children: ReactNode
    AppHeader: FunctionComponent
    isModalOpen: boolean
    setIsModalOpen: (flag: boolean) => void
    isRegNewUserOpen: boolean
    setIsRegNewUserOpen: (flag: boolean) => void
}

const AppLayout: FunctionComponent<IAppLayoutProps> = ({
    AppHeader,
    children,
    isModalOpen,
    setIsModalOpen,
    isRegNewUserOpen,
    setIsRegNewUserOpen,
}) => {
    // console.log(`Rendering...${AppLayout.name}`)

    const user = useSelector(getUser)
    const fontSize = useSelector(getFontSize)
    const isAuthenticated = useSelector(checkIfAuthenticated)

    return (
        <div className="h-full">
            <div className="flex flex-col flex-root h-full">
                <div className="flex flex-row flex-column-fluid">
                    <div className="flex flex-col flex-row-fluid appLayoutWrapper">
                        <AppHeader />

                        <div className="flex flex-col flex-column-fluid">
                            {/* entry */}
                            <div className="flex flex-column-fluid">
                                <div className="mainWrapper">
                                    {isAuthenticated && isModalOpen ? (
                                        <Modal2
                                            widthClass="w-full sm:w-1/5"
                                            title="PROFILE"
                                            onClose={setIsModalOpen}
                                        >
                                            <p>Name: {user.display_name}</p>
                                            <p>Email: {user.email}</p>
                                        </Modal2>
                                    ) : null}

                                    {isAuthenticated && isRegNewUserOpen ? (
                                        <Modals
                                            className="modal3"
                                            widthClass="w-full sm:w-1/3"
                                            title="Register New User"
                                            onClose={setIsRegNewUserOpen}
                                        >
                                            <RegisterNewUser></RegisterNewUser>
                                        </Modals>
                                    ) : null}

                                    <main
                                        className={`app app_font_size_${fontSize} flex-column-fluid`}
                                    >
                                        {children}
                                    </main>
                                </div>
                                {/* app container */}
                            </div>
                        </div>

                        <AppFooter></AppFooter>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppLayout
