import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { EnglishTaggers } from '../../data/taggers'
import useWindowDimensions from '../../hooks/useWindowDimensions'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const GuideTable = ({ taggerPdfFile }: { taggerPdfFile: string }) => {
    const [numPages, setNumPages] = useState<number | null>(null)
    const [pageNumber, setPageNumber] = useState(1)
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        setPageNumber(1)
    }, [taggerPdfFile])

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages)
    }

    const englishTaggerTable = (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <h3 className="heading text-page-heading font-bold text-2xl">
                        List of English taggers
                    </h3>
                    <h3 className="heading text-page-heading font-bold text-xl">(58 tags)</h3>

                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                                    >
                                        POS Tag
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                                    >
                                        Description
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                                    >
                                        Example
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {EnglishTaggers.map((taggers, taggersIdx) => (
                                    <tr key={`etaggers${taggersIdx}`}>
                                        {taggers.map((tagger) => (
                                            <td
                                                key={`etagger${taggersIdx}`}
                                                className="px-6 py-4 whitespace-nowrap"
                                            >
                                                <div className="text-sm font-medium text-gray-900">
                                                    {tagger}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                {/* <tr>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                            Flora Wu
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-700">
                                            Software engineer, IT
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-700">
                                            flora.wu@example.com
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <div className="overflow-scroll">
                <Document file={taggerPdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} width={200 + width * 0.4} />
                </Document>
            </div>
            <div className="flex items-center justify-center mt-4">
                <button
                    className="bg-blue-600 text-white hover:bg-blue-800 active:bg-blue-800 font-bold uppercase text-xs px-4 py-2 rounded-l outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setPageNumber(pageNumber - 1)}
                >
                    <FontAwesomeIcon
                        className="mx-5px"
                        style={{ color: '#ffffff' }}
                        icon={faAngleLeft}
                    />
                </button>
                <button
                    className="bg-blue-600 text-white font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                >
                    Page {pageNumber} of {numPages}
                </button>
                <button
                    className="bg-blue-600 text-white hover:bg-blue-800 active:bg-blue-800 font-bold uppercase text-xs px-4 py-2 rounded-r outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setPageNumber(pageNumber + 1)}
                >
                    <FontAwesomeIcon
                        className="mx-5px"
                        style={{ color: '#ffffff' }}
                        icon={faAngleRight}
                    />
                </button>
            </div>
        </div>
    )
}

export default GuideTable
