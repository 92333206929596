import { FunctionComponent } from 'react'
import { config } from '../config'
import { PageNotFound } from '../pages/errors'
import { Contact, Guide, Intro, Landing, Terms } from '../pages/index'
import adminRoutes from './adminRoutes'

interface Route {
    redirect?: boolean
    path?: string
    exact?: boolean
    component?: FunctionComponent
    auth?: boolean
    from?: string
    to?: any
}

export const routes: Route[] = [
    {
        path: '/',
        exact: true,
        component: Landing,
    },

    {
        path: '/introduction',
        exact: true,
        component: Intro,
    },

    {
        path: '/guide',
        exact: true,
        component: Guide,
    },

    {
        path: '/terms',
        exact: true,
        component: Terms,
    },

    // {
    //     path: '/registration',
    //     exact: true,
    //     component: Reg,
    // },

    {
        path: '/contact',
        exact: true,
        component: Contact,
    },

    // ...authRoutes,
    ...adminRoutes(`/${config.app.adminRoutePrefix}`),

    {
        path: '*',
        component: PageNotFound,
    },
]
