import { config } from "../../config"

export const adminRoute = (path: string = "") => `/${config.app.adminRoutePrefix}${path}`

export const range = (start: number, end: number, step: number = 1): number[] => {
    const a = [start]
    let b = start

    while (b < end) {
        a.push(b += step || 1)
    }

    return a
}
