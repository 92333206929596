export const EnglishTaggers = [
    ['CC     ', 'coordinating conjunction ', 'and, but, or, &'],
    ['CD     ', 'cardinal number          ', '1, three'],
    ['DT     ', 'determiner               ', 'the'],
    ['EX     ', 'existential there        ', 'there is'],
    ['FW     ', 'foreign word             ', "d'œuvre"],
    ['IN     ', 'preposition/subord. conj.', 'in,of,like,after,whether'],
    ['IN/that', 'complementizer           ', 'that'],
    ['JJ     ', 'adjective                ', 'green'],
    ['JJR    ', 'adjective, comparative   ', 'greener'],
    ['JJS    ', 'adjective, superlative   ', 'greenest'],
    ['LS     ', 'list marker              ', '(1),'],
    ['MD     ', 'modal                    ', 'could, will'],
    ['NN     ', 'noun, singular or mass   ', 'table'],
    ['NNS    ', 'noun plural              ', 'tables'],
    ['NP     ', 'proper noun, singular    ', 'John'],
    ['NPS    ', 'proper noun, plural      ', 'Vikings'],
    ['PDT    ', 'predeterminer            ', 'both the boys'],
    ['POS    ', 'possessive ending        ', "friend's"],
    ['PP     ', 'personal pronoun         ', 'I, he, it'],
    ['PP$    ', 'possessive pronoun       ', 'my, his'],
    ['RB     ', 'adverb                   ', 'however, usually, here, not'],
    ['RBR    ', 'adverb, comparative      ', 'better'],
    ['RBS    ', 'adverb, superlative      ', 'best'],
    ['RP     ', 'particle                 ', 'give up'],
    ['SENT   ', 'end punctuation          ', '?, !, .'],
    ['SYM    ', 'symbol                   ', '@, +, *, ^, |, ='],
    ['TO     ', 'to                       ', 'to go, to him'],
    ['UH     ', 'interjection             ', 'uhhuhhuhh'],
    ['VB     ', 'verb be, base form       ', 'be'],
    ['VBD    ', 'verb be, past            ', 'was|were'],
    ['VBG    ', 'verb be, gerund/participl', 'being'],
    ['VBN    ', 'verb be, past participle ', 'been'],
    ['VBZ', 'verb be, pres, 3rd p. sing  ', 'is'],
    ['VBP', 'verb be, pres non-3rd p.    ', 'am|are'],
    ['VD ', 'verb do, base form          ', 'do'],
    ['VDD', 'verb do, past               ', 'did'],
    ['VDG', 'verb do gerund/participle   ', 'doing'],
    ['VDN', 'verb do, past participle    ', 'done'],
    ['VDZ', 'verb do, pres, 3rd per.sing ', 'does'],
    ['VDP', 'verb do, pres, non-3rd per. ', 'do'],
    ['VH ', 'verb have, base form        ', 'have'],
    ['VHD', 'verb have, past             ', 'had'],
    ['VHG', 'verb have, gerund/participle', 'having'],
    ['VHN', 'verb have, past participle  ', 'had'],
    ['VHZ', 'verb have, pres 3rd per.sing', 'has'],
    ['VHP', 'verb have, pres non-3rd per.', 'have'],
    ['VV ', 'verb, base form             ', 'take'],
    ['VVD', 'verb, past tense            ', 'took'],
    ['VVG', 'verb, gerund/participle     ', 'taking'],
    ['VVN', 'verb, past participle       ', 'taken'],
    ['VVP', 'verb, present, non-3rd p.   ', 'take'],
    ['VVZ', 'verb, present 3d p. sing.   ', 'takes'],
    ['WDT', 'wh-determiner               ', 'which'],
    ['WP ', 'wh-pronoun                  ', 'who, what'],
    ['WP$', 'possessive wh-pronoun       ', 'whose'],
    ['WRB', 'wh-abverb                   ', 'where, when'],
    [':  ', 'general joiner              ', ';, -, --'],
    ['$  ', 'currency symbol             ', '$, £'],
]

export const chineseTaggers = [
    [""]
]
