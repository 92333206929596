export interface SystemState {
    is_loading: boolean
    corpus_lang: string
    font_size: string
    is_sidebar_active: boolean
}

export const SET_IS_LOADING = 'system/SET_IS_LOADING'
export const API_REQUEST = 'system/API_REQUEST'
export const SET_CORPUS_LANG = 'system/SET_CORPUS_LANG'
export const SET_FONT_SIZE = 'system/SET_FONT_SIZE'
export const SET_IS_SIDEBAR_ACTIVE = 'system/SET_IS_SIDEBAR_ACTIVE'
