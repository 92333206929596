import { cloneDeep, omit } from 'lodash'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import React, {
    FunctionComponent,
    ChangeEvent,
    useState,
    useCallback,
    useEffect,
    useRef,
} from 'react'
// import { useSelector } from 'react-redux'

import { WordListSearchQueryNew } from '../../../stores/data-models/WordList'
import { wordListOptions, getAllWordListOptionsLength } from '../../../data/ApiOption'
import { checkBoxFieldName } from '../../../data/checkbox'
import { defaultWordListRequest } from '../../../data/DefaultRequest'
import useLanguage from '../../../hooks/useLanguage'

import RadioAccordion from '../../../components/utility/RadioAccordion'
import SidebarAccordion from '../../../components/utility/CheckboxAccordion'
import MultiRowCheckbox from '../../../components/utility/MultiRowCheckboxAccordion'

type loopRefName = 'age' | 'gender' | 'placeofbirth' | 'firstlanguage' | 'exam'
interface SidebarsProps {
    handleSearchParamsChange: (params: Partial<WordListSearchQueryNew>) => void
}

const defaultWordListRequestCopy = cloneDeep(
    omit(defaultWordListRequest, ['sortby', 'searchText', 'showentirewordlist', 'metadatasearch'])
)

const Sidebar: FunctionComponent<SidebarsProps> = ({ handleSearchParamsChange }) => {
    // console.log(`Rendering...${Sidebar.name}`)

    const sidebarContentRef = useRef<HTMLDivElement>(null)
    const [searchParamsWordlist, setSearchParamsWordlist] = useState<
        Partial<WordListSearchQueryNew>
    >(defaultWordListRequestCopy)

    const language = useLanguage()
    const options = wordListOptions
    const category = language === 'english' ? options.category.en : options.category.cn
    const optionsLength = getAllWordListOptionsLength()

    const [metatdatSelectAll, setMetatdatSelectAll] = useState({
        age: searchParamsWordlist.metadata!['age'].length === optionsLength['age'],
        gender: searchParamsWordlist.metadata!['gender'].length === optionsLength['gender'],
        placeofbirth:
            searchParamsWordlist.metadata!['placeofbirth'].length === optionsLength['placeofbirth'],
        firstlanguage:
            searchParamsWordlist.metadata!['firstlanguage'].length ===
            optionsLength['firstlanguage'],
        hkdseenglish:
            searchParamsWordlist.metadata!['hkdseenglish'].length === optionsLength['hkdseenglish'],
        hkdsechinese:
            searchParamsWordlist.metadata!['hkdsechinese'].length === optionsLength['hkdsechinese'],
        hkaleenglish:
            searchParamsWordlist.metadata!['hkaleenglish'].length === optionsLength['hkaleenglish'],
        hkalechinese:
            searchParamsWordlist.metadata!['hkalechinese'].length === optionsLength['hkalechinese'],
        ielts: searchParamsWordlist.metadata!['ielts'].length === optionsLength['ielts'],
    })

    const isChecked = useCallback(
        (name: checkBoxFieldName, value: string): boolean => {
            return (searchParamsWordlist.metadata![name] || []).includes(value)
        },
        [searchParamsWordlist]
    )

    const handleCheckboxOnChange = useCallback(
        (name: checkBoxFieldName, value: string, isChecked: boolean) => {
            let values = searchParamsWordlist.metadata

            isChecked
                ? values![name].push(value)
                : (values![name] = values![name].filter((v) => v !== value))

            // console.log(name, value, isChecked)
            // console.log(values)
            setSearchParamsWordlist((params) => {
                return { ...params, metadata: values }
            })

            let newState = { ...metatdatSelectAll }
            for (const key in searchParamsWordlist.metadata) {
                if (Object.prototype.hasOwnProperty.call(searchParamsWordlist.metadata, key)) {
                    if (key === name) {
                        const el = searchParamsWordlist.metadata[key as checkBoxFieldName]
                        const optLen = optionsLength[name as checkBoxFieldName]
                        // console.log(name, el.length, optionLength)
                        if (el.length === optLen) {
                            newState[name as checkBoxFieldName] = true
                        } else {
                            newState[name as checkBoxFieldName] = false
                        }
                    }
                }
            }
            setMetatdatSelectAll(newState)
        },
        [searchParamsWordlist, metatdatSelectAll, optionsLength]
    )

    const handleRadioOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target
            // console.log(name, value)
            setSearchParamsWordlist((params) => {
                return { ...params, [name]: value }
            })
        },
        []
    )

    const handleSelectAllOnChange = (name: checkBoxFieldName, checked: boolean) => {
        let values = searchParamsWordlist.metadata
        // console.log(name, checked)

        if (!checked) {
            let optionsRefs: {
                // { label: string; value: string }[]
                age: { label: string; value: string }[]
                gender: { label: string; value: string }[]
                placeofbirth: { label: string; value: string }[]
                firstlanguage: { label: string; value: string }[]
                hkdseenglish: { label: string; value: string }[]
                hkdsechinese: { label: string; value: string }[]
                hkaleenglish: { label: string; value: string }[]
                hkalechinese: { label: string; value: string }[]
                ielts: { label: string; value: string }[]
            } = {
                age: options.age,
                gender: options.gender,
                placeofbirth: options.placeofbirth,
                firstlanguage: options.firstlanguage,
                hkdseenglish: options.exam[0].option,
                hkdsechinese: options.exam[1].option,
                hkaleenglish: options.exam[2].option,
                hkalechinese: options.exam[3].option,
                ielts: options.exam[4].option,
            }

            for (const optionRef in optionsRefs) {
                if (Object.prototype.hasOwnProperty.call(optionsRefs, optionRef)) {
                    const the_options = optionsRefs[optionRef as checkBoxFieldName]

                    if (name === 'age') {
                        continue
                    }

                    if (name === optionRef) {
                        the_options.map((opt) => {
                            if (!searchParamsWordlist.metadata![name].includes(opt.value)) {
                                values![name].push(opt.value)
                            }
                            return true
                        })
                    }
                }
            }

            setSearchParamsWordlist((params) => {
                return { ...params, metadata: values }
            })
        } else {
            values![name] = []
            setSearchParamsWordlist((params) => {
                return { ...params, metadata: values }
            })
        }

        setMetatdatSelectAll((prevState) => {
            return { ...prevState, [name]: !checked }
        })
    }

    useEffect(() => {
        handleSearchParamsChange(searchParamsWordlist)
    }, [handleSearchParamsChange, searchParamsWordlist])

    return (
        <div className="sidebar">
            <div className="flex w-full h-full" ref={sidebarContentRef}>
                {sidebarContentRef.current ? (
                    <SimpleBar
                        style={{
                            maxHeight: sidebarContentRef.current.clientHeight,
                            width: '100%',
                        }}
                    >
                        <div className="title">Categories of Courses</div>
                        <RadioAccordion
                            title="Categories of Courses"
                            name="category"
                            options={category}
                            current_option={searchParamsWordlist.category!}
                            handleRadioOnChange={handleRadioOnChange}
                        ></RadioAccordion>

                        <RadioAccordion
                            title="Sections"
                            name="section"
                            options={options.section}
                            current_option={searchParamsWordlist.section!}
                            handleRadioOnChange={handleRadioOnChange}
                        ></RadioAccordion>

                        <div className="title">Meta Data</div>

                        <SidebarAccordion
                            title="Gender"
                            name="gender"
                            options={options.gender}
                            checkedList={searchParamsWordlist.metadata!.gender}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></SidebarAccordion>

                        <SidebarAccordion
                            title="Place of Birth"
                            name="placeofbirth"
                            options={options.placeofbirth}
                            checkedList={searchParamsWordlist.metadata!.placeofbirth}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></SidebarAccordion>

                        <SidebarAccordion
                            title="First Language"
                            name="firstlanguage"
                            options={options.firstlanguage}
                            checkedList={searchParamsWordlist.metadata!.firstlanguage}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></SidebarAccordion>

                        <MultiRowCheckbox
                            title="Public Examination Results"
                            options={options.exam}
                            checkedList={searchParamsWordlist.metadata!}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></MultiRowCheckbox>
                    </SimpleBar>
                ) : null}
            </div>
        </div>
    )
}

export default Sidebar
