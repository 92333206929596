// import { Link } from 'react-router-dom'
import { CSSPlugin, gsap, TimelineLite } from 'gsap'
import React, { Fragment, FunctionComponent, useEffect } from 'react'
import Particles from 'react-particles-js'
// import EllipseSvg from './partials/EllipseSvg'
// import HitCount from './partials/HitCount'
// import serachSvg from '../../images/serach.svg'
// import ellipseSvg from '../../images/ellipse.svg'
import data_analyzing_svg from '../../images/front_page/data_analyzing_.svg'
import imac_svg from '../../images/front_page/imac.svg'
import search_engine_svg from '../../images/front_page/search_engine_.svg'
import search_result_svg from '../../images/front_page/search_result.svg'
import file_searching_svg from '../../images/front_page/undraw_File_searching_re_3evy.svg'
// import inspection_svg from '../../images/front_page/undraw_Inspection_re_tbt7.svg'
import undraw_Google_docs_re_evm3 from '../../images/front_page/undraw_Google_docs_re_evm3.svg'
// import { Type } from 'tsparticles'
import Footer from './partials/Footer'
import Header from './partials/Header'
// import LandingTyped from './partials/LandingTyped'
import LandingSwiper from './partials/LandingSwiper'

gsap.registerPlugin(CSSPlugin)

const Landing: FunctionComponent = () => {
    // const isAuthenticated = useSelector(checkIfAuthenticated)

    // if (isAuthenticated) {
    //     return <Redirect to={adminRoute()} />
    // }

    // const vlStyle = {
    //     height: bottomPosition * 0.33 + 30,
    // }

    useEffect(() => {
        const tl = new TimelineLite()
        const tl2 = new TimelineLite()

        tl.fromTo('.svg_set .main', 0.6, { opacity: 0, top: '55%' }, { opacity: 1, top: '50%' })
            .fromTo(
                '.svg_set .sub.tl',
                0.6,
                { opacity: 0, top: '40%', left: '40%' },
                { opacity: 1, top: '30%', left: '30%' }
            )
            .fromTo(
                '.svg_set .sub.tr',
                0.6,
                { opacity: 0, top: '40%', right: '20%' },
                { opacity: 1, top: '30%', right: '10%' }
            )
            .fromTo(
                '.svg_set .sub.br',
                0.6,
                { opacity: 0, bottom: '25%', right: '20%' },
                { opacity: 1, bottom: '15%', right: '10%' }
            )
            .fromTo(
                '.svg_set .sub.bl',
                0.6,
                { opacity: 0, bottom: '25%', left: '40%' },
                { opacity: 1, bottom: '15%', left: '30%' }
            )
            .fromTo(
                '.svg_set .result',
                0.6,
                { opacity: 0, top: '50%', left: '25%' },
                { opacity: 1, top: '50%', left: '65%' }
            )

        tl2.fromTo('#paragraph_i1', 0.1, { opacity: 0, y: 10 }, { opacity: 1, y: 0 }).fromTo(
            '#paragraph_i2',
            0.3,
            { opacity: 0, y: 10 },
            { opacity: 1, y: 0 }
        )
    })

    return (
        <Fragment>
            <Header />

            <main className="landing bg-no-repeat bg-fixed bg-contain sm:bg-cover">
                <div className="flex justify-center items-center w-full h-screen flex-col sm:flex-row">
                    <div className="flex justify-center items-center w-full h-full z-10 sm:w-1/2">
                        <div className="w-full h-full svg_set">
                            <div className="main">
                                <div className="point">
                                    <img src={imac_svg} alt="" />
                                </div>
                            </div>

                            <div className="result">
                                <div className="point">
                                    <img src={search_result_svg} alt="" />
                                </div>
                            </div>

                            <div className="sub tl">
                                <div className="point">
                                    <img src={file_searching_svg} alt="" />
                                </div>
                            </div>

                            <div className="sub tr">
                                <div className="point">
                                    <img src={search_engine_svg} alt="" />
                                </div>
                            </div>

                            <div className="sub bl">
                                <div className="point">
                                    <img src={data_analyzing_svg} alt="" />
                                </div>
                            </div>

                            <div className="sub br">
                                <div className="point">
                                    <img src={undraw_Google_docs_re_evm3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center items-start sm:items-center h-full w-full px-10px z-10 sm:w-1/2">
                        <div className="flex flex-col w-full">
                            <div className={'flex flex-col w-full'}>
                                <div>
                                    <p
                                        id="paragraph_i1"
                                        className={`text-lg sm:text-xl lg:2xl xl:text-4xl font-bold`}
                                    >
                                        The Chinese and English Learner Language (CELL) Corpus
                                    </p>
                                </div>

                                <div id="paragraph_i1">
                                    {/* <LandingTyped></LandingTyped> */}
                                    <LandingSwiper></LandingSwiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="absolute top-0 w-full h-screen overflow-hidden">
                        <Particles
                            params={{
                                particles: {
                                    number: {
                                        value: 50,
                                        density: {
                                            enable: !0,
                                            value_area: 800,
                                        },
                                    },
                                    line_linked: {
                                        shadow: {
                                            enable: true,
                                            color: '#fff',
                                            // blur: 5,
                                        },
                                    },
                                    color: {
                                        value: '#fff',
                                    },
                                    shape: {
                                        type: 'circle',
                                        stroke: {
                                            width: 0,
                                            color: '#ff0000',
                                        },
                                        polygon: {
                                            nb_sides: 5,
                                        },
                                        image: {
                                            src: '',
                                            width: 100,
                                            height: 100,
                                        },
                                    },
                                    opacity: {
                                        value: 1,
                                        random: !1,
                                        anim: {
                                            enable: !1,
                                            speed: 2,
                                            opacity_min: 0,
                                            sync: !1,
                                        },
                                    },
                                    size: {
                                        value: 2,
                                        random: !1,
                                        anim: {
                                            enable: !1,
                                            speed: 20,
                                            size_min: 0,
                                            sync: !1,
                                        },
                                    },
                                    move: {
                                        enable: !0,
                                        speed: 2,
                                        direction: 'none',
                                        random: !1,
                                        straight: !1,
                                        out_mode: 'out',
                                        bounce: !1,
                                        attract: {
                                            enable: !1,
                                            rotateX: 3e3,
                                            rotateY: 3e3,
                                        },
                                    },
                                },
                                interactivity: {
                                    events: {
                                        onhover: {
                                            enable: true,
                                            mode: 'repulse',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            </main>

            <Footer isAbsolute={true} />
        </Fragment>
    )
}

export default Landing
