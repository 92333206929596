import React, { FunctionComponent, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// import SmoothScroll from 'smooth-scroll'

interface IIntroDropdown {
    className?: string
    setIsHeaderSidebarOpen: (flag: boolean) => void
}

const IntroDropdown: FunctionComponent<IIntroDropdown> = ({
    className,
    setIsHeaderSidebarOpen,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()
    const history = useHistory()

    const pushHistoryToIntro = () => {
        if (location.pathname !== '/introduction') {
            history.push('/introduction')
        }
    }

    return (
        <div className={`relative inline-block text-left ${className}`}>
            <div>
                <a
                    className={`cursor-pointer`}
                    onMouseEnter={() => setIsOpen(true)}
                    onClick={() => pushHistoryToIntro()}
                >
                    Introduction
                </a>
            </div>

            <div
                className={` 'origin-top-right absolute right-0 pt-2 w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ${
                    isOpen ? 'block' : 'hidden'
                }`}
                onMouseLeave={() => setIsOpen(false)}
            >
                <div className="py-1 bg-white" role="menu">
                    <a
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer "
                        href="#about"
                        onClick={() => {
                            pushHistoryToIntro()
                            setIsHeaderSidebarOpen(false)
                        }}
                    >
                        About the Corpus
                    </a>
                    <a
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer "
                        href="#about-function"
                        onClick={() => {
                            pushHistoryToIntro()
                            setIsHeaderSidebarOpen(false)
                        }}
                    >
                        About the Corpus functions
                    </a>
                    <a
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer "
                        href="#about-data"
                        onClick={() => {
                            pushHistoryToIntro()
                            setIsHeaderSidebarOpen(false)
                        }}
                    >
                        About the Corpus data
                    </a>
                    <a
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer "
                        href="#about-team"
                        onClick={() => {
                            pushHistoryToIntro()
                            setIsHeaderSidebarOpen(false)
                        }}
                    >
                        About the Research Team
                    </a>
                    <a
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer "
                        href="#about-acknowledgements"
                        onClick={() => {
                            pushHistoryToIntro()
                            setIsHeaderSidebarOpen(false)
                        }}
                    >
                        Acknowledgements
                    </a>
                </div>
            </div>
        </div>
    )
}

export default IntroDropdown
