import React, { FunctionComponent } from 'react'

import FrontPageLayout from '../../components/layouts/FrontPageLayout'
import bg from '../../images/front_page/bg-svg/wave-header-purple.svg'

const Terms: FunctionComponent = () => {
    return (
        <FrontPageLayout>
            <div
                className="flex flex-col justify-between w-full h-screen px-10px sm:px-140px bg-no-repeat bg-cover sm:bg-contain"
                style={{
                    backgroundImage: `url(${bg})`,
                }}
            >
                <div></div>
                <div className="mx-auto sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-center text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline  text-white sm:text-black">Terms Of Use</span>
                    </h1>
                </div>

                <div className="flex justify-center w-full">
                    <img
                        className="w-12"
                        src={require('../../images/front_page/icon/down-chevron.svg')}
                        alt=""
                    />
                </div>
            </div>

            <div className="flex justify-center items-start w-full px-10px sm:px-140px mt-20px sm:mt-48px">
                <div className="w-full md:4/5 xl:w-2/3">
                    <h2 className="heading text-page-heading font-bold text-3xl">
                        Copyright Information
                    </h2>

                    <p className="">
                        The Research Institute for Bilingual Learning and Teaching (RIBiLT) of the
                        School of Education and Languages at Hong Kong Metropolitan University
                        retains the copyright of the CELL Corpus.
                    </p>

                    <div className="block mt-48px"></div>

                    <h2 className="heading text-page-heading font-bold text-3xl">Disclaimer</h2>

                    <p className="">
                        RIBiLT has attempted to ensure the accuracy of the contents of the CELL
                        Corpus. However, all the information provided by the CELL Corpus is only for
                        non-commercial personal, research and educational use without any express or
                        implied warranty.
                    </p>

                    <div className="block mt-48px"></div>

                    <h2 className="heading text-page-heading font-bold text-3xl">Terms of Use</h2>

                    <p className="">
                        By making use of the CELL Corpus, users should have understood that contents
                        of the CELL Corpus and its use:
                    </p>

                    <div className="mt-10px">
                        <ul className="list-decimal list-inside">
                            <li>are within the boundaries of Fair Dealing; </li>
                            <li>
                                <p className=" inline">
                                    must comply with Attribution-NonCommercial-NoDerivatives 4.0
                                    International{' '}
                                    <img
                                        className="inline"
                                        src={require('../../images/front_page/terms_ANN4.png')}
                                        alt=""
                                    />{' '}
                                    of Creative Commons license; and
                                </p>
                            </li>
                            <li>
                                are restricted for non-commercial personal, research and educational
                                purposes.{' '}
                            </li>
                        </ul>
                    </div>

                    <div className="block mt-48px"></div>

                    <h2 className="heading text-page-heading font-bold text-3xl">Citation Guide</h2>

                    <p className="">
                        If you use the contents from the CELL Corpus and want to cite it, please
                        refer to the following:
                    </p>
                    <p className="">
                        <span className="italic">The CELL Corpus</span> (2021). Distributed by
                        RIBiLT at HKMU. URL: (http://cellcorpushkmu.com)
                    </p>

                    <div className="block mt-48px"></div>
                </div>
            </div>
        </FrontPageLayout>
    )
}

export default Terms
