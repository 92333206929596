import React, { FC, Fragment, useEffect } from 'react'
import SmoothScroll from 'smooth-scroll'

import Footer from './partials/Footer'
import Header from './partials/Header'
import BackToTop from './partials/BackToTop'

const FrontPageLayout: FC = ({ children }) => {
    useEffect(() => {
        const scroll = new SmoothScroll('a[href*="#"]', {
            speed: 100,
            offset: 0,
            easing: 'easeInCubic',
        })

        return () => {
            scroll.destroy()
        }
    })

    return (
        <Fragment>
            <Header textClass="text-white" />

            <main className="flex flex-col justify-center items-center relative text-lg">
                {children}
                <BackToTop></BackToTop>
            </main>

            <Footer isAbsolute={false} />
        </Fragment>
    )
}

export default FrontPageLayout
