import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import AppLayout from '../../components/layouts/AppLayout'
import { config } from '../../config'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import AppHeaderTranscribedFile from './partials/AppHeaderTranscribedFile'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const TranscribedFile = () => {
  const sidebarContentRef = useRef<HTMLDivElement>(null)
  const boardContentRef = useRef<HTMLDivElement>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRegNewUserOpen, setIsRegNewUserOpen] = useState(false)
  const [filenameList, setFilenameList] = useState<string[]>([])
  const [currentFileName, setCurrentFileName] = useState(
    'Brief profile of the children_20211109.pdf'
  )
  const [sentenses, setSentenses] = useState<string[]>([])
  const [isFileListOpen, setIsFileListOpen] = useState(false)
  const { height, width } = useWindowDimensions()

  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState<number | null>(null)

  useEffect(() => {
    setPageNumber(1)
  }, [currentFileName])

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  const getAllFilename = () => {
    let req = require.context(
      '../../../public/transcribed_files/',
      false,
      /.*\.txt|\.pdf$/
    )
    let filelist: string[] = []
    req.keys().forEach(function (key) {
      let path = req(key)
      let pathArr = path.split('/')
      let filenameWithRandomNumber = pathArr[pathArr.length - 1]
      let filenameWithRandomNumberArr = filenameWithRandomNumber.split('.')

      let filename = filenameWithRandomNumberArr[0]
      let ext = filenameWithRandomNumberArr[2]
      // console.log(path, pathArr, filenameWithRandomNumber, [filename, randomNumber, ext])

      filelist.push(filename + '.' + ext)
    })
    setFilenameList(filelist)
  }

  const readTextFile = (file: string) => {
    let request = new XMLHttpRequest()
    request.open('GET', file, false)

    if (file.includes('Transcription_20190403_Full Class_pseudo name.txt')) {
      request.overrideMimeType('text/plain; charset=big5')
    }

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200 || request.status === 0) {
          let urlSplits = file.split('/')
          urlSplits.length
            ? setCurrentFileName(urlSplits[urlSplits.length - 1])
            : setCurrentFileName('')

          setSentenses(request.responseText.split('\n'))
        }
      }
    }
    request.send(null)
  }

  useEffect(() => {
    // console.log(location, window.location)
    getAllFilename()
  }, [])

  const handleFileSelect = (filename: string) => {
    if (filename.includes('.txt')) {
      readTextFile(
        `${window.location.origin}${config.app.transcribed_files_dir}${filename}`
      )
    } else if (filename.includes('.pdf')) {
      setCurrentFileName(filename)
    }
  }

  return (
    <AppLayout
      AppHeader={() => (
        <AppHeaderTranscribedFile
          setIsModalOpen={setIsModalOpen}
          setIsRegNewUserOpen={setIsRegNewUserOpen}
          isFileListOpen={isFileListOpen}
          setIsFileListOpen={setIsFileListOpen}
        ></AppHeaderTranscribedFile>
      )}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isRegNewUserOpen={isRegNewUserOpen}
      setIsRegNewUserOpen={setIsRegNewUserOpen}
    >
      <div className="transcribedFileWrapper">
        <div
          className={`transcribedFileWrapper__fileList ${
            isFileListOpen ? 'active' : ''
          }`}
        >
          <section className="flex h-full w-full relative">
            <div className="absolute w-full h-full flex flex-col space-y-6 p-6 bg-white shadow-cell">
              <header>
                <h2 className="text-xl leading-7 font-medium text-gray-900">
                  File List
                </h2>
              </header>

              <div className="flex w-full h-full" ref={sidebarContentRef}>
                {sidebarContentRef.current ? (
                  <SimpleBar
                    style={{
                      maxHeight: sidebarContentRef.current.clientHeight,
                      width: '100%',
                    }}
                  >
                    <div className="flex flex-col w-full h-full border-2 border-dashed border-gray-200">
                      {filenameList.map((filename, filename_idx) => (
                        <div
                          className="w-full flex flex-col px-10px py-20px hover:bg-gray-400 cursor-pointer"
                          key={`filename-${filename}-${filename_idx}`}
                          onClick={() => {
                            handleFileSelect(filename)
                            setIsFileListOpen(false)
                          }}
                        >
                          <div className="italic text-lg">
                            <p>{filename}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SimpleBar>
                ) : null}
              </div>
            </div>
          </section>
        </div>

        <div
          className={`sidebar-wrapper-overlay ${
            isFileListOpen ? 'active' : ''
          }`}
          onClick={() => setIsFileListOpen(false)}
        ></div>

        <div className="app__divider"></div>

        <div className="transcribedFileWrapper__fileContent">
          <section className="flex h-full w-full relative">
            <div className="absolute w-full h-full flex flex-col space-y-6 p-6 bg-white shadow-cell">
              <header>
                <h2 className="text-xl leading-7 font-medium text-gray-900">
                  {currentFileName}
                </h2>
              </header>

              <hr className="border-cell-accordion-border pb-2" />

              <div
                className="flex flex-col w-full h-full select-none"
                ref={boardContentRef}
              >
                {currentFileName ? (
                  boardContentRef.current && (
                    <SimpleBar
                      style={{
                        maxHeight: boardContentRef.current.clientHeight - 50,
                        width: '100%',
                      }}
                    >
                      {currentFileName.includes('txt') ? (
                        sentenses.length &&
                        sentenses.map((sentense, sentense_idx) => (
                          <p key={`sentense${sentense_idx}`}>{sentense}</p>
                        ))
                      ) : (
                        <div>
                          <div className="overflow-scroll">
                            <Document
                              file={`/transcribed_files/${currentFileName}`}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={pageNumber}
                                width={200 + width * 0.4}
                              />
                            </Document>
                          </div>
                          <div className="flex items-center justify-center mt-4">
                            <button
                              className="bg-blue-600 text-white hover:bg-blue-800 active:bg-blue-800 font-bold uppercase text-xs px-4 py-2 rounded-l outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setPageNumber(pageNumber - 1)}
                            >
                              <FontAwesomeIcon
                                className="mx-5px"
                                style={{ color: '#ffffff' }}
                                icon={faAngleLeft}
                              />
                            </button>
                            <button
                              className="bg-blue-600 text-white font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                              type="button"
                            >
                              Page {pageNumber} of {numPages}
                            </button>
                            <button
                              className="bg-blue-600 text-white hover:bg-blue-800 active:bg-blue-800 font-bold uppercase text-xs px-4 py-2 rounded-r outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setPageNumber(pageNumber + 1)}
                            >
                              <FontAwesomeIcon
                                className="mx-5px"
                                style={{ color: '#ffffff' }}
                                icon={faAngleRight}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </SimpleBar>
                  )
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <p className="font-bold text-lg text-center">
                      Please Select Spoken Data Archive
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </AppLayout>
  )
}

export default TranscribedFile
