import { SystemActions } from './actions'
import * as types from './types'

const initialState: types.SystemState = {
    is_loading: true,
    corpus_lang: 'english',
    font_size: 'medium',
    is_sidebar_active: false,
}

const systemReducer = (
    state: types.SystemState = initialState,
    action: SystemActions
): types.SystemState => {
    switch (action.type) {
        case types.SET_IS_LOADING:
            return { ...state, is_loading: action.payload }

        case types.SET_CORPUS_LANG:
            return { ...state, corpus_lang: action.payload }

        case types.SET_FONT_SIZE:
            return { ...state, font_size: action.payload }

        case types.SET_IS_SIDEBAR_ACTIVE:
            return { ...state, is_sidebar_active: action.payload }

        default:
            return state
    }
}

export default systemReducer
