import { cloneDeep } from 'lodash'
import React, { FormEvent, ChangeEvent, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getSortByOptions } from '../../../data/ApiOption'
import {
    defaultConcordanceRequestEn,
    defaultConcordanceRequestCn,
} from '../../../data/DefaultRequest'
import { ConcordanceSearchQueryNew } from '../../../stores/data-models/Assignment'
import { setIsKwicSort, getIsKwicSort } from '../../../stores/reducers/concordance'
import useLanguage from '../../../hooks/useLanguage'

// import { setSearchParams } from '../../../stores/reducers/concordance/actions'

import NumberInputHorizontal from '../../../components/utility/NumberInputHorizontal'
import KwicControl from './KwicControl'
// import CheckboxInput from '../../../components/utility/CheckboxInput'
import CheckboxInputWithDefVal from '../../../components/utility/CheckboxInputWithDefVal'
import Tabs from './Tabs'

type SearchBarProps = {
    parentSearchParams: ConcordanceSearchQueryNew
    parentSetSearchParams: (newParams: ConcordanceSearchQueryNew) => void
    onSearch: (event: FormEvent<HTMLFormElement>) => void
    //
    isSorting: boolean
    isSortingOn: boolean
    isSearching: boolean
}

const SearchBar = ({
    parentSearchParams,
    parentSetSearchParams,
    onSearch,
    //
    isSorting,
    isSortingOn,
    isSearching,
}: SearchBarProps) => {
    // console.log(`Rendering...${SearchBar.name}`)

    const dispatch = useDispatch()
    const language = useLanguage()
    const options = getSortByOptions()
    const [searchParams, setSearchParams] = useState<ConcordanceSearchQueryNew>(
        cloneDeep(
            language === 'english' ? defaultConcordanceRequestEn : defaultConcordanceRequestCn
        )
    )
    // const result = useSelector(getResults)
    const isKwicSort = useSelector(getIsKwicSort)

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let { name, value } = event.target
        // console.log(name, value, event.target)

        setSearchParams({
            ...searchParams,
            [name]: value,
        })
    }

    const handleSortByOnClick = (sortByIdx: number, sortByValue: string) => {
        let newSortby = [...searchParams.sortBy]
        newSortby[sortByIdx] = sortByValue
        // console.log('handleSortByOnClick', sortByIdx, sortByValue, newSortby)
        parentSetSearchParams({
            ...searchParams,
            sortBy: newSortby,
        })
    }

    const handleQuickKWIC = (quick: string) => {
        parentSetSearchParams({
            ...searchParams,
            sortBy: quick === 'L' ? ['1L', '2L', '3L'] : ['1R', '2R', '3R'],
        })
    }

    const handleCheckboxOnChange = (name: string, isChecked: boolean) => {
        // console.log(name, isChecked)
        parentSetSearchParams({
            ...searchParams,
            [name]: isChecked,
        })
    }

    const handleKWICCheckboxOnChange = (name: string, isChecked: boolean) => {
        // console.log(name, isChecked)
        dispatch(setIsKwicSort(isChecked))
    }

    const handleSearchBtnOnClick = () => {
        parentSetSearchParams({
            ...searchParams,
            searchText: searchParams.searchText.trim(),
            pagenumber: 0,
        })
    }

    useEffect(() => {
        setSearchParams((prevState) => ({ ...prevState, ...parentSearchParams }))
    }, [parentSearchParams])

    return (
        <React.Fragment>
            <div className="searchBarMobile">
                <form onSubmit={onSearch}>
                    <div className="searchBarMobile__searchGroup__concordance">
                        <input
                            className="searchBarMobile__searchGroup__concordance__input"
                            value={searchParams.searchText}
                            type="text"
                            name="searchText"
                            onChange={handleOnChange}
                            required
                        />

                        <div className="mt-10px">
                            <button
                                type="submit"
                                className={`searchBarMobile__searchGroup__concordance__btn${
                                    isSearching ? '--disable' : ''
                                }`}
                                disabled={isSearching}
                                onClick={() => handleSearchBtnOnClick()}
                            >
                                Search
                            </button>
                        </div>
                    </div>

                    <div className="mt-10px">
                        <Tabs
                            parentSearchParams={parentSearchParams}
                            parentSetSearchParams={parentSetSearchParams}
                            handleKWICCheckboxOnChange={handleKWICCheckboxOnChange}
                            handleQuickKWIC={handleQuickKWIC}
                            isKwicSort={isKwicSort}
                            //
                            isSorting={isSorting}
                            isSortingOn={isSortingOn}
                        ></Tabs>
                    </div>
                </form>
            </div>

            <div className="searchBar">
                {/* <form onSubmit={handleSearch}> */}
                <form onSubmit={onSearch}>
                    <div className="searchBarRow">
                        <div className="searchBarRow__grid">
                            <div className="searchBarRow__grid__row">
                                <input
                                    className="serach_input"
                                    value={searchParams.searchText}
                                    type="text"
                                    name="searchText"
                                    onChange={handleOnChange}
                                    required
                                />

                                <button
                                    type="submit"
                                    className={`search_bar_btn${isSearching ? '--disable' : ''}`}
                                    disabled={isSearching}
                                    onClick={() => handleSearchBtnOnClick()}
                                >
                                    Search
                                </button>
                            </div>

                            <div className="searchBarRow__grid__row">
                                <NumberInputHorizontal
                                    label="Page Size"
                                    value={searchParams.pagesize}
                                    name="pagesize"
                                    placehoderStr=""
                                    onChange={handleOnChange}
                                    max={1000}
                                    min={0}
                                ></NumberInputHorizontal>

                                <div className="ml-5px">
                                    <CheckboxInputWithDefVal
                                        label="Pattern"
                                        name="tagsearch"
                                        isChecked={searchParams.tagsearch}
                                        onChange={handleCheckboxOnChange}
                                    ></CheckboxInputWithDefVal>
                                </div>

                                <div className="ml-5px">
                                    <CheckboxInputWithDefVal
                                        label="KWIC Sort"
                                        name="kwicSort"
                                        isChecked={isKwicSort}
                                        onChange={handleKWICCheckboxOnChange}
                                    ></CheckboxInputWithDefVal>
                                </div>

                                <div className="ml-5px">
                                    <CheckboxInputWithDefVal
                                        label="Random"
                                        name="randomSample"
                                        isChecked={searchParams.randomSample}
                                        onChange={handleCheckboxOnChange}
                                    ></CheckboxInputWithDefVal>
                                </div>

                                {language === 'english' ? (
                                    <div className="ml-5px">
                                        <CheckboxInputWithDefVal
                                            label="Case"
                                            name="case"
                                            isChecked={searchParams.case}
                                            onChange={handleCheckboxOnChange}
                                        ></CheckboxInputWithDefVal>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="searchBarRow__grid">
                            <div className="searchBarRow__grid__row justify-end">
                                <button
                                    type="button"
                                    className={`search_bar_btn${isSortingOn ? '' : '--disable'}`}
                                    disabled={isSortingOn ? false : true}
                                    onClick={() => handleQuickKWIC('L')}
                                >
                                    Sort Left
                                </button>
                                <button
                                    type="button"
                                    className={`ml-5px search_bar_btn${
                                        isSortingOn ? '' : '--disable'
                                    }`}
                                    disabled={isSortingOn ? false : true}
                                    onClick={() => handleQuickKWIC('R')}
                                >
                                    Sort Right
                                </button>
                            </div>

                            <div className="searchBarRow__grid__row">
                                <KwicControl
                                    options={options}
                                    curSortBy={searchParams.sortBy}
                                    onClick={handleSortByOnClick}
                                    isSorting={isSorting}
                                    isSortingOn={isSortingOn}
                                ></KwicControl>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default SearchBar
