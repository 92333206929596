import {
    ConcordanceSearchQueryNew, ConcordanceSearchResponseNew
} from '../../data-models/Assignment'
import { createActionWithPayload, IActionWithPayload } from '../../types'
import * as types from './types'

type SetResultsAction = IActionWithPayload<typeof types.SET_RESULTS, ConcordanceSearchResponseNew>
type SetIsKwicSortAction = IActionWithPayload<typeof types.SET_IS_KWIC_SORT, boolean>
type SetGlobalSearchParamsAction = IActionWithPayload<
    typeof types.SET_GLOBAL_SEARCH_PARAMS,
    ConcordanceSearchQueryNew
>

export type ConcordanceActions = SetResultsAction | SetIsKwicSortAction | SetGlobalSearchParamsAction

export const setResults = createActionWithPayload<SetResultsAction>(types.SET_RESULTS)
export const setIsKwicSort = createActionWithPayload<SetIsKwicSortAction>(types.SET_IS_KWIC_SORT)
export const setGlobalSearchParams = createActionWithPayload<SetGlobalSearchParamsAction>(
    types.SET_GLOBAL_SEARCH_PARAMS
)
