import React, { FunctionComponent } from 'react'

const WordlistLoading: FunctionComponent = () => {
    return (
        <div className="overflow-auto h-full">
            <div className="kwicSpinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default WordlistLoading
