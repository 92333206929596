import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import qs from "qs"
import { config } from "../config"
import DataCache from "./DataCache"
import { getAuthTokenFromBrowserStorage } from "./helpers"

export type HttpResponse<T> = {
    body: {
        status: boolean
        data: T
        message?: string
    }
    status: number
    headers: AxiosResponse
}

const Http = axios.create({
    baseURL: config.app.api_url,
})

const cache = new DataCache()

class Api {
    public async get<T>(
        endpoint: string,
        params?: AxiosRequestConfig["params"],
        cachable: boolean = true
    ) {
        cachable = config.app.api_cache ? cachable : false

        const httpConfig: AxiosRequestConfig = {
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=utf-8",
            },
            params: params,
        }

        await this.setAuthHeaders()

        let response = {}

        const key = encodeURIComponent(endpoint + JSON.stringify(params))

        try {
            if (cachable && cache.has(key)) {
                response = cache.get(key)
            } else {
                response = await Http.get(endpoint, httpConfig)
                cache.set({ key, value: response })
            }
        } catch (e) {
            this.onError(e)
        }

        return this.onSuccess<T>(response as AxiosResponse)
    }

    public async post<T>(
        endpoint: string,
        params: any = {},
        processParams: boolean = false,
        contentType: string | null = null
    ) {
        let config = {
            headers: {
                "Content-Type": contentType || "application/json",
            },
        }

        await this.setAuthHeaders()

        let response = {}

        try {
            response = await Http.post(
                endpoint,
                processParams ? qs.stringify(params) : params,
                config
            )
        } catch (e) {
            this.onError(e)
        }

        return this.onSuccess<T>(response as AxiosResponse)
    }

    public async patch<T>(
        endpoint: string,
        params: any = {},
        processParams: boolean = false,
        contentType: string | null = null
    ) {
        let config = {
            headers: {
                "Content-Type": contentType || "application/json",
            },
        }

        await this.setAuthHeaders()

        let response = {}

        try {
            response = await Http.patch(
                endpoint,
                processParams ? qs.stringify(params) : params,
                config
            )
        } catch (error) {
            this.onError(error)
        }

        return this.onSuccess<T>(response as AxiosResponse)
    }

    public async put<T>(
        endpoint: string,
        params: any = {},
        processParams: boolean = false,
        contentType: string | null = null
    ) {
        let config = {
            headers: {
                "Content-Type": contentType || "application/json",
            },
        }

        await this.setAuthHeaders()

        let response = {}

        try {
            response = await Http.put(
                endpoint,
                processParams ? qs.stringify(params) : params,
                config
            )
        } catch (error) {
            this.onError(error)
        }

        return this.onSuccess<T>(response as AxiosResponse)
    }

    public async delete<T>(endpoint: string) {
        await this.setAuthHeaders()

        let response = {}

        try {
            response = await Http.delete(endpoint)
        } catch (error) {
            this.onError(error)
        }

        return this.onSuccess<T>(response as AxiosResponse)
    }

    public clearCache(): void {
        cache.clear()
    }

    private onError(error: any): void {
        throw error
    }

    private onSuccess<T>({
        data,
        status,
        headers,
    }: AxiosResponse<any>): HttpResponse<T> {
        return {
            body: data,
            status,
            headers,
        }
    }

    private async setAuthHeaders(): Promise<void> {
        const bearerToken = await getAuthTokenFromBrowserStorage()

        if (bearerToken) {
            Http.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${bearerToken}`
        }
    }
}

export default new Api()
