import {
    // createActionWithoutPayload,
    createActionWithPayload,
    // IActionWithoutPayload,
    IActionWithPayload,
} from '../../types'
import * as types from './types'
import { WordListData } from '../../data-models/WordList'

type SetSearchTextAction = IActionWithPayload<typeof types.SET_SEARCH_TEXT, string>
type SetResultsAction = IActionWithPayload<typeof types.SET_RESULTS, WordListData>
type SetIsInitSearchCompletedAction = IActionWithPayload<
    typeof types.SET_IS_INIT_SEARCH_COMPLETED,
    boolean
>
type SetWordlistPageNumberAction = IActionWithPayload<typeof types.SET_WORDLIST_PAGE_NUMBER, number>

export type WordlistActions =
    | SetSearchTextAction
    | SetResultsAction
    | SetIsInitSearchCompletedAction
    | SetWordlistPageNumberAction

export const setSearchText = createActionWithPayload<SetSearchTextAction>(types.SET_SEARCH_TEXT)
export const setResults = createActionWithPayload<SetResultsAction>(types.SET_RESULTS)
export const setIsInitSearchCompleted = createActionWithPayload<SetIsInitSearchCompletedAction>(
    types.SET_IS_INIT_SEARCH_COMPLETED
)
export const setWordlistPageNumberAction = createActionWithPayload<SetWordlistPageNumberAction>(
    types.SET_WORDLIST_PAGE_NUMBER
)
