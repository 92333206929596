import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { checkBoxFieldName } from '../../data/checkbox'
// import { getAllOptionsLength } from '../../data/ApiOption'
// import useLanguage from '../../hooks/useLanguage'

type CheckboxAccordionProps = {
    title: string
    name: checkBoxFieldName
    options: { label: string; value: string }[]
    checkedList: string[]

    selectAllOnChange: (name: checkBoxFieldName, localSelectAllChecked: boolean) => void
    isChecked: (name: checkBoxFieldName, value: string) => boolean
    handleCheckboxOnChange: (name: checkBoxFieldName, value: string, isChecked: boolean) => void
}

const CheckboxAccordion = ({
    title,
    name,
    options,
    checkedList,
    //
    selectAllOnChange,
    isChecked,
    handleCheckboxOnChange,
}: CheckboxAccordionProps) => {
    // const language = useLanguage()
    // const optionsLength = getAllOptionsLength(language)
    const [isActive, setIsActive] = useState(false)

    return (
        <div className="accordion">
            <div
                className={'accordion-btn' + (isActive ? ' active' : '')}
                onClick={() => setIsActive(!isActive)}
            >
                <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="ml-5 font-bold">{title}</div>
            </div>

            <div className={'accordion-meun' + (isActive ? ' active' : '')}>
                <div className="accordion-item">
                    <input
                        className="cell_checkbox"
                        type="checkbox"
                        name={name}
                        checked={checkedList.length === options.length}
                        onChange={() =>
                            selectAllOnChange(name, checkedList.length === options.length)
                        }
                    />
                    <label>Select All</label>
                </div>

                {options.map((opt, idx) => {
                    return (
                        <div className="accordion-item" key={idx}>
                            <input
                                className="cell_checkbox"
                                type="checkbox"
                                name={name}
                                value={opt.value}
                                checked={isChecked(name, opt.value)}
                                onChange={() =>
                                    handleCheckboxOnChange(
                                        name,
                                        opt.value,
                                        !isChecked(name, opt.value)
                                    )
                                }
                            />
                            <label>{opt.label}</label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CheckboxAccordion
