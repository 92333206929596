import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { WordListResponse } from '../../../stores/data-models/WordList'
import { setSearchText } from '../../../stores/reducers/wordlist/actions'
import useLanguage from '../../../hooks/useLanguage'
// import { setIsKwicSort } from '../../../stores/reducers/concordance'

type ResultTableProps = {
    datas: WordListResponse[]
}

const ResultTable = ({ datas }: ResultTableProps) => {
    // console.log(`Rendering...${ResultTable.name}`)

    const resultTableRef = useRef<HTMLDivElement>(null)
    const language = useLanguage()
    const dispatch = useDispatch()
    const lang = language === 'english' ? 'en' : 'cn'
    const [tableHeight, setTableHeight] = useState(0)

    const handleOnClick = (searchText: string) => {
        // console.log(searchText)
        dispatch(setSearchText(searchText))
        // dispatch(setIsKwicSort(false))
    }

    useEffect(() => {
        // console.log(resultTableRef.current)
        if (resultTableRef.current) {
            setTableHeight(resultTableRef.current.clientHeight)
        }
    }, [resultTableRef])

    return (
        <div className="flex w-full h-full" ref={resultTableRef}>
            {tableHeight ? (
                <SimpleBar style={{ maxHeight: tableHeight, width: '100%' }}>
                    <div className="result_table_wrapper">
                        <table className="result_table">
                            <thead>
                                <tr>
                                    <td className="pl-30px">Rank</td>
                                    <td className="w-full py-10px">Word Type</td>
                                    {/* <td className="w-1/2 py-10px">Frequency</td> */}
                                    <td className="pr-30px"></td>
                                </tr>
                            </thead>
                            <tbody>
                                {datas.map((data, idx) => (
                                    <tr key={data.Word + idx}>
                                        {/* COMMENTED OUT to replace with token rank on 06 June 2021 */}
                                        {/* <td className="pl-30px">
                                            {tableIndex > 1
                                                ? (tableIndex - 1) * 10 + idx + 1
                                                : idx + 1}
                                        </td> */}
                                        <td className="pl-30px">{data.tokenrank}</td>
                                        <td className="w-full py-10px">
                                            <Link
                                                to={`/concordance/${lang}`}
                                                onClick={() => handleOnClick(data.Word)}
                                            >
                                                {data.Word}
                                            </Link>
                                        </td>
                                        {/* <td className="w-1/2 py-10px">{data.Frequency}</td> */}
                                        <td className="pr-30px"></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </SimpleBar>
            ) : null}
        </div>
    )
}

export default ResultTable
