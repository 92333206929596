import React, {
    ChangeEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import SidebarAccordion from '../../../components/utility/CheckboxAccordion'
import MultiRowCheckbox from '../../../components/utility/MultiRowCheckboxAccordion'
import RadioAccordion from '../../../components/utility/RadioAccordion'
import {
    getAllOptionsLength,
    getCourseCh,
    getCourseEn,
    getExamOptions,
    getGenderOptions,
    getLanguageOptions,
    getPlaceOfBirthOptions,
    getSections
} from '../../../data/ApiOption'
import { checkBoxFieldName } from '../../../data/checkbox'
import useLanguage from '../../../hooks/useLanguage'
// import { useSelector } from 'react-redux'
import { ConcordanceSearchQueryNew } from '../../../stores/data-models/Assignment'
import { getGlobalSearchParams } from '../../../stores/reducers/concordance'

interface SidebarsProps {
    parentSetSearchParams: (newParams: ConcordanceSearchQueryNew) => void
}

const Sidebar: FunctionComponent<SidebarsProps> = ({ parentSetSearchParams }: SidebarsProps) => {
    const language = useLanguage()
    const searchParams = useSelector(getGlobalSearchParams)
    // const [searchParams, setSearchParams] = useState<ConcordanceSearchQueryNew>(
    //     cloneDeep(
    //         language === 'english' ? defaultConcordanceRequestEn : defaultConcordanceRequestCn
    //     )
    // )
    const sidebarContentRef = useRef<HTMLDivElement>(null)
    const [isDataReady, setIsDataReady] = useState(false)

    const enCoursesOptions = getCourseEn()
    const chCoursesOptions = getCourseCh()
    const coursesOptions = language === 'english' ? enCoursesOptions : chCoursesOptions
    const sectionOptions = getSections()
    const genderOptions = getGenderOptions()
    const placeOfBirthOptions = getPlaceOfBirthOptions()
    const firstlanguageOptions = getLanguageOptions(language)
    const publicExamResultOptions = getExamOptions()
    const optionsLength = getAllOptionsLength(language)

    const [metatdatSelectAll, setMetatdatSelectAll] = useState({
        age: searchParams.metadata['age'].length === optionsLength['age'],
        gender: searchParams.metadata['gender'].length === optionsLength['gender'],
        placeofbirth:
            searchParams.metadata['placeofbirth'].length === optionsLength['placeofbirth'],
        firstlanguage:
            searchParams.metadata['firstlanguage'].length === optionsLength['firstlanguage'],
        hkdseenglish:
            searchParams.metadata['hkdseenglish'].length === optionsLength['hkdseenglish'],
        hkdsechinese:
            searchParams.metadata['hkdsechinese'].length === optionsLength['hkdsechinese'],
        hkaleenglish:
            searchParams.metadata['hkaleenglish'].length === optionsLength['hkaleenglish'],
        hkalechinese:
            searchParams.metadata['hkalechinese'].length === optionsLength['hkalechinese'],
        ielts: searchParams.metadata['ielts'].length === optionsLength['ielts'],
    })

    const isChecked = useCallback(
        (name: checkBoxFieldName, value: string): boolean => {
            // console.log(name, value, (searchParams.metadata[name] || []).includes(value))
            return (searchParams.metadata[name] || []).includes(value)
        },
        [searchParams.metadata]
    )

    const handleRadioOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target
            // console.log(name, value)
            parentSetSearchParams({ ...searchParams, [name]: value })
        },
        [searchParams]
    )

    const handleCheckboxOnChange = useCallback(
        (name: checkBoxFieldName, value: string, isChecked: boolean) => {
            let values = searchParams.metadata

            isChecked
                ? values[name].push(value)
                : (values[name] = values[name].filter((v) => v !== value))
            // console.log(name, value, isChecked)
            // console.log(values)

            parentSetSearchParams({
                ...searchParams,
                metadata: values,
            })

            let newState = { ...metatdatSelectAll }
            for (const key in searchParams.metadata) {
                if (Object.prototype.hasOwnProperty.call(searchParams.metadata, key)) {
                    if (key === name) {
                        const el = searchParams.metadata[key as checkBoxFieldName]
                        const optionLength = optionsLength[name as checkBoxFieldName]
                        // console.log(name, el.length, optionLength)
                        if (el.length === optionLength) {
                            newState[name as checkBoxFieldName] = true
                        } else {
                            newState[name as checkBoxFieldName] = false
                        }
                    }
                }
            }
            setMetatdatSelectAll(newState)
        },
        [searchParams, metatdatSelectAll, optionsLength]
    )

    const handleSelectAllOnChange = useCallback(
        (name: checkBoxFieldName, checked: boolean) => {
            let values = searchParams.metadata

            if (!checked) {
                if (name === 'gender') {
                    genderOptions.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'placeofbirth') {
                    placeOfBirthOptions.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'firstlanguage') {
                    firstlanguageOptions.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'hkdseenglish') {
                    publicExamResultOptions[0].option.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'hkdsechinese') {
                    publicExamResultOptions[1].option.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'hkaleenglish') {
                    publicExamResultOptions[2].option.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'hkalechinese') {
                    publicExamResultOptions[3].option.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
                if (name === 'ielts') {
                    publicExamResultOptions[4].option.map((opt) => {
                        if (!searchParams.metadata[name].includes(opt.value)) {
                            values[name].push(opt.value)
                        }
                        return true
                    })
                }
            } else {
                values[name] = []
            }

            parentSetSearchParams({
                ...searchParams,
                metadata: values,
            })

            setMetatdatSelectAll((prevState) => ({ ...prevState, [name]: !checked }))
        },
        [
            searchParams,
            firstlanguageOptions,
            placeOfBirthOptions,
            publicExamResultOptions,
            genderOptions,
            parentSetSearchParams,
        ]
    )

    useEffect(() => {
        if (sidebarContentRef.current) {
            setIsDataReady(true)
        }
    }, [sidebarContentRef])

    return (
        <div className="sidebar">
            <div className="flex w-full h-full" ref={sidebarContentRef}>
                {isDataReady ? (
                    <SimpleBar
                        style={{
                            maxHeight: sidebarContentRef.current?.clientHeight,
                            width: '100%',
                        }}
                    >
                        <div className="title">Categories of Courses</div>

                        <RadioAccordion
                            title="Categories of Courses"
                            name="category"
                            options={coursesOptions}
                            current_option={searchParams.category}
                            handleRadioOnChange={handleRadioOnChange}
                        ></RadioAccordion>

                        <RadioAccordion
                            title="Sections"
                            name="section"
                            options={sectionOptions}
                            current_option={searchParams.section}
                            handleRadioOnChange={handleRadioOnChange}
                        ></RadioAccordion>

                        <div className="title">Meta Data</div>

                        <SidebarAccordion
                            title="Gender"
                            name="gender"
                            options={genderOptions}
                            checkedList={searchParams.metadata.gender}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></SidebarAccordion>

                        <SidebarAccordion
                            title="Place of Birth"
                            name="placeofbirth"
                            options={placeOfBirthOptions}
                            checkedList={searchParams.metadata.placeofbirth}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></SidebarAccordion>

                        <SidebarAccordion
                            title="First Language"
                            name="firstlanguage"
                            options={firstlanguageOptions}
                            checkedList={searchParams.metadata.firstlanguage}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></SidebarAccordion>

                        <MultiRowCheckbox
                            title="Public Examination Results"
                            options={publicExamResultOptions}
                            checkedList={searchParams.metadata}
                            //
                            selectAllOnChange={handleSelectAllOnChange}
                            isChecked={isChecked}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                        ></MultiRowCheckbox>
                    </SimpleBar>
                ) : null}
            </div>
        </div>
    )
}

export default Sidebar
