import { Environment, ReactAppConfig } from './types'

const getEnv = <K extends keyof Environment, T>(key: K, defaultValue?: T): T => {
    const value = process.env[key as string] || defaultValue
    if (typeof value === 'undefined') {
        throw new Error(`Environment variable not set for key: "${key}"`)
    }

    return value as T
}

const appConfig: ReactAppConfig = {
    api_url: getEnv('REACT_APP_API_URL', ''),
    api_cache: getEnv('REACT_APP_CACHE_API', true),
    authTokenKeyName: 'corpus_app_auth_token',
    adminRoutePrefix: 'admin',
    transcribed_files_dir: getEnv('REACT_APP_TRANSCRIBED_FILES_DIRECTORY', ''),
}

export default appConfig
