import React, { FunctionComponent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface IHeaderMobileMeunSubMeun {
    title: string
}

const HeaderMobileMeunSubMeun: FunctionComponent<IHeaderMobileMeunSubMeun> = ({
    children,
    title,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <React.Fragment>
            <button className="menu-link" onClick={() => setIsOpen(!isOpen)}>
                <span className="menu-text">{title}</span>
                <i className="menu-arrow">
                    <FontAwesomeIcon size="1x" icon={faChevronRight} />
                </i>
            </button>
            <div className={`menu-submenu ${isOpen ? 'active' : ''}`}>
                <ul className="menu-subnav">{children}</ul>
            </div>
        </React.Fragment>
    )
}

export default HeaderMobileMeunSubMeun
