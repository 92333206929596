import { combineReducers } from 'redux'
import { RootState } from '../types'
import { authReducer } from './auth'
import { concordanceReducer } from './concordance'
import { systemReducer } from './system'
import { wordlistReducer } from './wordlist'

export default combineReducers<RootState>({
    system: systemReducer,
    auth: authReducer,
    concordance: concordanceReducer,
    wordlist: wordlistReducer,
})
