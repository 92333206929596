import { Concordance } from '../pages/concordance'
import { PageNotFound } from '../pages/errors'
import TranscribedFile from '../pages/spoken-data-archive/TranscribedFile'
import { WordList } from '../pages/word-list'

export default (prefix: string) => [
    {
        redirect: true,
        exact: true,
        from: prefix,
        to: `/concordance/en`,
    },

    {
        auth: true,
        path: `/concordance/en`,
        exact: true,
        component: Concordance,
    },

    {
        auth: true,
        path: `/concordance/cn`,
        exact: true,
        component: Concordance,
    },

    {
        auth: true,
        path: `/wordlist/en`,
        exact: true,
        component: WordList,
    },

    {
        auth: true,
        path: `/wordlist/cn`,
        exact: true,
        component: WordList,
    },

    {
        auth: true,
        path: `/spoken-data-archive`,
        exact: true,
        component: TranscribedFile,
    },

    {
        auth: true,
        path: `/*`,
        component: PageNotFound,
    },
]
