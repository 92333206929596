import React, { FunctionComponent } from 'react'

import FrontPageLayout from '../../components/layouts/FrontPageLayout'
import bg from '../../images/front_page/bg-svg/wave-header-red.svg'

const Contact: FunctionComponent = () => {
    return (
        <FrontPageLayout>
            <div
                className="flex flex-col justify-between w-full h-screen px-10px sm:px-140px bg-no-repeat bg-cover sm:bg-contain"
                style={{
                    backgroundImage: `url(${bg})`,
                }}
            >
                <div></div>
                <div className="mx-auto sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-center text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline  text-white sm:text-black">Contact Us</span>
                    </h1>
                </div>

                <div className="flex justify-center w-full">
                    <img
                        className="w-12"
                        src={require('../../images/front_page/icon/down-chevron.svg')}
                        alt=""
                    />
                </div>
            </div>

            <div className="flex justify-center items-center w-full h-screen px-10px sm:px-140px mt-20px sm:mt-48px">
                <div className="text-center w-full md:4/5 xl:w-2/3">
                    <h2 className="heading text-page-heading font-bold text-3xl">Contact Us</h2>

                    <address>
                        <p>Research Institute for Bilingual Learning and Teaching (RIBiLT)</p>
                        <p>School of Education and Languages</p>
                        <p>Hong Kong Metropolitan University</p>
                        <p>Homantin, Kowloon, Hong Kong</p>
                    </address>

                    <br />
                    <br />

                    <p>
                        Phone:{' '}
                        <a
                            className=" text-cell-green hover:underline hover:cursor-pointer"
                            href="tel:+852-2768-5878"
                        >
                            (852)2768-5878
                        </a>
                    </p>
                    <p>
                        Email:{' '}
                        <a
                            className=" text-cell-green hover:underline hover:cursor-pointer"
                            href="mailto:ouhkribilt@gmail.com"
                        >
                            ouhkribilt@gmail.com
                        </a>
                    </p>
                </div>
            </div>
        </FrontPageLayout>
    )
}

export default Contact
