import React, { useMemo } from 'react'

const AppFooter = () => {
    const currentYear = useMemo(() => new Date().getFullYear(), [])

    return (
        <footer
            className={` px-10px flex items-center justify-between bottom-5px w-full text-xs flex-col sm:flex-row sm:px-30px`}
        >
            <div className="w-full">
                <p>
                    Supported by Research Grants Council, Hong Kong SAR (Project Code: UGC/IDS16/17)
                </p>
                <p>© 2018 - {currentYear} RIBiLT HKMU. All Rights Reserved</p>
            </div>

            <div className="flex items-center justify-between w-full sm:w-auto">
                <div>
                    <img
                        className="w-20 sm:w-40"
                        src={require('../../images/RIBiLT Logo (blue without stroke)_transprant.png')}
                        alt=""
                    />
                </div>
                <div className="ml-1">
                    <img className="w-20 sm:w-40" src={require('../../images/HKMU-logo-mb.png')} alt="" />
                </div>
            </div>
        </footer>
    )
}

export default AppFooter
