import { WordListSearchQueryNew, WordListResponse } from '../stores/data-models'
import Api from '../utils/Api'

class WordListService {
    public static async searchWordListEn(searchParams: Partial<WordListSearchQueryNew>) {
        const { body } = await Api.post<WordListResponse>('/word-list/en', searchParams)
        return body.data
    }

    public static async searchWordListCn(searchParams: Partial<WordListSearchQueryNew>) {
        const { body } = await Api.post<WordListResponse>('/word-list/cn', searchParams)
        return body.data
    }
}

export default WordListService
