import React, { FunctionComponent, HTMLAttributes, useLayoutEffect } from 'react'

import { CloseIcon } from '../../images'

interface ModalProps {
    title: string
    heightClass?: string
    widthClass?: string
    className?: HTMLAttributes<HTMLElement>['className']
    onClose: (flag: boolean) => void
}

const Modals: FunctionComponent<ModalProps> = ({
    title,
    heightClass,
    widthClass,
    className,
    children,
    onClose,
}) => {
    useLayoutEffect(() => {
        const body = document.querySelector('body')

        if (body) {
            body.classList.add('modal-active')
        }

        document.onkeydown = function (event) {
            event = event || window.event
            let isEscape = event.key === 'Escape' || event.key === 'Esc'
            // console.log('key down event', event, isEscape)

            if (isEscape && document.body.classList.contains('modal-active') && onClose) {
                onClose(false)
            }
        }

        return () => {
            if (body) {
                body.classList.remove('modal-active')
            }
        }
    }, [onClose])

    return (
        <div className={`modal ${className || ''}`}>
            <div className="modal-overlay bg-secondary-300 opacity-25 fixed w-full h-full min-h-full top-0 left-0 z-30" />

            <div className="modal-wrapper fixed top-0 left-0 w-full h-screen flex items-center justify-center z-50">
                <div
                    className={`modal-container bg-white p-10 relative shadow-cell rounded-5px 
                    ${heightClass || ''} 
                    ${widthClass || ''}`}
                >
                    <div className="modal-content container mx-auto h-auto text-left">
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-sm sm:text-xl font-bold border-b-4 border-gray-700">{title}</p>
                            </div>
                            <div
                                onClick={() => onClose(false)}
                                className="modal-close cursor-pointer flex flex-col items-end text-black text-sm z-50"
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className="pt-30px text-sm sm:text-xl">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modals
