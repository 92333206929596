import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { WordListService } from '../../ApiServices'
import { WordListNew, WordListSearchQueryNew } from '../data-models'
import { apiCall } from '../reducers/system'
import { OnSuccessCallBack, RootState, OnErrorCallBack } from '../types'

export const searchWordListEn = (
    searchParams: Partial<WordListSearchQueryNew>,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => WordListService.searchWordListEn(searchParams),
            onSuccess: async (response: WordListNew[]) => {
                onSuccess(response)
            },
            onError: (error: any) => {
                // tslint:disable-next-line: no-console
                console.error(error)
                onError()
            },
        })
    )
}

export const searchWordListCn = (
    searchParams: Partial<WordListSearchQueryNew>,
    onSuccess: OnSuccessCallBack,
    onError: OnErrorCallBack
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
    dispatch(
        apiCall({
            onRequest: async () => WordListService.searchWordListCn(searchParams),
            onSuccess: async (response: WordListNew[]) => {
                onSuccess(response)
            },
            onError: (error: any) => {
                // tslint:disable-next-line: no-console
                console.error(error)
                onError()
            },
        })
    )
}
