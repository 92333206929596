import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'

import React, { memo, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getResults } from '../../../stores/reducers/wordlist'
import Pagination from './Pagination'

type excelTitleType = 'Word' | 'Frequency'

type BoardFooterPropd = {
    csvName: string
    resultFragmentPerPage: number
    tableIndex: number
    setTableIndex: (pagenumber: number) => void
}

const BoardFooter = memo(
    ({ csvName, resultFragmentPerPage, tableIndex, setTableIndex }: BoardFooterPropd) => {
        const results = useSelector(getResults)
        const [isGenerating, setIsGenerating] = useState(false)

        const genXlxs = useCallback(() => {
            return new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    let copy = []
                    let col_title = ['Rank', 'Word Type', 'Frequency']
                    copy.push(col_title)

                    let csvArr = [...results.wordlist]
                    csvArr.forEach((csv_data_row, csv_data_row_idx) => {
                        let tempArr: string[] = []
                        tempArr[0] = (csv_data_row_idx + 1).toString()
                        tempArr[1] = csv_data_row.Word
                        tempArr[2] = csv_data_row.Frequency.toString()
                        copy.push(tempArr)
                    })

                    var worksheet = XLSX.utils.aoa_to_sheet(copy)
                    var new_workbook = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
                    var wbout = XLSX.write(new_workbook, { bookType: 'xlsx', type: 'binary' })

                    let buf = new ArrayBuffer(wbout.length)
                    let view = new Uint8Array(buf)
                    for (let i = 0; i < buf.byteLength; i++) {
                        view[i] = wbout.charCodeAt(i) & 0xff
                    }
                    FileSaver.saveAs(new Blob([buf]), csvName)

                    resolve()
                }, 500)
            })
        }, [csvName, results.wordlist])

        useEffect(() => {
            if (isGenerating) {
                genXlxs().then(() => setIsGenerating(false))
            }
        }, [isGenerating])

        return (
            <div className="board__footer">
                <div className="board__footer__downloadArea">
                    <button
                        className={`${
                            isGenerating ? ' bg-gray-400' : 'bg-primary-300 hover:bg-primary-600'
                        } text-center ml-10px px-10px py-5px h-full text-white rounded duration-300 text-sm sm:text-lg `}
                        onClick={() => setIsGenerating(true)}
                        disabled={isGenerating}
                    >
                        {isGenerating ? 'Generating...' : 'Download Results on This Page'}
                    </button>
                </div>

                <div className="board__footer__paginationArea">
                    <Pagination
                        resultFragmentPerPage={resultFragmentPerPage}
                        totalWriting={results.wordlist.length}
                        tableIndex={tableIndex}
                        setTableIndex={setTableIndex}
                    ></Pagination>
                </div>
            </div>
        )
    }
)

export default BoardFooter
