import React, { FunctionComponent } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from '../../../stores/epics/authEpics'
import { getUser } from '../../../stores/reducers/auth'
import HeaderMobileMeunSubMeun from './HeaderMobileMeunSubMeun'
import useLanguage from '../../../hooks/useLanguage'

interface IHeaderSideMeun {
    handleOnClick: (lang: string) => void
    setIsModalOpen: (flag: boolean) => void
    setIsMeunActive: (flag: boolean) => void
    setIsRegNewUserOpen: (flag: boolean) => void
}

const HeaderSideMeun: FunctionComponent<IHeaderSideMeun> = ({
    handleOnClick,
    setIsModalOpen,
    setIsMeunActive,
    setIsRegNewUserOpen,
}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const language = useLanguage()
    const user = useSelector(getUser)

    const pushHistoryToIntro = () => {
        if (location.pathname !== '/introduction') {
            history.push('/introduction')
        }
    }

    return (
        <div className="header-mobile-meun">
            <ul className="menu-nav">
                <li className="menu-item">
                    <HeaderMobileMeunSubMeun title="Introduction">
                        <li className="menu-item">
                            <a
                                className="menu-link"
                                href="#about"
                                onClick={() => {
                                    pushHistoryToIntro()
                                }}
                            >
                                About
                            </a>
                        </li>
                        <li className="menu-item">
                            <a
                                className="menu-link"
                                href="#about-function"
                                onClick={() => {
                                    pushHistoryToIntro()
                                }}
                            >
                                Function
                            </a>
                        </li>
                        <li className="menu-item">
                            <a
                                className="menu-link"
                                href="#about-data"
                                onClick={() => {
                                    pushHistoryToIntro()
                                }}
                            >
                                Data
                            </a>
                        </li>
                        <li className="menu-item">
                            <a
                                className="menu-link"
                                href="#about-team"
                                onClick={() => {
                                    pushHistoryToIntro()
                                }}
                            >
                                Team
                            </a>
                        </li>
                        <li className="menu-item">
                            <a
                                className="menu-link"
                                href="#about-acknowledgements"
                                onClick={() => {
                                    pushHistoryToIntro()
                                }}
                            >
                                Acknowledgements
                            </a>
                        </li>
                    </HeaderMobileMeunSubMeun>
                </li>

                <li className="menu-item">
                    <HeaderMobileMeunSubMeun title="Pages">
                        <li className="menu-item">
                            <Link to="/guide" className={`menu-link`}>
                                User Guide
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/terms" className={`menu-link`}>
                                Terms of Use
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/contact" className={`menu-link`}>
                                Contact Us
                            </Link>
                        </li>
                    </HeaderMobileMeunSubMeun>
                </li>

                <li className="menu-item">
                    <HeaderMobileMeunSubMeun title="App">
                        <li className="menu-item">
                            <Link
                                to="/concordance/en"
                                className={`menu-link ${
                                    language === 'english' ? 'text-cell-green' : 'text-black'
                                }`}
                                onClick={() => handleOnClick('english')}
                            >
                                English Corpus
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/concordance/cn"
                                className={`menu-link ${
                                    language === 'chinese' ? 'text-cell-green' : 'text-black'
                                }`}
                                onClick={() => handleOnClick('chinese')}
                            >
                                Chinese Corpus
                            </Link>
                        </li>

                        {/* DISBALED SPOKEN DATA ARCHIVE - TEMPORARILY   */}
                        <li className="menu-item">
                            <Link
                                to="/spoken-data-archive"
                                className={
                                    `menu-link ` +
                                    (location.pathname.includes('spoken-data-archive')
                                        ? 'text-cell-green'
                                        : 'text-black')
                                }
                            >
                                Spoken Data Archive
                            </Link>
                        </li>
                    </HeaderMobileMeunSubMeun>
                </li>

                <li className="menu-item">
                    <HeaderMobileMeunSubMeun title="User">
                        <li className="menu-item">
                            <div
                                className="menu-link"
                                onClick={() => {
                                    setIsModalOpen(true)
                                    setIsMeunActive(false)
                                }}
                            >
                                Profile
                            </div>
                        </li>
                        {user.isAdmin ? (
                            <li className="menu-item">
                                <div
                                    className="menu-link"
                                    onClick={() => {
                                        setIsRegNewUserOpen(true)
                                        setIsMeunActive(false)
                                    }}
                                >
                                    Register New User
                                </div>
                            </li>
                        ) : null}
                        <li className="menu-item">
                            <div className="menu-link" onClick={() => dispatch(logout())}>
                                Logout
                            </div>
                        </li>
                    </HeaderMobileMeunSubMeun>
                </li>
            </ul>
        </div>
    )
}

export default HeaderSideMeun
