import React from 'react'

type NumberInputHorizontalProps = {
    className?: string
    label: string
    value: number
    name: string
    placehoderStr: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    max: number
    min: number
}

const NumberInputHorizontal = ({
    className,
    label,
    value,
    name,
    placehoderStr,
    onChange,
    max,
    min,
}: NumberInputHorizontalProps) => {
    return (
        <div className={`numberInputHorizontal--mobile ${className}`}>
            <label className="numberInputHorizontal--mobile__label">{label}</label>
            <input
                className="numberInputHorizontal--mobile__input"
                type="number"
                placeholder={placehoderStr}
                value={value}
                name={name}
                onChange={onChange}
                required
                max={max}
                min={min}
            />
        </div>
    )
}

export default NumberInputHorizontal
