import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import PageNotFoundSvg from '../../images/404_page_not_found_.svg'

const PageNotFound: FunctionComponent = () => (
    <div className="flex flex-col w-full h-full p-30px">
        <div className="flex justify-center flex-grow w-full">
            <img className="w-1/3" src={PageNotFoundSvg} alt="" />
        </div>
        <div className="flex justify-center">
            <Link
                to="/"
                className="bg-primary-300 text-center px-15px py-5px text-white rounded duration-300 hover:bg-primary-600 cursor-pointer"
            >
                Home
            </Link>
        </div>
    </div>
)

export default PageNotFound
