import { WordlistActions } from './actions'
import * as types from './types'

const initialState: types.WordListState = {
    searchText: '',

    results: {
        wordtypes: 0,
        wordtokens: 0,
        hits: 0,
        wordlist: [],
    },

    isInitSearchCompleted: false,

    wordlistPageNumber: 1,
}

const wordlistReducer = (
    state: types.WordListState = initialState,
    action: WordlistActions
): types.WordListState => {
    switch (action.type) {
        case types.SET_SEARCH_TEXT:
            return { ...state, searchText: action.payload }

        case types.SET_RESULTS:
            return { ...state, results: action.payload }

        case types.SET_IS_INIT_SEARCH_COMPLETED:
            return { ...state, isInitSearchCompleted: action.payload }

        case types.SET_WORDLIST_PAGE_NUMBER:
            return { ...state, wordlistPageNumber: action.payload }

        default:
            return state
    }
}

export default wordlistReducer
